<template>
    <div>
        <v-card v-if="showFilters" class="pa-2 mb-2">
            <v-row>
                <v-col cols="12" :sm="3">
                    <v-text-field
                        hide-details
                        outlined
                        clearable
                        dense
                        label="Number"
                        :disabled="isLoading"
                        v-model="localFilter.number"
                        @keydown.enter="updateLocalFilter"
                        @change="updateDID"
                    />
                </v-col>
              <v-col cols="12" :sm="3">
                <v-autocomplete
                    hide-details
                    outlined
                    clearable
                    dense
                    :items="descriptionOptions"
                    item-text="numbering_area"
                    item-value="numbering_area"
                    :disabled="loading"
                    label="Description/SZU"
                    v-model="localFilter.description"
                    placeholder="Type to search"
                    hide-no-data
                    return-object
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                    v-if="false"
                    hide-details
                    outlined
                    dense
                    label="Area code"
                    v-model="localFilter.areaCode"
                    :disabled="isLoading"
                    :items="areaCodeOptions"
                    item-text="option"
                    item-value="value"
                />
                <v-select
                    hide-details
                    outlined
                    dense
                    label="State"
                    v-model="localFilter.state"
                    :disabled="isLoading"
                    :items="stateOptions"
                    item-text="option"
                    item-value="value"
                />
              </v-col>
                <v-col cols="12" sm="3" v-if="statusValuePerm == null">
                    <v-select
                        v-if="statusValuePerm == null"
                        outlined
                        dense
                        label="Status"
                        v-model="localFilter.usage"
                        :disabled="isLoading"
                        :items="usageOptions"
                        item-text="option"
                        item-value="value"
                    />
                </v-col>
                <v-col cols="12" sm="3">
                    <v-btn
                        outlined
                        block
                        height="40"
                        :loading="isLoading"
                        @click="updateLocalFilter"
                    >Search</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <PaginationCard
            :page="filter.page"
            :data="data"
            :limit="filter.limit"
            :loading="loading || isLoading"
            v-on:updateLimit="updateLimit"
            v-on:updatePage="updatePage"
            :no-skeleton="!firstLoad"
        >
            <template v-slot:content v-if="$slots.content">
                <slot name="content" />
            </template>

            <v-toolbar
                dense
                fluid
                v-if="selected.length"
                :color="$vuetify.theme.dark ? 'blue-grey' : 'blue lighten-3'"
                flat
            >
                <v-btn icon @click="changeSelectAll(false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-toolbar-title
                    >{{ selected.length }} DID<span v-if="selected.length > 1"
                        >s</span
                    >
                    selected</v-toolbar-title
                >

            </v-toolbar>
            <v-simple-table>
                <thead>
                    <tr v-show="!selected.length">
                        <th >
                            <v-simple-checkbox
                                v-if="data.data && data.data.length"
                                v-model="selectAll"
                                @input="changeSelectAll"
                                dense
                            />
                        </th>
                        <th>Number</th>
                        <th v-if="statusValuePerm == null">Status</th>
                        <th>State</th>
                        <th>Description / SZU</th>
                        <th class="text-center">Activation Fee</th>
                        <th class="text-center">Recurring Fee</th>
                        <th class="text-center" v-if="$store.state.user.user_type === 'admin'">Carrier</th>
                        <th class="text-center" v-if="$store.state.user.user_type === 'admin'">Carrier Status</th>
                    </tr>
                </thead>
                <!--tbody -->

                <v-slide-x-transition tag="tbody" hide-on-leave group>
                    <tr v-if="data.data && data.data.length === 0" key="nodata">
                        <td colspan="100%" class="text-center">
                            No DIDs found
                        </td>
                    </tr>
                    <tr
                        v-for="item in data.data"
                        :key="item.i_did_number"
                        v-bind:class="{
                            'blue-grey darken-2':
                                $vuetify.theme.dark && item.selected,
                            'blue lighten-5': !$vuetify.theme.dark && item.selected,
                        }"
                    >
                        <td>
                            <v-simple-checkbox
                                dense
                                v-model="item.selected"
                                :disabled="disabled"
                                @input="updateSelected"
                            />
                        </td>

                        <td class="pa-0">
                            <router-link
                                v-if="$store.state.user.isAdmin"
                                :to="{name: 'DIDDetail', params: { did: item.hasOwnProperty('number') ? item.number : item.did_number }}"
                                style="cursor: pointer"
                                class="pa-4"
                            >
                                <span class="font-weight-medium">{{
                                    item.hasOwnProperty('number') ? item.number : item.did_number
                                }}</span>
                            </router-link>
                            <span v-else class="font-weight-medium">{{item.hasOwnProperty('number') ? item.number : item.did_number}}</span>
                        </td>

                        <td v-if="statusValuePerm == null">
                            <DIDStatus :data="item" />
                        </td>
                        <td>{{ item.hasOwnProperty('city_name') ? item.city_name : item.area_name }}</td>
                        <td>{{ item.description }}</td>
                        <td class="text-center" v-if="false">
                            <Currency :value="item.hasOwnProperty('periodic_fee') ? item.periodic_fee : item.pricing.customer_costs.periodic" add-gst />
                        </td>
                        <td class="text-center">
                          <Currency :value="item.hasOwnProperty('activation_cost') ? item.activation_cost : 0" add-gst />
                        </td>
                        <td class="text-center">
                          <Currency :value="item.hasOwnProperty('recurring_cost') ? item.recurring_cost : 0" add-gst />
                        </td>
                        <td class="text-center" v-if="$store.state.user.user_type === 'admin'">
                            <span v-if="item.i_vendor == 5">Auswide</span>
                            <span v-if="item.i_vendor == 6">NetSIP</span>
                            <span v-if="item.i_vendor == 8">VX</span>
                        </td>
                        <td class="text-center" v-if="$store.state.user.user_type === 'admin'">
                            <DIDCarrierStatus small :status="item.carrier_status" />
                        </td>
                    </tr>
                </v-slide-x-transition>
                <!-- /tbody -->
            </v-simple-table>

        </PaginationCard>
    </div>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import Currency from '../../pieces/Currency';
import DIDStatus from '../../pieces/DID/DIDStatus';
import DIDCarrierStatus from '../../pieces/DID/DIDCarrierStatus';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
export default {
    name: 'DIDPoolSelect',
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
    data: () => ({
        localFilter: {
            number: '',
            usage: 'A',
            state: 'any',
            areaCode:'any',
            description: '',
        },
        usageOptions: [
            { option: 'All', value: 'A' },
            { option: 'Unused', value: 'I' },
            { option: 'Unused+Res', value: 'F' },
            { option: 'In Use', value: 'U' },
            { option: 'Frozen', value: 'Z' },
        ],
      areaCodeOptions: [
        { option: 'Any', value: 'any' },
        { option: 'NSW/ACT (02)', value: '02' },
        { option: 'VIC/TAS (03)', value: '03' },
        { option: 'Mobile (04)', value: '04' },
        { option: 'QLD (07)', value: '07' },
        { option: 'WA,SA,NT (08)', value: '08' },
      ],
      stateOptions: [
        { option: 'Any', value: 'any' },
        { option: 'NSW', value: 'New South Wales' },
        { option: 'VIC', value: 'Victoria' },
        { option: 'QLD', value: 'Queensland' },
        { option: 'WA', value: 'Western Australia' },
        { option: 'SA', value: 'South Australia' },
        { option: 'NT', value: 'Northern Territory' },
        { option: 'ACT', value: 'Australian Capital Territory' },
        { option: 'TAS', value: 'Tasmania' },
      ],
      descriptionOptions: [],
        selectAll: false,
        transferDIDDialog: {
            show: false,
        },
        removeDIDDialog: {
            show: false,
        },
        selected: [],
        table: {
            headers: [
                {
                    text: 'Number',
                    align: 'start',
                    sortable: false,
                    value: 'number',
                },
                { text: 'City Name', value: 'city_name' },
                { text: 'Description', value: 'description' },
            ],
        },
    }),
    components: {
        DIDCarrierStatus,
        DIDStatus,
        Currency,
        PaginationCard,
    },
    props: {
        hidePagination: {
            type: Boolean,
            default: false,
        },
        // acctId: {
        //     type: Number,
        //     default: null,
        // },
        trunkData:{
          type:Object,
          default:null
        },
        // custId: {
        //     type: Number,
        //     default: null,
        // },
        customerData:{
          type:Object,
          default:null
        },
        resellerId: {
          type: Number,
          default: null,
        },
        reload: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        statusValuePerm: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        type:{
          type: String,
          required:true,
        }
    },
    methods: {
        refresh() {
            document.dispatchEvent(new CustomEvent('refreshRequested'));
        },
        updatePage(data){
          this.filter.page = data;
          this.update( );
        },
        updateLimit(data){
          if(this.filter.limit != data){
            this.filter.limit = data;
            this.filter.page = 1;
            this.update( );
          }
        },
        updateLocalFilter(){
          this.filter.page = 1;
          for (var item in this.localFilter) {
            this.filter[item] = this.localFilter[item];
          }
          if(typeof this.localFilter.description === 'object' && this.localFilter.description != null){
            this.filter.description = this.localFilter.description.numbering_area;
          }else{
            this.filter.description = '';
          }
          this.update( );
        },
        async update() {
            this.isLoading = true;
            //this.getLocalFilters();
            let params = { limit: this.filter.limit, page: this.filter.page };
            if (this.customerData) params.i_customer = this.customerData.i_customer;
            if (this.trunkData) params.i_account = this.trunkData.i_account;
            if (this.filter.frozen) params.frozen = this.filter.frozen;
            if(this.statusValuePerm != null){
              params.usage = this.statusValuePerm;
            }else{
              if (this.filter.usage) params.usage = this.filter.usage;
            }

          if (this.filter.state && this.filter.state != 'any') params.cityName = this.filter.state;
          if (this.filter.description) params.description = this.filter.description;
          if (this.filter.number) params.number = this.filter.number;

          //Any is wild card so we do not pass any area_code. Else pass the area_code
          if (this.filter.areaCode && this.filter.areaCode != 'any') {
            params.area_code = this.filter.areaCode;
          }
          /**
           * Here we use the type to determine how to handle the processing.
           * This will determine where we pull the details from.
           * Note that we also can pass the 'type' value from this component to
           * further help the API understand what we need and prevent hacking.
           */
          params.type = this.type;
          let queryString = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
          }).join('&');

          let response;
          if(this.type == 'assign-to-reseller'){
            //we need access to the global pool of DID to assign to resellers.
            delete(params.i_customer);
            params.managed_by = null;
            params.assign_to_reseller_id = this.resellerId;
            response = await this.Api.send('post','dids/list', params);
          }else if(this.type == 'assign-to-managed-customer'|| (this.type == 'link-to-account' && this.trunkData.isWholesaleManaged)){
            //we need to pull a list of the DIDs liked to the wholesale owner
            if(!this.resellerId){
              console.error('Missing reseller ID.');
            }
            //if admin or wholesale we pull a list of DID from the Wholesalers pool
            if(this.$store.state.user.isAdmin || this.$store.state.user.isManagedWholesale){
              response = await this.Api.send('get','wholesalers/'+this.resellerId+'/dids?'+queryString);
            }else if(this.$store.state.user.isPartner){
              //if partner we pull DID list for partner. Which should be the same as the wholesalers pool
              //we use this controller to validation reasons on the API end. EG a partner does not permissions to access a wholesale route.
              response = await this.Api.send('get','partners/'+this.$store.state.user.partner.i_rep+'/dids?'+queryString);
            }
          }else if(this.type == 'assign-to-customer'){
            //Normal default customer.
            if(this.$store.state.user.isPartner){
              response = await this.Api.send('get','partners/'+this.$store.state.user.partner.i_rep+'/dids?'+queryString);
            }else if(this.customerData.i_rep){
              response = await this.Api.send('get','partners/'+this.customerData.i_rep+'/dids?'+queryString);
            }else{
              //should be a customer directly attached to admin. Managed customers are catered above,
              response = await this.Api.send('post','dids/list', params);
            }
          }else if(this.type == 'link-to-account'){
            //We need to pull a list of DID that are allowed to be assigned for this trunk.

            //we remove the i_account from params. Should not be present else it will filter via DID attached to the trunk.
            delete(params.i_account);
            queryString = Object.keys(params).map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');
            if(this.trunkData.isWholesaleManaged){
              if(this.$store.state.user.isAdmin || this.$store.state.user.isManagedWholesale){
                response = await this.Api.send('get','wholesalers/'+this.customerData.i_parent+'/dids?'+queryString);
              }else if(this.$store.state.user.isPartner){
                //if partner we pull DID list for partner. Which should be the same as the wholesalers pool
                //we use this controller to validation reasons on the API end. EG a partner does not permissions to access a wholesale route.
                response = await this.Api.send('get','partners/'+this.customerData.i_rep+'/dids?'+queryString);
              }
            }else{
              if(this.customerData.i_rep){
                response = await this.Api.send('get','partners/'+this.customerData.i_rep+'/dids?'+queryString);
              }else if(this.$store.state.user.isAdmin){
                //customer is managed by admin
                response = await this.Api.send('post','dids/list', params);
              }
            }
          }else{
            throw 'Unrecognised type.';
          }

          // /**
          //  * if resellerID is set we follow different logic.
          //  * This value will change how we pull these value here.
          //  * As the DID logic in the UI and API is merging too much logic together.
          //  * where we start to split it out.
          //  */
          // let response;
          // if(this.resellerId > 0) {
          //   params.i_reseller = this.resellerId;
          //
          //   //unsure why this is here. need
          //   if (this.filter.areaCode && this.filter.areaCode != 'any') {
          //     params.area_code = this.filter.areaCode;
          //   }
          //
          //   let queryString = Object.keys(params).map((key) => {
          //     return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
          //   }).join('&');
          //
          //   if(this.$store.state.user.isAdmin || this.$store.state.user.isManagedWholesale){
          //     response = await this.Api.send('get','wholesalers/'+this.resellerId+'/dids?'+queryString);
          //   }else if(this.$store.state.user.isPartner){
          //     response = await this.Api.send('get','partners/'+this.$store.state.user.partner.i_rep+'/dids?'+queryString);
          //   }else{
          //     console.error('Invalid');
          //     return;
          //   }
          // }else{
          //   if (this.filter.areaCode && this.filter.areaCode != 'any'){
          //     params.areaCode = this.filter.areaCode;
          //   }
          //
          //   response = await this.Api.send('post','dids/list', params);
          // }
            this.selected = [];
            this.selectAll = false;
            this.isLoading = false;
            this.firstLoad = false;
            if (response.success) {
                this.data = response.data;
            } else {
                this.data = [];
            }
        },
        updateStatus(data) {
            this.$emit('update', { status: data, page: 1 })
        },
        updateSelected() {
            this.selected = this.data.data.filter((x) => x.selected);
            this.$emit('input', this.selected);
        },
        changeSelectAll(value) {
            this.data.data.forEach((item) => {
                item.selected = value;
            });
            this.selectAll = value;
            this.updateSelected();
        },
        updateDID() {
            if (!!this.localFilter.number) {
                if (this.localFilter.number.includes(' ')) this.localFilter.number = this.localFilter.number.replaceAll(' ', '');
            }
        }
    },
  async created( ){
    const response = await this.Api.send('get','resources/unique-did-descriptions');
    this.descriptionOptions = response.data;
  },

};
</script>
