<template>
  <BasicCard
      :loading="isBusy"
      :no-skeleton="false"
  >
    <template v-slot:feature>
      <v-icon right x-large>mdi-receipt-text</v-icon>
    </template>
    <p v-if="invoiceTemplateData != null" class="title text--primary">{{invoiceTemplateData.label}}</p>
    <v-row dense v-if="invoiceTemplateData != null">
      <v-col cols="4">
        <span class="font-weight-bold">Last Published At</span>
      </v-col>
      <v-col cols="8">
        {{invoiceTemplateData.last_published_at_formatted_aus}}
      </v-col>
    </v-row>
    <template v-slot:actions v-if="invoiceTemplateData != null">
      <ButtonWithTooltip
          text="Edit"
          flat
          :tooltip="'Edit '+invoiceTemplateData.label+' Details'"
          icon="mdi-pencil"
          :to="$store.state.user.isAdmin ? {name:'AdminWholesaleManagedInvoiceTemplateEdit', params:{customerId:invoiceTemplateData.i_customer,wholesaleManagedInvoiceTemplateTag: 'default'}} : { name: 'WholesaleManagedInvoiceTemplateEdit', params:{wholesaleManagedInvoiceTemplateTag: 'default'}}"
      />
      <ButtonWithTooltip
          text="Preview"
          flat
          :tooltip="'View invoice preview options.'"
          icon="mdi-receipt"
          @click="showDemoDialog = true"
      />
      <ButtonWithTooltip
          text="Publish"
          flat
          :tooltip="'Publishes the current working version.'"
          icon="mdi-publish"
          @click="publishTemplate()"
      />
    </template>
    <InvoiceTemplateDemoOptionsDialog v-model="showDemoDialog" :invoice-template-data="invoiceTemplateData" v-if="invoiceTemplateData != null"/>
  </BasicCard>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import resellerDataMixin from "../../../mixins/resellerDataMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import InvoiceTemplateDemoOptionsDialog from "../../dialogs/Wholesalers/InvoiceTemplateDemoOptionsDialog";
export default {
    name: 'WholesalerInvoiceTemplateDetailsCard',
    mixins: [apiMixin, resellerDataMixin, GlobalHelperMixin],
    data: () => ({
      showEditDialog:false,
      showDemoDialog:false,
      busy: false,
    }),
    components: {
      InvoiceTemplateDemoOptionsDialog,
      BasicCard,
      ButtonWithTooltip,
    },
    props: {
      invoiceTemplateData:{
          type: Object,
          default: null,
        },
      loading:{
          type: Boolean,
          default:false,
      }
    },
  computed:{
    isBusy( ){
      return this.loading || this.busy;
    }
  },
    methods: {
      async publishTemplate() {
        this.busy = true;
        const response = await this.Api.send('put', 'wholesalers/' + this.invoiceTemplateData.i_customer + '/invoice-templates/default/publish');
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.showGlobalSuccessMessage('Invoice Template published successfully.');
        }else{
          this.showGlobalErrorMessage('Failed to publish template.');
        }
        this.busy = false;
      },
    },
};
</script>