<template>
  <div>
    <PartnerListCard v-if="iCustomer" :reseller-id="iCustomer" add-button @addButtonTriggered="showNewPartner = true"/>
    <NewPartnerDialog v-if="iCustomer" type="wholesale-partner" v-model="showNewPartner" :resellerData="resellerData" @customer="goToPartner" />
    <FloatingButton
        tooltip="New Partner"
        icon="mdi-account-plus"
        @click="showNewPartner = true"
    />
  </div>
</template>

<script>
import NewPartnerDialog from "../../components/dialogs/Partners/NewPartnerDialog";
import PartnerListCard from "../../components/cards/Partner/PartnerListCard";
import FloatingButton from "../../components/pieces/FloatingButton";
import resellerDataMixin from "../../mixins/resellerDataMixin";
export default {
    name: 'WholesalePartnerList',
    data: () => ({
      showNewPartner:false,
      customerClassData:{}, //holds selected customer class data we pass to the create process
    }),
    mixins: [resellerDataMixin],
    components: {
      NewPartnerDialog,
      PartnerListCard,
      FloatingButton
    },
    methods: {
      goToPartner(id) {
        this.showNewPartner = false;
        this.$router.push({ name: 'CustomerSummary', params: { customerId: id }});
      },
    },
  computed:{
    isBusy( ){
      return !this.resellerLoading && !(this.resellerData && this.resellerData.i_customer)
    },
    iCustomer( ){
      if(this.resellerData && this.resellerData.i_customer){
        return this.resellerData.i_customer;
      }else{
        return null;
      }
    }
  }
};
</script>