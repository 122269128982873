<template>
  <ComplexDialog
      :title="'Edit Customer Class ('+customerClassData.title+') Finance Details'"
      v-model="visible"
      :loading="busy"
  >
    <v-form ref="form" v-model="isValid">
      <v-select outlined label="Send statistics" :items="statisticsSummaryOptions" v-model="form.send_statistics" :rules="rules.required" hide-details
                item-text="option"
                item-value="value"
      />
    </v-form>
    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="busy"
          @click="update"
          text
          :disabled="!isValid"
      >Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
export default {
  name: 'EditWholesaleCustomerClassFinanceDetailsDialog',
  mixins: [apiMixin, dialogMixin],
  components: { ComplexDialog },
  data: () => ({
    valid: false,
    form: {
      send_statistics:null,
    },
    busy: false,
    isValid: false,
    statisticsSummaryOptions:[
      { option: 'Do not send', value: 'N'},
      { option: 'Send Summary', value: 'S' },
      { option: 'Send Full Statistics', value: 'F' },
    ],
    rules: {
      name: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1) || 'Must contain at least 2 characters.',
        (v) => (!!v && v.length < 51) || 'Must contain at less than 50 characters.'
      ],
      subName: [
        (v) => (!v || v.length > 1) || 'Must contain at least 2 characters.',
        (v) => (!v || v.length < 64) || 'Must contain at less than 63 characters.'
      ],
      price: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length >= 0) || 'Price must be zero or greater.'
      ],
      // email: [
      //   (v) => !!v || 'Required',
      //   (v) => /^.+@.+\..+$/.test(v) || 'Invalid email',
      // ],
      // phone1: [
      //   (v) => !!v || 'Required',
      //   (v) => (/^(?:0[2,3,5-9]\d{8})?$/.test(v) || /^(?:04\d{8})?$/.test(v)) || 'Invalid Phone/Mobile number.'
      // ],
    }

  }),
  methods: {
    async update() {
      this.busy = true;
      const param = this.form;
      const response = await this.Api.send('put', 'wholesalers/' + this.customerClassData.i_customer + '/customer-classes/'+this.customerClassData.i_customer_class, param);
      if (response.success) {
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
      }
      this.busy = false;
    },

  },

  props: {
    customerClassData: {
      type: Object,
      default: null
    },
    loading:{
      type: Boolean,
      default: false,
    }
  },
  created(){
    if(this.customerClassData.send_statistics != null){
      this.form.send_statistics = this.customerClassData.send_statistics;
    }
  },
  computed: {
    isBusy() {
      return this.loading || this.busy;
    },
  }
};
</script>