<template>
  <ComplexDialog
      title="Edit Product Details"
      v-model="visible"
      :loading="busy"
  >
    <v-form ref="form" v-model="isValid">
      <v-switch class="ma-0 pa-2" v-model="form.visible" hide-details  :label="form.visible?'Visible to users':'Hidden from users'" />
      <v-switch class="ma-0 pa-2 mb-6" :disabled="!form.visible" v-model="form.available_to_partners" hide-details :label="form.available_to_partners?'Available to Partners':'Not available to Partners'"/>
      <v-text-field v-model="form.end_user_name" outlined label="Name" :rules="rules.name" :counter="50"/>
      <v-text-field v-model="form.end_user_description" outlined label="Sub Name" :rules="rules.subName" :counter="63"/>
    </v-form>
    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="busy"
          @click="update"
          text
          :disabled="!isValid"
      >Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
export default {
  name: 'EditWholesaleProductDetailsDialog',
  mixins: [apiMixin, dialogMixin],
  components: { ComplexDialog },
  data: () => ({
    valid: false,
    form: {
      visible:null,
      available_to_partners:null,
      end_user_name:null,
      end_user_description:null,
    },
    busy: false,
    isValid: false,
    rules: {
      name: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1) || 'Must contain at least 2 characters.',
        (v) => (!!v && v.length < 51) || 'Must contain at less than 50 characters.'
      ],
      subName: [
        (v) => (!v || v.length > 1) || 'Must contain at least 2 characters.',
        (v) => (!v || v.length < 64) || 'Must contain at less than 63 characters.'
      ],
      price: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length >= 0) || 'Price must be zero or greater.'
      ],
      // email: [
      //   (v) => !!v || 'Required',
      //   (v) => /^.+@.+\..+$/.test(v) || 'Invalid email',
      // ],
      // phone1: [
      //   (v) => !!v || 'Required',
      //   (v) => (/^(?:0[2,3,5-9]\d{8})?$/.test(v) || /^(?:04\d{8})?$/.test(v)) || 'Invalid Phone/Mobile number.'
      // ],
    }

  }),
  methods: {
    async update() {
      this.busy = true;
      const param = this.form;
      const response = await this.Api.send('put', 'wholesalers/' + this.productData.i_customer + '/products/'+this.productData.i_product, param);
      if (response.success) {
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
      }
      this.busy = false;
    },

  },

  props: {
    productData: {
      type: Object,
      default: null
    },
    loading:{
      type: Boolean,
      default: false,
    }
  },
  created(){
    if(this.productData.visible_at == null){
      this.form.visible = false;
    }else{
      this.form.visible = true;
    }
    if(this.productData.partner_enabled_at == null){
      this.form.available_to_partners = false;
    }else{
      this.form.available_to_partners = true;
    }
    this.form.end_user_name = this.productData.end_user_name;
    this.form.end_user_description = this.productData.end_user_description;
  },
  computed: {
    isBusy() {
      return this.loading || this.busy;
    },
  }
};
</script>