<template>
  <div>
    <!-- <v-row dense justify="center"> -->
    <v-row dense>
      <v-col cols="6" class="d-none d-xl-flex" v-if="false" > <!-- Hidden for now -->
        <div style="margin-left:auto; margin-right:auto;width:791px">
        <div style="position:fixed">
            <v-img
              src="../../assets/img/wholesale-invoice-templates/default/invoice-template-default-locations.png"
              max-height="1121"
              max-width="791"
              contain
            />
        </div>
        </div>
      </v-col>
      <v-col cols="12" xl="6" lg="8" md="10" sm="12">
        <BasicCard
            :loading="isBusy"
            :no-skeleton="false"
        >
          <div style="float:right" v-if="$vuetify.breakpoint.lgAndDown && false"> <!-- hidden for now -->
            <ButtonWithTooltip
                text="Overview"
                color="primary"
                small
                @click="showDemoDialog=true"
            />
          </div>
          <div style="float:right">
            <ButtonWithTooltip
              text="Overview"
              color="primary"
              small
              @click="showDemoDialog=true"
            />
          </div>
          <p v-if="invoiceTemplateData != null" class="title text--primary">Edit {{invoiceTemplateData.label}} Invoice</p>
          <p>If any section are left empty they will not be included in the output of the invoice. The invoice will adjust itself to cater for
          any missing sections. In bodies of text new lines are preserved.</p>
          <v-form v-model="valid" :disabled="isBusy">
            <SpinnerOverlay absolute v-if="isBusy" />
            <div style="border:2px solid #265CFF; margin-bottom:10px;border-radius: 10px;padding-top:10px;">
            <v-row no-gutters>
              <v-col cols="12" md="6" style="padding:8px;">
                <div
                    style="
                  max-height:239px;
                  max-width:357px;
                  margin-left:auto;
                  margin-right:auto;
                ">
                  <v-img
                      src="../../assets/img/wholesale-invoice-templates/default/invoice-template-default-locations-alert.png"
                      max-height="239"
                      max-width="357"
                      contain
                  />
                </div>
              </v-col>
              <v-col cols="12" md="6" style="padding:8px;">
                <v-text-field
                    v-model="form.alert_title"
                    label="Alert Title"
                    outlined
                    hint="The title shown in the alert section."
                />
                <v-textarea
                    outlined
                    v-model="form.alert_body"
                    clear-icon="mdi-close-circle"
                    label="Alert Body"
                    hint="The main body of the alert section."
                ></v-textarea>
              </v-col>
            </v-row>
          </div>

            <div style="border:2px solid #C20CFF; margin-bottom:10px;border-radius: 10px;">
              <v-row no-gutters>
                <v-col cols="12" style="padding:8px;">
                  <div style="
                  max-height:193px;
                  max-width:688px;
                  margin-left:auto;
                  margin-right:auto;
                ">
                    <v-img
                        src="../../assets/img/wholesale-invoice-templates/default/invoice-template-default-locations-contact.png"
                        max-height="193"
                        max-width="688"
                        contain
                    />
                  </div>
                </v-col>
                <v-col cols="12" style="padding:8px;">
                  <v-text-field
                      v-model="form.contact_section_title"
                      label="Contact Title"
                      outlined
                      hint="The title shown in the contact section."
                  />
                  </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" lg="3" md="6" style="padding:8px;">
                  <v-textarea
                      outlined
                      v-model="form.contact_section_phone_body"
                      clear-icon="mdi-close-circle"
                      label="Phone Body"
                      hint="Body for the phone section."
                  ></v-textarea>
                </v-col>
                <v-col cols="6" lg="3" md="6" style="padding:8px;">
                  <v-textarea
                      outlined
                      v-model="form.contact_section_email_body"
                      clear-icon="mdi-close-circle"
                      label="Email Body"
                      hint="Body for the email section."
                  ></v-textarea>
                </v-col>
                <v-col cols="6" lg="3" md="6" style="padding:8px;">
                  <v-textarea
                      outlined
                      v-model="form.contact_section_website_body"
                      clear-icon="mdi-close-circle"
                      label="Website Body"
                      hint="Body for the website section."
                  ></v-textarea>
                </v-col>
                <v-col cols="6" lg="3" md="6" style="padding:8px;">
                  <v-textarea
                      outlined
                      v-model="form.contact_section_office_hours_body"
                      clear-icon="mdi-close-circle"
                      label="Office hours body"
                      hint="Body for the office hours section."
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>

            <div style="border:2px solid #00D936;border-radius: 10px;">
              <v-row no-gutters>
                <v-col cols="12" style="padding:8px;">
                  <div style="
                  max-height:180px;
                  max-width:689px;
                  margin-left:auto;
                  margin-right:auto;
                ">
                  <v-img
                      src="../../assets/img/wholesale-invoice-templates/default/invoice-template-default-locations-payment.png"
                      max-height="180"
                      max-width="689"
                      contain
                  />
                  </div>
                </v-col>
                <v-col cols="12" style="padding:8px;">
                  <v-text-field
                      v-model="form.payment_section_title"
                      label="Payment Title"
                      outlined
                      hint="The title shown in the payment section."
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6" style="padding:8px;">
                  <v-text-field
                      v-model="form.payment_section_first_header"
                      label="Payment Header 1"
                      outlined
                      hint="Header for the first payment section."
                  />
                  <v-textarea
                      outlined
                      v-model="form.payment_section_first_body"
                      clear-icon="mdi-close-circle"
                      label="Payment Section 1"
                      hint="Body for payment section 1."
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6" style="padding:8px;">
                  <v-text-field
                      v-model="form.payment_section_second_header"
                      label="Payment Header 2"
                      outlined
                      hint="Header for the second payment section."
                  />
                  <v-textarea
                      outlined
                      v-model="form.payment_section_second_body"
                      clear-icon="mdi-close-circle"
                      label="Payment Section 2"
                      hint="Body for payment section 2."
                  ></v-textarea>
                </v-col>
              </v-row>
            </div>
          </v-form>
          <template v-slot:actions v-if="invoiceTemplateData != null">
            <ButtonWithTooltip
                text="Save"
                flat
                :tooltip="'Save working details'"
                icon="mdi-content-save"
                @click="saveWorkingSettings('save')"
            />
            <ButtonWithTooltip
                text="Save and download preview"
                flat
                :tooltip="'Save and download preview'"
                icon="mdi-content-save"
                @click="saveWorkingSettings('save-preview')"
            />
            <ButtonWithTooltip
                text="Save and Publish"
                flat
                :tooltip="'Save publishes the current working version.'"
                icon="mdi-publish"
                @click="saveWorkingSettings('save-publish')"
            />
          </template>
        </BasicCard>
      </v-col>
    </v-row>
    <SimpleDialog
        v-model="showDemoDialog"
        :maxWidth="860"
        okOnly
      >
      <div style="margin-left:auto; margin-right:auto;width:791px">
        <v-img
            src="../../assets/img/wholesale-invoice-templates/default/invoice-template-default-locations.png"
            max-height="1121"
            max-width="791"
            contain
        />
      </div>
    </SimpleDialog>
  </div>
</template>

<script>
import resellerDataMixin from "../../mixins/resellerDataMixin";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
import BasicCard from "../../components/cards/templates/BasicCard";
import SimpleDialog from "../../components/dialogs/templates/SimpleDialog";
import ButtonWithTooltip from "../../components/pieces/ButtonWithTooltip";
import GlobalHelperMixin from "../../mixins/GlobalHelperMixin";

export default {
    name: 'WholesaleManagedInvoiceTemplate',
    data: () => ({
      valid:false,
      form:{
        alert_title:null,
        alert_body:null,
        contact_section_title:null,
        contact_section_phone_body:null,
        contact_section_email_body:null,
        contact_section_website_body:null,
        contact_section_office_hours_body:null,
        payment_section_title:null,
        payment_section_first_header:null,
        payment_section_first_body:null,
        payment_section_second_header:null,
        payment_section_second_body:null,
      },
      showDemoDialog:false,
      invoiceTemplateData:null,
      busyCounter:0,
      linkBase: process.env.VUE_APP_API_BASE || '/api/',
    }),
    mixins: [resellerDataMixin, refreshListenerMixin, GlobalHelperMixin],
    components: {
      ButtonWithTooltip,
      SimpleDialog,
      BasicCard,
    },
    methods: {
      resetForm(){
        this.form = {
          alert_title:this.invoiceTemplateData.working_settings.alert_title,
          alert_body:this.invoiceTemplateData.working_settings.alert_body,
          contact_section_title:this.invoiceTemplateData.working_settings.contact_section_title,
          contact_section_phone_body:this.invoiceTemplateData.working_settings.contact_section_phone_body,
          contact_section_email_body:this.invoiceTemplateData.working_settings.contact_section_email_body,
          contact_section_website_body:this.invoiceTemplateData.working_settings.contact_section_website_body,
          contact_section_office_hours_body:this.invoiceTemplateData.working_settings.contact_section_office_hours_body,
          payment_section_title:this.invoiceTemplateData.working_settings.payment_section_title,
          payment_section_first_header:this.invoiceTemplateData.working_settings.payment_section_first_header,
          payment_section_first_body:this.invoiceTemplateData.working_settings.payment_section_first_body,
          payment_section_second_header:this.invoiceTemplateData.working_settings.payment_section_second_header,
          payment_section_second_body:this.invoiceTemplateData.working_settings.payment_section_second_body,
        }
      },

      async loadInvoiceTemplate( ){
        this.busyCounter++;
        const response = await this.Api.send('get', 'wholesalers/' + this.resellerData.i_customer + '/invoice-templates/'+this.$route.params.wholesaleManagedInvoiceTemplateTag);
        this.busyCounter--;
        this.invoiceTemplateData = null
        if(response.success){
          this.invoiceTemplateData = response.data;
          this.resetForm( );
        }
        this.buildBreadCrumbs();
      },

      async saveWorkingSettings(type){
        this.busyCounter++;
        let params = {working_settings:this.form};
        const response = await this.Api.send('put', 'wholesalers/' + this.resellerData.i_customer + '/invoice-templates/'+this.$route.params.wholesaleManagedInvoiceTemplateTag,params);
        this.busyCounter--;
        this.invoiceTemplateData = null
        if(response.success){
          this.invoiceTemplateData = response.data;
          this.showGlobalSuccessMessage('Saved working version successfully.');
          if(type == 'save-preview'){
            window.location.href = this.linkBase + 'invoice-templates/'+this.invoiceTemplateData.working_summary_i_template+'/preview?session-token=' + this.$store.state.session.token;
          }else if(type == 'save-publish'){
            this.publishTemplate( );
          }
        }
      },

      async publishTemplate() {
        this.busyCounter++;
        const response = await this.Api.send('put', 'wholesalers/' + this.invoiceTemplateData.i_customer + '/invoice-templates/default/publish');
        this.busyCounter--;
        if (response.success) {
          this.showGlobalSuccessMessage('Invoice Template published successfully.');
        }else{
          this.showGlobalErrorMessage('Failed to publish template.');
        }
      },

      update( ){
        this.loadInvoiceTemplate();
      },
      buildBreadCrumbs( ){
        let breadCrumbs;
        if(this.$store.state.user.isAdmin){
          breadCrumbs = [
            {
              //name:"AdminWholesaleManagedInvoiceTemplates",
              label: "Invoice Templates",
              //params:{"customerId":this.localIReseller}
            },
            {
              name:"AdminWholesaleManagedInvoiceTemplate",
              label: this.invoiceTemplateData.label,
              params:{"customerId":this.localIReseller,"wholesaleManagedInvoiceTemplateTag":this.invoiceTemplateData.tag}
            },
            {
              name:"AdminWholesaleManagedInvoiceTemplateEdit",
              label: 'Edit',
              params:{"customerId":this.localIReseller,"wholesaleManagedInvoiceTemplateTag":this.invoiceTemplateData.tag}
            }

          ];
        }else{
          breadCrumbs = [
            {
              //name:"WholesaleManagedInvoiceTemplates",
              label: "Invoice Templates",
              //params:{}
            },
            {
              name:"WholesaleManageddInvoiceTemplate",
              label: this.invoiceTemplateData.label,
              params:{"wholesaleManagedInvoiceTemplateTag":this.invoiceTemplateData.tag}
            },
            {
              name:"WholesaleManageddInvoiceTemplateEdit",
              label: 'Edit',
              params:{"wholesaleManagedInvoiceTemplateTag":this.invoiceTemplateData.tag}
            }
          ];
        }
        this.$store.commit('data', {name: 'breadCrumbsAddon', data: breadCrumbs});
      }
    },
    created() {
      if(this.resellerData && this.resellerData.i_customer){
        this.update();
      }
    },
    watch: {
      localIReseller(value) {
       if(value != null){
         this.update( );
       }
      }
    },
    computed:{
      isBusy( ){
        return this.resellerLoading || this.busyCounter > 0;
      },
      localIReseller( ){
        if(this.resellerData && this.resellerData.i_customer){
          return this.resellerData.i_customer;
        }else{
          return null;
        }
      }
    }
};
</script>
<style>
.centerDemo
{
  position: sticky;

  /*position: fixed;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  min-height: 1121px;
  width: 90%;
  background-color: blue;*/

  /*
  height:1121px;
  width:791px;
  left:50%;
  margin-left:-395px;
  position: absolute;
  */
}
</style>