<template>
  <v-row>
    <v-col cols="4"
        v-for="product in products"
        :key="product.i_product"
    >
      <WholesalerProductListCard :loading="isBusy" :product-data="product" />
    </v-col>
    <v-col cols="4"
           v-if="isBusy"
    >
      <BasicCard
          :loading="true"
          :no-skeleton="false"
      />
    </v-col>
  </v-row>
</template>

<script>
import BasicCard from "../../cards/templates/BasicCard";
import WholesalerProductListCard from "../../cards/Wholesalers/WholesalerProductListCard";
export default {
    name: 'WholesaleManagedProducts',
    data: () => ({
      busy: false,
      products:null,
    }),
    components: {
      WholesalerProductListCard,
      BasicCard,
    },
    methods: {
      async getProducts() {
        this.busy = true;
        const response = await this.Api.send('get','wholesalers/'+this.resellerData.i_customer+'/products');
        this.busy = false;
        this.products = null;
        if(response.success){
          this.products = response.data;
        }
      },
    },
  created( ){
      if(this.resellerData && this.resellerData.i_customer) {
        this.getProducts();
      }
  },
  computed:{
    isBusy( ){
      return this.loading || this.busy;
    },
  },
  watch: {
    resellerData(value) {
      if (value.i_customer) {
        this.getProducts();
      }
    }
  },
  props: {
    resellerData:{
      type: Object,
      default: null,
    },
    loading:{
      type: Boolean,
      default: false
    }
  },
};
</script>