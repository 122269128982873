<template>
  <BasicCard
      :loading="loading"
      :no-skeleton="false"
      title="Invoicing"
  >
    <p>* comma separated values indicates multiple values. EG 2,3,5 indicate day 2, day 3 and day 5.</p>
    <v-row dense v-if="customerClassData != null">
      <v-col cols="8">
        <span class="font-weight-bold">Payment due days</span>
      </v-col>
      <v-col cols="4">
        {{customerClassData.grace_period}} day(s)
      </v-col>

      <v-col cols="8">
        <span class="font-weight-bold">Notify due date in advance</span>
      </v-col>
      <v-col cols="4">
        {{customerClassData.notific_days}} day(s)
      </v-col>

      <v-col cols="8">
        <span class="font-weight-bold">Resend the invoice after the due date</span>
      </v-col>
      <v-col cols="4">
        <v-icon
            v-if="!customerClassData.resend_days || customerClassData.resend_days == null || customerClassData.resend_days == ''"
            color="error"
            class="mr-2"
        >mdi-close</v-icon>
        <span v-else>{{customerClassData.resend_days}} day(s)</span>
      </v-col>
      <v-col cols="8">
        <span class="font-weight-bold">Overdue days until suspension</span>
      </v-col>
      <v-col cols="4">
        <v-icon
            v-if="!customerClassData.suspension_days || customerClassData.suspension_days == null || customerClassData.suspension_days == ''"
            color="error"
            class="mr-2"
        >mdi-close</v-icon>
        <span v-else>{{customerClassData.suspension_days}} day(s)</span>
      </v-col>
      <v-col cols="8">
        <span class="font-weight-bold">Warning of suspension</span>
      </v-col>
      <v-col cols="4">
        {{customerClassData.warning_days}} day(s)
      </v-col>
    </v-row>
    <template v-slot:actions>
      <ButtonWithTooltip
          v-if="customerClassData != null"
          text="Edit"
          flat
          :tooltip="'Edit'+customerClassData.title+' Details'"
          icon="mdi-pencil"
          @click="showEditDialog = true"
      />
    </template>

    <EditWholesaleCustomerClassInvoicingDetailsDialog v-model="showEditDialog" :customer-class-data="customerClassData"/>
  </BasicCard>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import resellerDataMixin from "../../../mixins/resellerDataMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import EditWholesaleCustomerClassInvoicingDetailsDialog
  from "../../dialogs/Wholesalers/EditWholesaleCustomerClassInvoicingDetailsDialog";
export default {
    name: 'WholesalerCustomerClassInvoiceDetailsCard',
    mixins: [apiMixin, resellerDataMixin, GlobalHelperMixin],
    data: () => ({
      showEditDialog:false,
      busy: false,
    }),
    components: {
      EditWholesaleCustomerClassInvoicingDetailsDialog,
      BasicCard,
      ButtonWithTooltip,
    },
    props: {
      customerClassData:{
        type: Object,
        default: null,
      },
      loading:{
          type: Boolean,
          default:false,
      }
    },
    computed:{

    },
    methods: {
      async toggleAdminDisable() {
        this.busy = true;
        const param = this.form;
        var disable = (this.productData.admin_blocked_at == null);
        const response = await this.Api.send('put', 'wholesalers/' + this.productData.i_customer + '/products/'+this.productData.i_product+'/admin-block-toggle', param);
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          if(disable){
            this.showGlobalSuccessMessage('Product disabled successfully.');
          }else{
            this.showGlobalSuccessMessage('Product enabled successfully.');
          }
        }else{
          this.showGlobalErrorMessage('Failed to update.');
        }
        this.busy = false;
      },
    },
};
</script>