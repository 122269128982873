<template>
    <ComplexDialog
        :title="title"
        v-model="visible"
        :max-width="650"
        :loading="busy"
    >
        <v-window v-model="step" touchless>
            <v-window-item :value="1">
                <p class="subtitle-1 text--primary">
                    What would you like to do with the selected DIDs?
                </p>
                <RadioSelect
                    v-model="removeType.value"
                    :items="removeType.options"
                />
            </v-window-item>
            <v-window-item :value="2">
                <v-row dense>
                    <v-col cols="12">
                        <p class="subtitle-1 text--primary">
                            <span v-if="!busy && !taskProgress"
                                >Please review the list of
                                {{ didListLocal.length }} DID<span
                                    v-if="didListLocal.length !== 1"
                                    >s</span
                                >
                                to be removed.</span
                            >
                            <span v-if="busy"
                                >Please wait while the DIDs are removed...</span
                            >
                            <span v-if="!busy && taskProgress"
                                >Operation completed, please check results
                                below.</span
                            >
                        </p>
                        <v-alert
                            v-if="
                                !busy && !taskProgress && removeType.value === 1
                            "
                            type="info"
                            >The DIDs will immediately become available for use
                            by other customers.</v-alert
                        >
                        <v-alert
                            v-if="
                                !busy && !taskProgress && removeType.value === 3
                            "
                            type="error"
                            >The DIDs will be permanently deleted from the
                            inventory. This operation cannot be undone!</v-alert
                        >
                        <v-progress-linear
                            rounded
                            height="25"
                            v-if="busy || taskProgress > 0"
                            class="my-4"
                            :value="taskProgress"
                        >
                            <template v-slot:default="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                        </v-progress-linear>
                        <v-simple-table dense fixed-header height="400">
                            <thead>
                                <tr>
                                    <th>Phone Number</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in didListLocal"
                                    v-bind:key="item.number"
                                    dense
                                >
                                    <td>{{ item.number }}</td>
                                    <td>
                                        <span v-if="!busy && !taskProgress"
                                            >Remove DID
                                            <span v-if="removeType.value === 1">
                                                and release to pool</span
                                            >
                                            <span
                                                v-else-if="removeType.value === 2 && $store.state.user.isAdmin"
                                            >
                                                and freeze it</span
                                            >
                                            <span
                                                v-else-if="
                                                    removeType.value === 3
                                                "
                                            >
                                                from inventory entirely</span
                                            >
                                        </span>
                                        <span v-if="busy || taskProgress">
                                            <v-icon
                                                color="success"
                                                class="mr-2"
                                                v-if="item.success"
                                                >mdi-check-circle</v-icon
                                            >
                                            <v-icon
                                                color="warning"
                                                class="mr-2"
                                                v-if="item.error"
                                                >mdi-alert</v-icon
                                            >
                                            <v-progress-circular
                                                v-if="item.busy"
                                                class="mr-2"
                                                :width="3"
                                                :size="20"
                                                color="primary"
                                                indeterminate
                                            ></v-progress-circular
                                            >{{ item.status }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
        <template v-slot:actions>
            <v-btn
                v-if="(step + stepDiff) === 2"
                text
                :disabled="busy || taskProgress > 0"
                @click="step--"
                >Back</v-btn
            >
            <v-spacer />
            <v-btn
                @click="step++"
                color="primary"
                :disabled="busy || (step === 1 && !removeType.value)"
                v-if="step !== 2"
                text
                >Next</v-btn
            >
            <v-btn
                @click="removeDIDs"
                color="primary"
                :disabled="busy"
                v-if="step === 2 && taskProgress < 100"
                text
                >Remove {{ didListLocal.length }} DID<span
                    v-if="didListLocal.length !== 1"
                    >s</span
                ></v-btn
            >
            <v-btn
                @click="visible = false"
                :disabled="busy"
                color="primary"
                tooltip="Close this window"
                v-if="step === 2 && taskProgress === 100"
                text
                >Close</v-btn
            >
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import RadioSelect from '../../pieces/Forms/RadioSelect';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'RemoveDIDDialog',
    mixins: [apiMixin, dialogMixin],
    components: {
        RadioSelect,
        ComplexDialog,
    },
    data: () => ({
        step: 1,
        stepDiff: 0,
        removeType: {
            value: null,
            options: [
                {
                    value: 1,
                    label: 'Release to pool',
                    description:
                        'DIDs will be immediately released back to pool and become available for use by other customers',
                },
                {
                    value: 2,
                    label: 'Freeze/Quarantine',
                    description: 'DIDs will be placed into frozen status',
                },
                {
                    value: 3,
                    label: 'Delete',
                    description:
                        'DIDs will be deleted entirely from the inventory',
                },
            ],
        },
        busy: false,
        fail: false,
        tasksComplete: 0,
        didListLocal: [],
    }),
    methods: {
        async removeDID(did) {
            let data = {};
            switch (this.removeType.value) {
                case 1:
                    data.action = 'RELEASE';
                    break;
                case 2:
                    data.action = 'FREEZE';
                    break;
                case 3:
                    data.action = 'DELETE';
                    break;
            }
            if (this.trunkId) data.i_account = this.trunkId;
            await this.$nextTick();
            let response = await this.Api.send('post',
                'dids/' + did.number + '/remove',
                data
            );
            if (response.success) {
                switch (this.removeType.value) {
                    case 1:
                        return {
                            success: true,
                            message: 'DID released to pool',
                        };
                    case 2:
                        return { success: true, message: 'DID frozen' };
                    case 3:
                        return {
                            success: true,
                            message: 'DID deleted from inventory',
                        };
                }
            } else {
                return { success: false, message: response.errorDetail };
            }
        },
        removeDIDs: function () {
            this.busy = true;
            const maxNumOfWorkers = 1;
            var numOfWorkers = 0;
            var taskIndex = 0;

            return new Promise((done) => {
                const handleResult = (index) => (result) => {
                    this.didListLocal[index].busy = false;
                    this.didListLocal[index].status = result.message;
                    if (result.success) {
                        this.didListLocal[index].success = true;
                    } else {
                        this.didListLocal[index].error = true;
                    }
                    numOfWorkers--;
                    this.tasksComplete++;
                    getNextTask();
                };
                const getNextTask = () => {
                    if (
                        numOfWorkers < maxNumOfWorkers &&
                        taskIndex < this.didListLocal.length
                    ) {
                        this.didListLocal[taskIndex].status = 'Please wait...';
                        this.didListLocal[taskIndex].busy = true;
                        this.removeDID(this.didListLocal[taskIndex])
                            .then(handleResult(taskIndex))
                            .catch(handleResult(taskIndex));
                        taskIndex++;
                        numOfWorkers++;
                        getNextTask();
                    } else if (
                        numOfWorkers === 0 &&
                        taskIndex === this.didListLocal.length
                    ) {
                        taskIndex++;
                      document.dispatchEvent(new CustomEvent('refreshRequested'));
                      this.busy = false;
                        done();
                    }
                };
                getNextTask();
            });
        },
      reset: function ( ){
        if(this.$store.state.user.isPartner || this.$store.state.user.isByoWholesale || this.$store.state.user.isManagedWholesale){
          this.step = 2;
          this.stepDiff = -1;
          this.removeType.value = 2;
        }else{
          this.step = 1;
          this.stepDiff = 0;
          this.removeType.value = null;
        }
        this.didListLocal = [];
        this.tasksComplete = 0;
      }
    },
    watch: {
        transferType: function (val) {
            if (val !== 1) {
                this.carrier = null;
                this.accountNumber = null;
            }
            if (val !== 2) {
                this.migrationType = null;
            }
            this.editCustomerDetail = null;
        },
        visible: function (value) {
            if (value) {
                this.didListLocal = this.didList;
            } else {
               this.reset( );
            }
        },
    },
    props: {
        title: {
            type: String,
            default: 'Remove DIDs',
        },
        didList: {
            default: null,
        },
    },
    computed: {
        taskProgress: function () {
            if (!this.didListLocal.length) {
                return 0;
            }
            return (this.tasksComplete / this.didListLocal.length) * 100;
        },
    },
  created( ){
      this.reset( );
  }

};
</script>

<style scoped></style>
