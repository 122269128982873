<template>
  <div>
    <v-row dense>
      <v-col cols="12" lg="4">
        <WholesalerCustomerClassDetailsCard :customerClassData="customerClassData" :loading="isBusy"/>
      </v-col>
      <v-col cols="12" lg="4">
        <WholesalerCustomerClassFinanceDetailsCard :customerClassData="customerClassData" :loading="isBusy"/>
      </v-col>
      <v-col cols="12" lg="4">
        <WholesalerCustomerClassInvoiceDetailsCard :customerClassData="customerClassData" :loading="isBusy"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WholesalerCustomerClassDetailsCard from "../../components/cards/Wholesalers/WholesalerCustomerClassDetailsCard";
import resellerDataMixin from "../../mixins/resellerDataMixin";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
import WholesalerCustomerClassFinanceDetailsCard
  from "../../components/cards/Wholesalers/WholesalerCustomerClassFinanceDetailsCard";
import WholesalerCustomerClassInvoiceDetailsCard
  from "../../components/cards/Wholesalers/WholesalerCustomerClassInvoicingDetailsCard";
export default {
    name: 'WholesaleManagedCustomerClassPage',
    data: () => ({
      customerClassData:null,
      busyCounter:0,
    }),
    mixins: [resellerDataMixin, refreshListenerMixin],
    components: {
      WholesalerCustomerClassInvoiceDetailsCard,
      WholesalerCustomerClassFinanceDetailsCard,
      WholesalerCustomerClassDetailsCard,
    },
    methods: {
      async loadCustomerClass( ){
        this.busyCounter++;
        const response = await this.Api.send('get', '/customer-classes/'+this.$route.params.customerClassId);
        this.busyCounter--;
        this.customerClassData = null
        if(response.success){
          this.customerClassData = response.data;
        }
        this.buildBreadCrumbs();
      },
      update( ){
        this.loadCustomerClass();
      },
      buildBreadCrumbs( ){
        let breadCrumbs;
        if(this.$store.state.user.isAdmin){
          breadCrumbs = [
            {
              name:"AdminWholesaleManagedCustomerClasses",
              label: "Customer Classes",
              params:{"customerId":this.localIReseller}
            },
            {
              name:"AdminWholesaleManagedCustomerClass",
              label: this.customerClassData.title,
              params:{"customerId":this.localIReseller,"customerClassId":this.customerClassData.i_customer_class}
            }
          ];
        }else{
          breadCrumbs = [
            {
              name:"WholesaleManagedCustomerClasses",
              label: "Customer Classes",
              params:{}
            },
            {
              name:"WholesaleManagedProduct",
              label: this.customerClassData.title,
              params:{"customerClassId":this.customerClassData.i_customer_class}
            }
          ];
        }
        this.$store.commit('data', {name: 'breadCrumbsAddon', data: breadCrumbs});
      }
    },
    created() {
      if(this.resellerData && this.resellerData.i_customer){
        this.loadCustomerClass();
      }
    },
    watch: {
      localIReseller(value) {
       if(value != null){
         this.loadCustomerClass();
       }
      }
    },
    computed:{
      isBusy( ){
        return this.resellerLoading || this.busyCounter > 0;
      },
      localIReseller( ){
        if(this.resellerData && this.resellerData.i_customer){
          return this.resellerData.i_customer;
        }else{
          return null;
        }
      }
    }
};
</script>