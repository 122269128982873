import equalsMixin from './equalsMixin';
export default {
    data: () => ({
        filter: {
            limit: 10,
            page: 1,
        },
        filterDefaults: {},
    }),
    mixins: [equalsMixin],
    beforeRouteUpdate(to, from, next) {
        let filter = this.calculateFilterFromQuery(to.query);
        if (!this.equals(this.filter, filter)) {
            this.filter = filter;
        }
        next();
    },
    created() {
        this.filterDefaults = { ...this.filter };

        /**
         * Here we need to cater for the situation where a query string can be pasted into the URL.
         * If this is the case we do not need to overwrite the query string with the defaults.
         * Hence we run over the query string and if any values are missing from the default we add the default value.
         * EG if the defaults are limit:10 and page:1 and a url is passed with page:2 then this process will add the default value for limit.
         */

        //first we add all the query string values to the filter
        for (const queryItem in this.$route.query) {
            this.filter[queryItem] = this.$route.query[queryItem];
        }

        this.setupQueryStringAndFilter();
    },
    methods: {
        setupQueryStringAndFilter()
        {
            //we update the query string with the filter default.
            this.updateQueryString( );
            this.filter = this.calculateFilterFromQuery(this.$route.query);
        },
        updateFilter(data) {
            this.filter = { ...this.filter, ...data }
        },
        calculateFilterFromQuery(query) {
            //let filter = { ...this.filterDefaults }; // defaulting to this means that if a filter is set to null it will always be overwritten by its default.
            let filter = {};
            for (var item in query) {
                switch(typeof this.filterDefaults[item]) {
                    case "number":
                        if (filter[item] !== Number(query[item]))
                            filter[item] = Number(query[item]);
                        break;
                    case "boolean":
                        if (filter[item] !== Boolean(query[item]))
                            filter[item] = Boolean(query[item]);
                        break;
                    case "object":
                        if (this.filterDefaults[item] === null) {
                            if (query[item] !== null && query[item] !== "")
                                filter[item] = query[item];
                            break;
                        }
                    default:
                        if (String(filter[item]) !== String(query[item]))
                            filter[item] = String(query[item]);
                        break;
                }
            };
            return filter;
        },
        /**
         * Will create a query string based on the filter and push to use on that page.
         * @param force
         *
         * Note there has been a series of update here mainly due to the fact that the logic here does not handle default settings not set to null.
         * To better follow general URL practises the URL should be the source of truth for the filter values.
         * Before if the default filter values included a non null value it was not added to the URL. This caused drama when a filter
         * value we changed to null but its default was not null.
         *
         * In anycase the logic is updated so the URL params are the source of truth.
         */
        updateQueryString(){
            //build the query string
            let query = {};
            for (const item in this.filter) {
                if(this.filter[item] != null) {
                    query[item] = String(this.filter[item]);
                }
            };

            if (!this.equals(query, this.$route.query ? this.$route.query : {})){
                this.$router.push({ query: query});
            }
        }
    },
    watch: {
        filter: {
            handler(val) {
            /*    if (!this.equals(this.query, this.$route.query ? this.$route.query : {})){
                    this.$router.push({ query: this.query });
                }*/
                // console.log('Watch Filter::start');
                // console.log(this.$route.query);
                this.updateQueryString();
            },
            deep: true
        },
        $route (to, from){
            /**
             * if the query string is empty we need to build it again
             */
            if(JSON.stringify(to.query) === JSON.stringify({}) || to.query == null || to.query == {} || to.query == undefined){
                //need to use the initial filter values.
                //reset to localFilters
                /*
                    Note that when clicking the link to the page again the created method does not run.
                    So filters in the query string are not setup and the query string is empty.
                    Here we need to reset the base filter array to its default values which we saved during the create method (save the filterDefaults).
                    Then we rerun the process to build the query string which will use the values in the filter array.

                    NOTE that the filter array here behaves quite differently between this logic and the pagination logic.
                    Here the filter array is manipulated in the paginationmixin the filter is a property and is always set to its defaults.
                 */
                this.filter = this.filterDefaults;
                this.setupQueryStringAndFilter( );
            }
        }
    },
    computed: {
        query: function() {
            let query = {};
            for (const item in this.filter) {
                if (this.filterDefaults[item] !== this.filter[item]) {
                    if(this.filter[item] != null) {
                        query[item] = String(this.filter[item]);
                    }
                }
            };
            return query;
        }
    }
};