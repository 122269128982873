<template>
  <div>
    <WholesalerActiveSessionListCard :customer-data="resellerData" :loading="isBusy"/>
  </div>
</template>

<script>
import resellerDataMixin from "../../mixins/resellerDataMixin";
import WholesalerActiveSessionListCard from "../../components/cards/Wholesalers/WholesalerActiveSessionListCard";
export default {
    name: 'WholesaleManagedActiveSessionListPage',
    data: () => ({

    }),
    mixins: [resellerDataMixin],
    components: {
      WholesalerActiveSessionListCard,
    },
    methods: {

    },
  computed:{
    isBusy( ){
      return !this.resellerLoading && !(this.resellerData && this.resellerData.i_customer)
    },
    iCustomer( ){
      if(this.resellerData && this.resellerData.i_customer){
        return this.resellerData.i_customer;
      }else{
        return null;
      }
    }
  }
};
</script>