<template>
  <BasicCard
      :loading="isBusy"
      :no-skeleton="false"
  ><span v-if="resellerData.gst_inclusive">* Prices are GST inclusive</span>
    <span v-else>* Prices are GST exclusive</span>
    <v-tabs
        v-model="tab"
        fixed-tabs
        show-arrows
        center-active
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#outbound">
        Outbound Rates
      </v-tab>
      <v-tab href="#inbound">
        Inbound Rates
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" v-if="productTariffRatesData != null">
      <v-tab-item :key="1" value="outbound">
          <p class="mb-0"><strong>Standard Rates</strong></p>
          <ManageWholesaleProductTariffRatesListTable typeTag="standard" :productData="productData" :productTariffRateData="productTariffRatesData.outbound.standard" @updateRates="updateRates"/>
        <hr class="mb-10"/>
        <p class="mb-0"><strong>1300 Rate</strong></p>
          <ManageWholesaleProductTariffRatesListTable typeTag="1300"  :productData="productData" :productTariffRateData="productTariffRatesData.outbound['1300']" @updateRates="updateRates"/>
        <hr class="mb-10"/>
          <p class="mb-0"><strong>1800 Rate</strong></p>
          <ManageWholesaleProductTariffRatesListTable typeTag="1800"  :productData="productData" :productTariffRateData="productTariffRatesData.outbound['1800']" @updateRates="updateRates"/>
      </v-tab-item>
      <v-tab-item :key="2" value="inbound">
        <p class="mb-0" v-if="false"><strong>Standard Rates</strong></p>
        <ManageWholesaleProductTariffRatesListTable v-if="false" :productData="productData" :productTariffRateData="productTariffRatesData.inbound.filter(e => e.type_tag == 'standard')[0]" @updateRates="updateRates"/>
        <hr class="mb-10" v-if="false"/>
        <p class="mb-0"><strong>1300 Rate</strong></p>
        <ManageWholesaleProductTariffRatesListTable typeTag="1300"  :productData="productData" :productTariffRateData="productTariffRatesData.inbound['1300']" @updateRates="updateRates"/>
        <hr class="mb-10"/>
        <p class="mb-0"><strong>1800 Rate</strong></p>
        <ManageWholesaleProductTariffRatesListTable typeTag="1800"  :productData="productData" :productTariffRateData="productTariffRatesData.inbound['1800']" @updateRates="updateRates"/>
      </v-tab-item>
    </v-tabs-items>
    </BasicCard>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import ManageWholesaleProductTariffRatesListTable from "../../pieces/Wholesalers/ManageWholesaleProductTariffRatesListTable";
import EditWholesaleProductRatePriceDialog from "../../dialogs/Wholesalers/EditWholesaleProductRatePriceDialog";
import BasicCard from "../templates/BasicCard";
export default {
    name: 'ManagedWholesaleProductTariffTabsCard',
    mixins: [apiMixin],
    data: () => ({
      busy:null,
      tab:'standard',
      showEditDialogToggle:false,
      currentEditDialogRecord:null,
    }),
    components: {
      BasicCard,
      ManageWholesaleProductTariffRatesListTable
    },

    props: {
      resellerData: {
        type: Object,
        default: null,
      },
      productTariffRatesData:{
        type: Object,
        default:null,
      },
      productData:{
        type: Object,
        default:null,
      },
      loading:{
        type: Boolean,
        deafult: false,
      }
    },
  computed:{
    isBusy(){
      return this.busy || this.loading;
    }
  },
    methods: {
      openPriceEditDialig(record){
        this.showEditDialogToggle = true;
        this.currentEditDialogRecord = record;
      },
      updateRates(){
        this.$emit('updateRates');
      }
    },
};
</script>
