<template>
  <BasicCard
      :loading="loading"
      :no-skeleton="false"
  >
    <template v-slot:feature>
      <v-icon right x-large>mdi-account-box</v-icon>
    </template>
    <p v-if="customerClassData != null" class="title text--primary">{{customerClassData.title}}</p>
    <v-row dense v-if="customerClassData != null">

      <v-col cols="4">
        <span class="font-weight-bold">Visible to users</span>
      </v-col>
      <v-col cols="8">
        <v-icon
            v-if="customerClassData.visible_at != null"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>
        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-close</v-icon>
      </v-col>

      <v-col cols="4">
        <span class="font-weight-bold">Available to Partners</span>
      </v-col>
      <v-col cols="8">
        <v-icon
            v-if="customerClassData.partner_enabled_at != null"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>
        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-close</v-icon>
      </v-col>

    </v-row>
    <v-row dense v-if="($store.state.user.isAdmin || $store.state.user.isManagedWholesale) && customerClassData != null">

    </v-row>
    <template v-slot:actions>
      <ButtonWithTooltip
          v-if="customerClassData != null"
          text="Edit"
          flat
          :tooltip="'Edit'+customerClassData.title+' Details'"
          icon="mdi-pencil"
          @click="showEditDialog = true"
      />
    </template>
    <EditWholesaleCustomerClassDetailsDialog v-model="showEditDialog" :customer-class-data="customerClassData"/>
  </BasicCard>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import resellerDataMixin from "../../../mixins/resellerDataMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import EditWholesaleCustomerClassDetailsDialog from "../../dialogs/Wholesalers/EditWholesaleCustomerClassDetailsDialog";
export default {
    name: 'WholesalerCustomerClassDetailsCard',
    mixins: [apiMixin, resellerDataMixin, GlobalHelperMixin],
    data: () => ({
      showEditDialog:false,
      busy: false,
    }),
    components: {
      EditWholesaleCustomerClassDetailsDialog,
      BasicCard,
      ButtonWithTooltip,
    },
    props: {
        customerClassData:{
          type: Object,
          default: null,
        },
      loading:{
          type: Boolean,
          default:false,
      }
    },
  computed:{

  },
    methods: {

    },
};
</script>