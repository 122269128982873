<template>
  <BasicCard
      :loading="loading"
      :no-skeleton="false"
  >
    <template v-slot:feature>
      <v-chip v-if="productData != null && productData.admin_blocked_at != null" class="warning ml-2">Disabled by Admin</v-chip>
      <v-icon right x-large>mdi-package-variant-closed</v-icon>
    </template>
    <p class="title text--primary">{{productData.end_user_name}}</p>
    <v-row dense>

      <v-col cols="8">
        <span class="font-weight-bold">Visible to users</span>
      </v-col>
      <v-col cols="4">
        <v-icon
            v-if="productData.visible_at != null"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>
        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-close</v-icon>
      </v-col>

      <v-col cols="8">
        <span class="font-weight-bold">Available to Partners</span>
      </v-col>
      <v-col cols="4">
        <v-icon
            v-if="productData.partner_enabled_at != null"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>
        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-close</v-icon>
      </v-col>

      <v-col cols="8">
        <span class="font-weight-bold">Count Addon Products</span>
      </v-col>
      <v-col cols="4">
        {{productData.addons.length}}
      </v-col>

    </v-row>
    <template v-slot:actions>
      <ButtonWithTooltip
          text="View"
          flat
          :tooltip="'View '+productData.end_user_name+' Details'"
          icon="mdi-eye"
          @click="openProduct"
      />
    </template>
  </BasicCard>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import resellerDataMixin from "../../../mixins/resellerDataMixin";
import IconWithTooltip from "../../pieces/IconWithTooltip";
export default {
    name: 'WholesalerProductListCard',
    mixins: [apiMixin, resellerDataMixin],
    data: () => ({

    }),
    components: {
      BasicCard,
      ButtonWithTooltip,
    },
    props: {
        productData:{
          type: Object,
          default: null,
        },
      loading:{
          type: Boolean,
          default:false,
      }
    },
  computed:{

  },
    methods: {
      openProduct( ){
        if(this.$store.state.user.isAdmin){
          this.$router.push({
            name: 'AdminWholesaleManagedProduct',
            params: {productId: this.productData.i_product,customerId:this.resellerId}
          });
        }else {
          this.$router.push({
            name: 'WholesaleManagedProduct',
            params: {productId: this.productData.i_product}
          });
        }
      },
    },
};
</script>