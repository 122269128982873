<template>
  <div>
    <v-card class="pa-2 mb-2">
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
              hide-details
              outlined
              clearable
              dense
              label="Called From Number (CLI)"
              :disabled="isBusy"
              v-model="searchFilters.cli"
              @keyup.enter="updateSearch"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              hide-details
              outlined
              clearable
              dense
              label="Called To Number (CLD)"
              :disabled="isBusy"
              v-model="searchFilters.cld"
              @keyup.enter="updateSearch"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
              hide-details
              outlined
              clearable
              dense
              label="Account"
              :disabled="isBusy"
              v-model="searchFilters.account_id"
              @keyup.enter="updateSearch"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
              hide-details
              outlined
              clearable
              dense
              label="Customer ID"
              :disabled="isBusy"
              v-model="searchFilters.i_customer"
              @keyup.enter="updateSearch"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn
              outlined
              block
              height="40"
              :loading="isBusy"
              @click="updateSearch"
          >Search</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <PaginationCardV2
        :page="searchFilters.page"
        :totalRecords="listData.count"
        :totalPages="listData.pages"
        :limit="searchFilters.limit"
        :currentPageTotal="(listData.results) ? listData.results.length : 0"
        :loading="isBusy"
        @updatedPagination="updatedPagination"
        :no-skeleton="false"
        :updateOnCreate="false"
    >
      <v-simple-table v-if="!isBusy">
        <thead>
        <tr>
          <th class="text-center">CLI</th>
          <th class="text-center">CLD</th>
          <th class="text-center">Start Time</th>
          <th class="text-center">Duration (hh:mm:ss)</th>
          <th class="text-center">Country</th>
          <th class="text-center">Area</th>
          <th class="text-center">Customer</th>
          <th class="text-center">Account</th>
          <th class="text-center">Vendor</th>
          <th class="text-center">Connection</th>
          <th class="text-center"></th>
        </tr>
        </thead>
        <v-slide-x-transition tag="tbody" hide-on-leave group>
          <tr v-if="listData.results && listData.results.length === 0" key="nodata">
            <td colspan="100%" class="text-center">
              No results to show.
            </td>
          </tr>
          <tr
              v-for="item in listData.results"
              :key="item.i_account"
          >
            <td class="text-center">
              {{item.cli}}
            </td>
            <td class="text-center">
              {{item.cld}}
            </td>
            <td class="text-center">
              {{item.setup_time_formatted}}
            </td>
            <td class="text-center">
              {{item.duration_formatted}}
            </td>
            <td class="text-center">
              {{item.country}}
            </td>
            <td class="text-center">
              {{item.subdivision}}
            </td>
            <td class="text-center">
              <router-link
                  :to="{
                      name: 'CustomerSummary',
                      params: {
                          customerId: item.i_customer,
                      },
                  }"
                  class="font-weight-medium"
              >{{item.customer_name}}</router-link>
            </td>
            <td class="text-center">
              <router-link
                  :to="{
                      name: 'TrunkSummary',
                      params: {
                          trunkId: item.i_account,
                      },
                  }"
                  class="font-weight-medium"
              >{{item.account_id}}</router-link>
            </td>
            <td class="text-center">
              {{item.vendor_name}}
            </td>
            <td class="text-center">
              {{item.connection_name}}
            </td>
            <td class="text-center">
              <ButtonWithTooltip
                  icon="mdi-phone-hangup"
                  text="Terminate"
                  tooltip="Terminate this call/session"
                  @click="openTerminateDialog(item)"
              />
            </td>
          </tr>
        </v-slide-x-transition>
      </v-simple-table>
    </PaginationCardV2>
    <SimpleDialog
        v-model="showTerminateDialog"
        title="Terminate Active Call"
        yesText="Confirm Termination"
        noText="Cancel"
        @click="confirmTermination"
        styled-header
        errorHeader
        yesColor="error"
        :yesDisabled="isBusy"
    >
      <div v-if="terminateingRecord != null">
        <p>Are you sure you wish to terminate this call?</p>
        <p>CLI: {{terminateingRecord.cli}}<br />
          CLD: {{terminateingRecord.cld}}<br />
          Customer: {{terminateingRecord.customer_name}}<br />
          Start Time: {{terminateingRecord.setup_time_formatted}}<br />
          Duration (hh:mm:ss): {{terminateingRecord.duration_formatted}}<br />
        </p>
      </div>

    </SimpleDialog>
  </div>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import PaginationCardV2 from "../templates/PaginationCardV2";
import SimpleDialog from "../../dialogs/templates/SimpleDialog";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'WholesalerActiveSessionListCard',
    mixins: [apiMixin, GlobalHelperMixin],
    data: () => ({
      busy:false,
      showTerminateDialog:false,
      terminateingRecord:null,
      searchFilters:{
        page:1,
        limit:20,
        cld:null,
        cli:null,
        account_id:null,
        i_customer:null,
      },
      listData:{
        page:1,
        limit:20,
        results:[],
        total:0
      }
    }),
    components: {
      SimpleDialog,
      ButtonWithTooltip,
      PaginationCardV2
    },
    props: {
      customerData:{
        type: Object,
        default: null,
      },
      loading:{
          type: Boolean,
          default:false,
      }
    },
    computed:{
      isBusy( ){
        return this.loading || this.busy;
      },
    },
    methods: {
      openTerminateDialog(item){
        this.terminateingRecord = item;
        this.showTerminateDialog = true;
      },
      async confirmTermination( ){
        this.busy = true;
        let response = await this.Api.send('put','wholesalers/'+this.customerData.i_customer+'/active-sessions/'+this.terminateingRecord.h323_conf_id);
        this.busy = false;
        this.showTerminateDialog = false;
        this.terminateingRecord = null;
        if(response.success){
          this.pullActiveSessions( );
          this.showGlobalSuccessMessage('Call terminated successfull');
        }else{
          this.showGlobalErrorMessage('Failed to terminat');
        }
      },
      updateSearch( ){
        this.searchFilters.page = 1;
        this.pullActiveSessions();
      },
      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        this.pullActiveSessions();
      },
      async pullActiveSessions( ){
        let params = {
          page:this.searchFilters.page,
          limit:this.searchFilters.limit,
        }
        const keys = [
            'cld','cli','account_id','i_customer'
        ];

        let self = this;
        keys.forEach(function (key) {
          if(self.searchFilters[key] != null){
            params[key] = self.searchFilters[key];
          }
        });

        let queryString = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');

        this.listData = {
          page:1,
          limit:20,
          results:[],
          total:0
        };
        this.busy = true;
        let response = await this.Api.send('get','wholesalers/'+this.customerData.i_customer+'/active-sessions?'+queryString);
        this.busy = false;
        if(response.success){
          this.listData = response.data;
        }
      },
    },
  watch: {
    customerData: function (val) {
      if(val != null){
        this.pullActiveSessions( );
      }
    },
  },
};
</script>