var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleDialogOld',{attrs:{"loading":_vm.busy,"visible":_vm.visible,"yes-text":"Save","yes-enabled":!_vm.busy && _vm.dirty && _vm.formValid,"no-text":"Cancel","title":"Change Credit Limit","persistent":""},on:{"close":_vm.close,"click":_vm.update}},[_c('v-form',{model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-expand-transition',[(parseFloat(_vm.newCreditLimit) > 5000)?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v("Credit limit seems high! Please consider carefully before saving.")]):_vm._e()],1),_c('v-expand-transition',[(
                    parseFloat(_vm.newCreditLimit) > 0 &&
                    _vm.data.balance > parseFloat(_vm.newCreditLimit)
                )?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v("The customer's current balance exceeds $"+_vm._s(_vm.newCreditLimit)+"!")]):_vm._e()],1),_c('v-row',{staticClass:"mt-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Credit Limit","outlined":"","autofocus":"","prefix":"$","rules":_vm.rules.number,"disabled":_vm.busy || _vm.temporaryIncrease},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.newCreditLimit),callback:function ($$v) {_vm.newCreditLimit=$$v},expression:"newCreditLimit"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-switch',{class:'pl-4' +
                        (_vm.$vuetify.breakpoint.smAndUp
                            ? ' mt-3'
                            : ' mt-0 mb-2'),attrs:{"label":"Temporary increase"},model:{value:(_vm.temporaryIncrease),callback:function ($$v) {_vm.temporaryIncrease=$$v},expression:"temporaryIncrease"}})],1)],1),_c('v-expand-transition',[(_vm.temporaryIncrease)?_c('v-sheet',{staticClass:"pa-4",attrs:{"color":'blue' +
                    (_vm.$vuetify.theme.isDark ? ' darken-4' : ' lighten-4'),"rounded":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Limit Increase","outlined":"","autofocus":_vm.dirty,"prefix":"$","hint":"Amount to increase limit by","hide-details":_vm.formValid,"rules":_vm.rules.temporaryLimit,"error-messages":parseFloat(_vm.newTempCreditLimit) >
                                parseFloat(_vm.newCreditLimit)
                                    ? 'Cannot be more than credit limit'
                                    : null,"disabled":_vm.busy},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.newTempCreditLimit),callback:function ($$v) {_vm.newTempCreditLimit=$$v},expression:"newTempCreditLimit"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('DatePickerV2',{attrs:{"label":"Valid until","hint":"Date increase expires","hide-details":_vm.formValid,"required":"","future-only":"","clearable":"","min":_vm.dateMin,"max":_vm.dateMax,"initialValue":_vm.temporaryIncreaseExpiryOriginal},model:{value:(_vm.temporaryIncreaseExpiry),callback:function ($$v) {_vm.temporaryIncreaseExpiry=$$v},expression:"temporaryIncreaseExpiry"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"body-1"},[_vm._v("$"+_vm._s(parseFloat(_vm.newCreditLimit) ? parseFloat(_vm.newCreditLimit) : 0)+" "),_c('span',{staticClass:"caption"},[_vm._v("(credit limit)")]),_vm._v(" + $"+_vm._s(parseFloat(_vm.newTempCreditLimit) ? parseFloat(_vm.newTempCreditLimit) : 0)+" "),_c('span',{staticClass:"caption"},[_vm._v("(temp. increase)")]),_vm._v(" = "),_c('b',[_vm._v("$"+_vm._s(parseFloat(_vm.newCreditLimit) + parseFloat(_vm.newTempCreditLimit) ? parseFloat(_vm.newCreditLimit) + parseFloat(_vm.newTempCreditLimit) : 0))])])])],1)],1):_vm._e()],1),_c('v-expand-transition',[(_vm.error)?_c('v-alert',{staticClass:"mt-4",attrs:{"type":"warning"}},[_vm._v("Error: "+_vm._s(_vm.error))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }