<template>
    <v-row dense>
      <CustomerListCardV2 v-if="iCustomer" :show-reseller-names="true" :reseller-id="iCustomer" add-button @addButtonTriggered="showNewCustomerAccountType = true"/>
      <NewCustomerAccountTypeDialog type="wholesale-managed-customer" v-if="iCustomer" :resellerId="iCustomer" v-model="showNewCustomerAccountType" @type-selected="function(classData){customerClassData=classData; showNewCustomer=true;}" />
      <NewCustomerDialog v-if="resellerData" type="wholesale-managed-customer" v-model="showNewCustomer" :resellerId="iCustomer" :customer-class-data="customerClassData" @customer="goToCustomer" @newCustomerCreated="emitUpdate"/>
      <FloatingButton tooltip="New Customer" icon="mdi-account-plus" @click="showNewCustomerAccountType = true"/>
    </v-row>
</template>

<script>
import FloatingButton from '../../components/pieces/FloatingButton';
import NewCustomerDialog from '../../components/dialogs/Customer/NewCustomerDialog';
import NewCustomerAccountTypeDialog from "../../components/dialogs/Customer/NewCustomerAccountTypeDialog";
import CustomerListCardV2 from "../../components/cards/Customer/CustomersListCardV2";
import resellerDataMixin from "../../mixins/resellerDataMixin";
import dialogMixin from "../../mixins/dialogMixin";
import apiMixin from "../../mixins/apiMixin";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
export default {
    name: 'WholesaleCustomerList',
    mixins: [resellerDataMixin],
    components: {
      CustomerListCardV2,
      NewCustomerAccountTypeDialog,
      NewCustomerDialog,
      FloatingButton,
    },
    data: () => ({
      showNewCustomerAccountType:false,
      showNewCustomer:false,
      customerClassData:{}, //holds selected customer class data we pass to the create process
    }),
    methods: {
      goToCustomer(id) {
        this.showNewCustomer = false;
        this.showNewCustomerAccountType = false;
        this.$router.push({ name: 'CustomerSummary', params: { customerId: id }});
      },
      emitUpdate( ){
        document.dispatchEvent(new CustomEvent('refreshRequested'));
      }
    },
    beforeRouteLeave(to, from, next){
        return (this.showNewCustomer) ? next(false) : next();
    },
    computed:{
      isBusy( ){
        return !this.partnerLoading && !(this.partnerData && this.partnerData.i_rep)
      },
      iCustomer( ){
        if(this.resellerData && this.resellerData.i_customer){
          return this.resellerData.i_customer;
        }else{
          return null;
        }
      }
    }
};
</script>
