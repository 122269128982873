<template>
  <BasicCard
      :loading="loading"
      :no-skeleton="false"
  >
    <template v-slot:feature>
      <v-chip v-if="customerClass != null && customerClass.admin_blocked_at != null" class="warning ml-2">Disabled by Admin</v-chip>
      <v-icon right x-large>mdi-account-box</v-icon>
    </template>
    <p class="title text--primary">{{customerClass.title}}</p>
    <v-row dense>

      <v-col cols="8">
        <span class="font-weight-bold">Visible to users</span>
      </v-col>
      <v-col cols="4">
        <v-icon
            v-if="customerClass.visible_at != null"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>
        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-close</v-icon>
      </v-col>

      <v-col cols="8">
        <span class="font-weight-bold">Available to Partners</span>
      </v-col>
      <v-col cols="4">
        <v-icon
            v-if="customerClass.partner_enabled_at != null"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>
        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-close</v-icon>
      </v-col>

    </v-row>
    <template v-slot:actions>
      <ButtonWithTooltip
          text="View"
          flat
          :tooltip="'View '+customerClass.title+' Details'"
          icon="mdi-eye"
          @click="openCustomerClass"
      />
    </template>
  </BasicCard>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import resellerDataMixin from "../../../mixins/resellerDataMixin";
import IconWithTooltip from "../../pieces/IconWithTooltip";
export default {
    name: 'WholesalerCustomerClassListCard',
    mixins: [apiMixin, resellerDataMixin],
    data: () => ({

    }),
    components: {
      BasicCard,
      ButtonWithTooltip,
    },
    props: {
        customerClass:{
          type: Object,
          default: null,
        },
      loading:{
          type: Boolean,
          default:false,
      }
    },
  computed:{

  },
    methods: {
      openCustomerClass( ){
        if(this.$store.state.user.isAdmin){
          this.$router.push({
            name: 'AdminWholesaleManagedCustomerClass',
            params: {customerClassId: this.customerClass.i_customer_class,customerId:this.resellerId}
          });
        }else {
          this.$router.push({
            name: 'WholesaleManagedCustomerClass',
            params: {customerClassId: this.customerClass.i_customer_class}
          });
        }
      },
    },
};
</script>