<template>
  <v-form v-model="valid" v-on:submit.prevent="">
    <v-text-field
        outlined
        v-model="dealercode"
        label="Dealer Code *"
        :error-messages="(!!dealercode && currentQuery===dealercode && !valid) ? validErrorMessage : null"
        :rules="rules"
        hint="Leader dealer code reference"
        :counter="41"
    >
      <template v-slot:append>
        <div v-if="!!dealercode && !loading && dealercode===currentQuery">
          <IconWithTooltip
              v-if="valid"
              tooltip="Dealer code is unique"
              icon="mdi-check"
              color="success"
          />
          <IconWithTooltip
              v-if="!valid"
              tooltip="Dealer code already in use"
              icon="mdi-alert"
              color="error"
          />
        </div>
        <v-progress-circular
            v-if="loading"
            :width="3"
            :size="24"
            color="primary"
            indeterminate
        />
      </template>
    </v-text-field>
  </v-form>
</template>

<script>
//const IDRegex = /^[A-Z0-9][\w\d \-\,_\.&\']*[\w\d]$/;
import apiMixin from '../../../mixins/apiMixin';
import IconWithTooltip from '../IconWithTooltip';
export default {
  name: 'DealerCodeField',
  components: { IconWithTooltip },
  mixins: [apiMixin],
  data: () => ({
    dealercode: '',
    valid: false,
    validErrorMessage: null,
    timer: null,
    loading: false,
    currentQuery: '',
    rules: [
      v => !!v || "Dealer code is required",
      //v => IDRegex.test(v) || "Username is not valid"
    ]
  }),
  methods: {
    async validate(dealercode) {
      this.loading = true;
      this.currentQuery = dealercode;
      const response = await this.Api.send('post','users/validate-dealercode', {dealercode: dealercode}, true);
      if (response.success) {
        if (dealercode===this.dealercode) this.$emit('input', dealercode);
        this.valid = true;
        this.validErrorMessage = null;
      } else {
        if (this.value) this.$emit('input', null);
        this.validErrorMessage = response.data.description;
        this.valid = false;
      }
      this.loading = false;
    },
  },
  watch: {
    dealercode(val) {
      clearTimeout(this.timer);
      this.$emit('input', null);
      // if (val && !IDRegex.test(val)) {
      //     /*val = val.replaceAll('  ', ' ');
      //     val = val.trimStart().charAt(0).toUpperCase() + val.trimStart().slice(1);*/
      //     this.id = val;
      //     this.currentQuery = '';
      //     return;
      // }
      if (val){ //&& IDRegex.test(val)) {
        this.timer = setTimeout(() => {
          if (val !== this.currentQuery && val != this.currentValue) {
            this.validate(val);
          }
        }, 500);
      } else {
        this.valid = false;
        this.currentQuery = '';
      }
    },
    value(value) {
      if (value && this.dealercode !==value) this.dealercode = value;
    }
  },
  props: {
    value: {
      type: String,
      default: null
    },
    currentValue:{
      type:String,
      default:null
    }
  },
  created() {
    if (this.value) this.dealercode = this.value;
  }
};
</script>

<style scoped>

</style>