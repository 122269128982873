<template>
    <SimpleDialogOld
        :loading="busy"
        :visible="visible"
        @close="close"
        yes-text="Save"
        @click="update"
        :yes-enabled="!busy && dirty && formValid"
        no-text="Cancel"
        title="Change Credit Limit"
        persistent
    >
        <v-form v-model="formValid">
            <v-expand-transition>
                <v-alert type="warning" v-if="parseFloat(newCreditLimit) > 5000">Credit limit seems high! Please consider carefully before saving.</v-alert>
            </v-expand-transition>
            <v-expand-transition>
                <v-alert
                    v-if="
                        parseFloat(newCreditLimit) > 0 &&
                        data.balance > parseFloat(newCreditLimit)
                    " type="warning">The customer's current balance exceeds ${{
                        newCreditLimit
                    }}!</v-alert>
            </v-expand-transition>
            <v-row dense class="mt-2">
                <v-col cols="12" sm="6">
                    <v-text-field
                        label="Credit Limit"
                        v-model="newCreditLimit"
                        outlined
                        autofocus
                        prefix="$"
                        @keydown.enter.native.prevent
                        :rules="rules.number"
                        :disabled="busy || temporaryIncrease"
                    />
                </v-col>
                <v-col cols="12" sm="6">
                    <v-switch
                        label="Temporary increase"
                        v-model="temporaryIncrease"
                        :class="
                            'pl-4' +
                            ($vuetify.breakpoint.smAndUp
                                ? ' mt-3'
                                : ' mt-0 mb-2')
                        "
                    />
                </v-col>
            </v-row>
            <v-expand-transition>
                <v-sheet
                    v-if="temporaryIncrease"
                    :color="
                        'blue' +
                        ($vuetify.theme.isDark ? ' darken-4' : ' lighten-4')
                    "
                    class="pa-4"
                    rounded
                >
                    <v-row dense>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                label="Limit Increase"
                                v-model="newTempCreditLimit"
                                outlined
                                :autofocus="dirty"
                                @keydown.enter.native.prevent
                                prefix="$"
                                hint="Amount to increase limit by"
                                :hide-details="formValid"
                                :rules="rules.temporaryLimit"
                                :error-messages="
                                    parseFloat(newTempCreditLimit) >
                                    parseFloat(newCreditLimit)
                                        ? 'Cannot be more than credit limit'
                                        : null
                                "
                                :disabled="busy"
                            />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <DatePickerV2
                              label="Valid until"
                              hint="Date increase expires"
                              :hide-details="formValid"
                              v-model="temporaryIncreaseExpiry"
                              required
                              future-only
                              clearable
                              :min="dateMin"
                              :max="dateMax"
                              :initialValue="temporaryIncreaseExpiryOriginal"
                            />

                        </v-col>
                        <v-col cols="12">
                            <span class="body-1"
                                >${{
                                    parseFloat(newCreditLimit)
                                        ? parseFloat(newCreditLimit)
                                        : 0
                                }}
                                <span class="caption">(credit limit)</span> +
                                ${{
                                    parseFloat(newTempCreditLimit)
                                        ? parseFloat(newTempCreditLimit)
                                        : 0
                                }}
                                <span class="caption">(temp. increase)</span> =
                                <b
                                    >${{
                                        parseFloat(newCreditLimit) +
                                        parseFloat(newTempCreditLimit)
                                            ? parseFloat(newCreditLimit) +
                                              parseFloat(newTempCreditLimit)
                                            : 0
                                    }}</b
                                ></span
                            >
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-expand-transition>
            <v-expand-transition>
                <v-alert v-if="error" class="mt-4" type="warning"
                    >Error: {{ error }}</v-alert
                >
            </v-expand-transition>
        </v-form>
    </SimpleDialogOld>
</template>

<script>
import SimpleDialogOld from '../templates/SimpleDialogOld';
import DatePicker from '../../pieces/Forms/DatePicker';
import apiMixin from '../../../mixins/apiMixin';
import DatePickerV2 from "../../pieces/Forms/DatePickerV2";
export default {
    name: 'CustomerCreditLimitDialog',
    mixins: [apiMixin],
    components: {DatePickerV2, SimpleDialogOld },
    data() {
        return {
            busy: false,
            display: true,
            newCreditLimit: null,
            temporaryIncrease: false,
            newTempCreditLimit: null,
            temporaryIncreaseExpiry: null,
            temporaryIncreaseExpiryOriginal: null,
            formValid: false,
            Api: [],
            rules: {
                number: [
                    (v) => !!v || 'Required',
                    (v) =>
                        /^\d+(?:\.\d{1,2})?$/i.test(v) ||
                        'Enter a valid number',
                    (v) => parseFloat(v) !== 0 || 'Cannot be zero',
                ],
                temporaryLimit:[
                  (v) => !!v || 'Required',
                  (v) =>
                      /^\d+(?:\.\d{1,2})?$/i.test(v) ||
                      'Enter a valid number',
                  (v) => parseFloat(v) !== 0 || 'Cannot be zero',
                  (v) => v>0 || 'Value must be positive.',
                  (v)=> v<101 || 'Value must be between 1 and 100.',
                ]
            },
            error: null,
        };
    },
    props: {
        data: {},
        visible: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        visible: function (val) {
            if (val) {
                this.newCreditLimit = this.data.perm_credit_limit;
                this.temporaryIncrease = !!this.data.temp_credit_limit;
                this.newTempCreditLimit = this.data.temp_credit_limit
                    ? this.data.temp_credit_limit
                    : null;
                if (this.data.credit_limit_until) {
                    var date = new Date(this.data.credit_limit_until + 'Z');

                    this.temporaryIncreaseExpiry = new Date(
                        date.getTime() - date.getTimezoneOffset() * 60000
                    )
                        .toISOString()
                        .split('T')[0];
                    this.temporaryIncreaseExpiryOriginal = new Date(
                        date.getTime() - date.getTimezoneOffset() * 60000
                    )
                        .toISOString()
                        .split('T')[0];
                }
            } else {
                this.temporaryIncrease = false;
            }
        },
        temporaryIncrease: function (val) {
            if (val) {
                this.newCreditLimit = this.data.perm_credit_limit;
            } else {
                this.newTempCreditLimit = null;
                this.temporaryIncreaseExpiry = null;
            }
        },
    },
    computed: {
        dirty: function () {
            if (this.newCreditLimit != this.data.perm_credit_limit) return true;
            if (this.temporaryIncrease !== !!this.data.temp_credit_limit) return true;
            if (this.newTempCreditLimit != this.data.temp_credit_limit) return true;

            if (this.temporaryIncrease &&
                this.temporaryIncreaseExpiry != null &&
                this.temporaryIncreaseExpiryOriginal !==
                this.temporaryIncreaseExpiry
            )
                return true;
            return false;
        },
      dateMin: function( ){
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return  yyyy + '-' + mm + '-' + dd + '00:00:00';
      },
      dateMax: function(){
        var today = new Date(); // Now
        today.setDate(today.getDate() + 30);
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        return  yyyy + '-' + mm + '-' + dd + '00:00:00';
      }

    },
    methods: {
        async update() {
            this.busy = true;
            let data = this.temporaryIncrease ? { temp_credit_limit: parseFloat(this.newTempCreditLimit), credit_limit_until: this.temporaryIncreaseExpiry } : { perm_credit_limit: parseFloat(this.newCreditLimit) };
            const response = await this.Api.send('post',
                'customers/' + this.data.i_customer + '/update',
                data
            );
            if (response.success) {
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                this.busy = false;
                this.$emit('close', true);
                document.dispatchEvent(new CustomEvent('refreshRequested'));
            } else {
                this.error = response.data.description;
                this.busy = false;
            }
        },
        close() {
            this.confirmDeletion = '';
            this.error = null;
            this.$emit('close');
        },
    },
};
</script>
