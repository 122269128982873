<template>
    <v-tooltip :disabled="tooltip==null" :small="small" bottom open-delay="200"
      v-model="show"
    >
        <template v-slot:activator="{ }">
          <span @mouseover="show = true"
                @mouseleave="mouseout( )">
            <v-btn
                :icon="!text"
                :small="small"
                :color="color"
                :text="flat"
                :href="href"
                :block="block"
                :class="paddingRight ? 'mr-2' : ''"
                :to="to"
                :disabled="disabled"
                exact
                @click="click( )"
            >

              <v-icon
                v-if="icon && !iconRight && !showingDisabled"
                :left="!!text"
                :small="small"
              >{{ icon }}</v-icon><v-icon
              v-if="iconDisabled && !iconRight && showingDisabled"
              :left="!!text"
              :small="small"
              :color="'#c50000'"
            >{{ iconDisabled }}</v-icon>{{text}}<v-icon
              v-if="icon && iconRight && !showingDisabled"
              right="right"
              :small="small"
            >{{ icon }}</v-icon
            ><v-icon
              v-if="iconDisabled && iconRight && showingDisabled"
              right="right"
              :small="small"
              :color="'#c50000'"
            >{{ iconDisabled }}</v-icon></v-btn>
          </span>
        </template>
        <span v-if="!showingDisabled && !disabled">{{ tooltip }}</span>
        <span v-if="showingDisabled || disabled" style="text-align: center;">{{ tooltip }}<br /><span style="color:#f5a5a5;">{{tooltipDisabled}}</span></span>
    </v-tooltip>
</template>

<script>
export default {
    name: 'ButtonDisabledWithTooltip',
    data () {
      return {
        show: false,
        showingDisabled:false,
      }
    },
    methods: {
      click( ){
        this.showingDisabled=true;
      },
      mouseout( ){
        this.show = false;
        this.showingDisabled = false;
      }
    },
    props: {
        icon: {
            type: String,
            default: null,
        },
        iconDisabled: {
          type: String,
          default: 'mdi-cancel',
        },
        text: {
            type: String,
            default: null,
        },
        href: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: '',
        },
        tooltip: {
            type: String,
            default: null,
        },
        tooltipDisabled: {
          type: String,
          default: 'Disabled',
        },
        to: {
            default: null,
        },
        small: {
            type: Boolean,
            default: false,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        iconRight: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        paddingRight: {
            type: Boolean,
            default: false,
        },
        hrefNewWindow: {
            type: Boolean,
            default: false,
        },
    },

};
</script>
