<template>
    <v-form v-model="formValid" ref="form">
        <DIDListImport
            :visible="showImport"
            @close="showImport = false"
            @import="importDIDs"
        />
        <div class="float-right">
            <ButtonWithTooltip
                text="Import list"
                icon="mdi-playlist-plus"
                tooltip="Import a list of numbers via copy/paste"
                @click="showImport = true"
                flat
            />
        </div>
        <v-row dense>
            <v-col cols="12">
                <p class="subtitle-1 mb-0 text--primary">{{ message }}</p>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-alert v-if="imported > 0" class="mt-4" type="info" outlined
                    >{{ imported }} number<span v-if="imported > 1">s</span>
                    added to the list. Please review details to ensure correct
                    before proceeding.</v-alert
                >
                <!-- v-toolbar dense fluid v-if="true" :color="$vuetify.theme.dark ? 'blue-grey' : 'blue lighten-3'" flat >
                    <v-btn icon @click="changeSelectAll(false)">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-toolbar-title> DID<span v-if="1>1">s</span> selected</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="mr-4" disabled>
                        <v-icon>mdi-transfer</v-icon> Transfer
                    </v-btn>
                    <v-btn color="error" disabled>
                        <v-icon>mdi-delete</v-icon> Remove
                    </v-btn>

                </v-toolbar -->
                <v-simple-table dense>
                    <thead>
                        <tr>
                            <th>
                                <v-checkbox
                                    v-model="selectAll"
                                    @change="changeSelectAll"
                                    :indeterminate="indeterminateSelectAll"
                                />
                            </th>
                            <th>
                                <span v-if="!selectAll"> Phone Number </span>
                            </th>
                            <th v-if="showAccountNumber">
                                <span v-if="!selectAll">{{
                                    accountNumberTitle
                                }}</span>
                            </th>
                            <th v-if="porting">
                                <span v-if="!selectAll">Port Type</span>
                                <v-select
                                    v-if="selectAll"
                                    :items="portTypes"
                                    item-value="value"
                                    item-text="text"
                                    hide-details
                                    outlined
                                    dense
                                    @change="bulkUpdatePortType"
                                />
                            </th>
                            <th v-if="showVendor">
                                <span v-if="!selectAll">Vendor</span>
                                <v-select
                                    v-if="selectAll"
                                    :items="vendors"
                                    item-value="value"
                                    item-text="text"
                                    hide-details
                                    outlined
                                    dense
                                    @change="bulkUpdateVendor"
                                    style="max-width: 240px"
                                    hint="Vendor"
                                />
                            </th>
                            <th v-if="showCarrierStatus" class="text-center">
                                Carrier Status
                            </th>

                            <th v-if="showDescription">
                                <span v-if="!selectAll">Description</span>
                                <v-text-field
                                    v-if="selectAll"
                                    @input="bulkUpdateDescription"
                                    hide-details
                                    outlined
                                    dense
                                />
                            </th>
                            <th class="text-center">
                                <span v-if="!selectAll">Delete</span>
                                <ButtonWithTooltip
                                    v-if="selectAll"
                                    icon="mdi-delete"
                                    @click="bulkDelete"
                                    color="error"
                                    tooltip="Delete selected number(s) from the list"
                                    flat
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="didInputList.length === 0">
                            <td colspan="7" class="text-center">
                                There are no numbers in the list.
                            </td>
                        </tr>
                        <tr
                            v-for="(did, index) in didInputList"
                            v-bind:key="index"
                            dense
                        >
                            <td class="text-center py-0">
                                <v-checkbox
                                    v-model="did.selected"
                                    @change="updateSelected"
                                />
                            </td>
                            <td class="text-center py-0">
                                <v-text-field
                                    v-model="did.number"
                                    @input="validateEntry(index)"
                                    required
                                    outlined
                                    dense
                                    hide-details
                                    :rules="[
                                        (v) =>
                                            /^(?:(?:61|0)[2,3,4,7,8]\d{6}(?:\d{2}|\d0-9|00-99)|(?:61)?(?:1300|1800)\d{6}|(?:64)[1,3-9]\d{7})$/gm.test(
                                                v
                                            ) || 'Invalid number entered',
                                    ]"
                                    :error="did.validated && !did.valid"
                                    style="max-width: 320px; min-width: 260px"
                                >
                                    <template v-slot:append>
                                        <v-chip
                                            label
                                            small
                                            v-if="
                                                did.quantity > 1 &&
                                                did.number &&
                                                did.valid &&
                                                (did.validated ||
                                                    !validateNumbers)
                                            "
                                            class="mr-2 success"
                                            >{{ did.quantity }} DIDs</v-chip
                                        >
                                        <icon-with-tooltip
                                            v-if="
                                                did.number &&
                                                did.valid &&
                                                did.validated
                                            "
                                            tooltip="The number/range appears to be valid"
                                            icon="mdi-check"
                                            color="success"
                                        />
                                        <icon-with-tooltip
                                            v-if="
                                                did.number &&
                                                !did.valid &&
                                                !did.busy &&
                                                did.validated
                                            "
                                            tooltip="Already exists in DID inventory!"
                                            icon="mdi-alert"
                                            color="error"
                                        />
                                        <v-progress-circular
                                            v-if="did.busy && !did.validated"
                                            :width="3"
                                            :size="24"
                                            color="primary"
                                            indeterminate
                                        ></v-progress-circular>
                                    </template>
                                </v-text-field>
                            </td>
                            <td
                                v-if="showAccountNumber"
                                class="text-center py-0"
                            >
                                <v-text-field
                                    v-model="did.account_number"
                                    required
                                    hide-details
                                    outlined
                                    dense
                                    style="max-width: 160px; min-width: 160px"
                                />
                            </td>
                            <td v-if="porting" class="text-center py-0">
                                <v-select
                                    v-model="did.port_type"
                                    :items="portTypes"
                                    item-value="value"
                                    item-text="text"
                                    :required="porting"
                                    :rules="[(v) => !!v]"
                                    hide-details
                                    outlined
                                    dense
                                />
                            </td>
                            <td v-if="showVendor" class="text-center py-0">
                                <v-select
                                    v-model="did.i_vendor"
                                    @change="carrierStatus(index) && update()"
                                    :items="vendors"
                                    item-value="value"
                                    item-text="text"
                                    :required="showVendor"
                                    :rules="[(v) => !!v]"
                                    hide-details
                                    outlined
                                    dense
                                    style="max-width: 240px; min-width: 120px"
                                />
                            </td>
                            <td
                                v-if="showCarrierStatus"
                                class="text-center py-0"
                            >
                                <DIDCarrierStatus
                                    :status="did.carrier_status"
                                    v-if="
                                        did.i_vendor === 6 &&
                                        !did.busy &&
                                        did.valid
                                    "
                                />
                                <v-chip v-if="!did.valid || did.i_vendor !== 6"
                                    >N/A</v-chip
                                >
                                <v-progress-circular
                                    v-if="
                                        did.busy && did.valid && did.validated
                                    "
                                    :width="3"
                                    :size="24"
                                    color="primary"
                                    indeterminate
                                ></v-progress-circular>
                            </td>

                            <td v-if="showDescription" class="text-center py-0">
                                <v-text-field
                                    v-model="did.description"
                                    :rules="[(v) => !!v]"
                                    hide-details
                                    outlined
                                    dense
                                />
                            </td>
                            <td class="text-center py-0">
                                <ButtonWithTooltip
                                    icon="mdi-delete"
                                    color="error"
                                    @click="removeDID(index)"
                                    tooltip="Delete this number from the list"
                                    flat
                                />
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row dense class="mt-2">
            <v-col cols="12" class="text-center">
                <ButtonWithTooltip
                    @click="addDID"
                    icon="mdi-plus-circle"
                    tooltip="Add another number"
                    text="Add another number"
                    flat
                    block
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import DIDListImport from './DIDListImport';
import IconWithTooltip from '../IconWithTooltip';
import ButtonWithTooltip from '../ButtonWithTooltip';
import allStates from '../../../constants/states';
import DIDCarrierStatus from './DIDCarrierStatus';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'DIDListInput',
    mixins: [apiMixin],
    components: {
        DIDCarrierStatus,
        ButtonWithTooltip,
        IconWithTooltip,
        DIDListImport,
    },
    data: () => ({
        showImport: false,
        indeterminateSelectAll: false,
        imported: false,
        selectAll: false,
        formValid: false,
        didInputList: [],
        portTypes: [
            { value: 1, text: 'Cat A' },
            { value: 2, text: 'Cat C' },
        ],
        vendors: [
            { value: 6, text: 'NetSIP' },
            { value: 8, text: 'VX Group' },
        ],
        states: allStates.allStates,
    }),
    props: {
        title: {
            type: String,
            default: null,
        },
        porting: {
            type: Boolean,
            default: false,
        },
        saved: {
            type: Boolean,
            default: false,
        },
        accountNumberTitle: {
            type: String,
            default: 'Account Number',
        },
        message: {
            type: String,
            default: 'Please enter the list of numbers (DIDs) below',
        },
        showVendor: {
            type: Boolean,
            default: false,
        },
        validateNumbers: {
            type: Boolean,
            default: false,
        },
        showAccountNumber: {
            type: Boolean,
            default: false,
        },
        showCarrierStatus: {
            type: Boolean,
            default: false,
        },
        showDescription: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        update() {
            this.$emit('update', this.didList);
        },
        importDIDs(data) {
            this.removeBlanks();
            let i = 0;
            data.forEach((value) => {
                if (value.length === 10) {
                    value = `61${
                        value.charAt(0) === '0' ? value.substring(1) : value
                    }`;
                }
                if (!this.numberExists(value)) {
                    let newLength = this.addDID(value);
                    this.validateEntry(newLength - 1);
                    i++;
                }
            });
            this.$refs.form.validate();
            this.imported = i;
            this.update();
        },
        numberExists(value) {
            return (
                this.didInputList.filter((x) => x.number === value).length > 0
            );
        },
        async validateDID(did) {
            await this.$nextTick();
            let response = await this.Api.send('post',
                'dids/' + did + '/validate',
                null, true
            );
            return !!response.success;
        },
        async getCarrierStatus(did) {
            await this.$nextTick();
            let response = await this.Api.send('post',
                'dids/' + did + '/carrierstatus',
                null, true
            );
            return response.data.status;
        },
        bulkUpdatePortType(value) {
            this.didInputList.forEach((item) => {
                if (item.selected) {
                    item.port_type = value;
                }
            });
            this.$nextTick();
            this.update();
        },
        bulkUpdateVendor(value) {
            for (var i = 0; i < this.didInputList.length; i++) {
                if (
                    this.didInputList[i].selected &&
                    this.didInputList[i].i_vendor !== value
                ) {
                    this.didInputList[i].i_vendor = value;
                    this.carrierStatus(i);
                }
            }
            this.$nextTick();
            this.update();
        },
        bulkUpdateDescription(value) {
            this.didInputList.forEach((item) => {
                if (item.selected) {
                    item.description = value;
                }
            });
            this.$nextTick();
        },
        validateEntry(index) {
            this.didInputList[index].carrier_status = null;
            this.didInputList[index].validated = false;
            // change leading '0' to '61'
            if (this.didInputList[index].number.charAt(0) === '0') {
                this.didInputList[index].number = `61${this.didInputList[
                    index
                ].number.substring(1)}`;
            }
            // update range boolean
            this.didInputList[index].quantity =
                this.didInputList[index].number.search('00-99') > 0
                    ? 100
                    : this.didInputList[index].number.search('0-9') > 0
                    ? 10
                    : 1;
            // check validity via regex
            this.didInputList[index].valid = this.isValidNumber(
                this.didInputList[index].number
            );

            // check validity online
            if (this.didInputList[index].valid && this.validateNumbers) {
                this.didInputList[index].busy = true;
                this.didInputList[index].validated = false;
                return new Promise((done) => {
                    const handleResult = (index) => (result) => {
                        this.didInputList[index].busy = false;
                        this.didInputList[index].valid = result;
                        this.didInputList[index].validated = true;
                        if (this.didInputList[index].i_vendor === 6) {
                            this.carrierStatus(index);
                        } else {
                            this.update();
                        }
                    };
                    this.validateDID(this.didInputList[index].number)
                        .then(handleResult(index))
                        .catch(handleResult(index));
                    done();
                });
            }
        },
        carrierStatus(index) {
            this.didInputList[index].carrier_status = null;
            if (
                this.didInputList[index].i_vendor === 6 &&
                this.didInputList[index].valid &&
                this.didInputList[index].validated
            ) {
                this.didInputList[index].busy = true;
                return new Promise((done) => {
                    const handleResult = (index) => (result) => {
                        this.didInputList[index].busy = false;
                        this.didInputList[index].carrier_status = result;
                    };
                    this.getCarrierStatus(this.didInputList[index].number)
                        .then(handleResult(index))
                        .catch(handleResult(index));
                    done();
                });
            }
            this.update();
        },
        isValidNumber(value) {
          return /^(?:(?:61|0)[2,3,4,7,8]\d{6}(?:\d{2}|\d0-9|00-99)|(?:61)?(?:1300|1800)\d{6}|(?:64)[1,3-9]\d{7})$/gm.test(
              value
          );
        },
        bulkDelete() {
            this.didInputList = this.didInputList.filter((x) => !x.selected);
            if (this.didInputList.length === 0) this.addDID();
            this.update();
            this.$nextTick();
        },
        removeBlanks() {
            this.didInputList = this.didInputList.filter(
                (x) =>
                    x.number !== '' &&
                    x.number !== null &&
                    x.number !== undefined
            );
            this.$nextTick();
        },
        addDID(number) {
            let index = this.didInputList.push({
                number: number ? number : '',
                i_vendor: null,
                port_type: null,
                selected: false,
                valid: false,
                account_number: null,
                description: null,
                quantity: 1,
                validated: false,
                busy: false,
                carrier_status: null,
            });
            if (number) this.updateSelected();
            return index;
        },
        removeDID(index) {
            this.didInputList.splice(index, 1);
            if (this.didInputList.length === 0) this.addDID('');
            this.update();
            this.updateSelected();
        },
        changeSelectAll() {
            this.didInputList.forEach((item) => {
                item.selected = this.selectAll;
            });
        },
        updateSelected() {
            let selectedDIDs = this.didInputList.filter(
                (x) => x.selected
            ).length;
            this.selectAll = selectedDIDs > 0;
            this.indeterminateSelectAll =
                selectedDIDs > 0 && selectedDIDs < this.didInputList.length;
        },
    },
    computed: {
        isValid: function () {
            return this.$refs.form.validate();
        },
        didList: function () {
            if (!this.formValid) return [];
            let results = [];
            this.didInputList.forEach((value) => {
                if (value.number && value.valid) {
                    if (value.number.search('0-9') > 0) {
                        if (value.number.search('00-99') > 0) {
                            for (i = 0; i < 100; i++) {
                                results.push({
                                    number: String(
                                        value.number.substr(
                                            0,
                                            value.number.length - 5
                                        ) + String(i).padStart(2, '0')
                                    ),
                                    i_vendor: value.i_vendor,
                                });
                            }
                        } else {
                            for (i = 0; i < 10; i++) {
                                results.push({
                                    number: String(
                                        value.number.substr(
                                            0,
                                            value.number.length - 3
                                        ) + String(i)
                                    ),
                                    i_vendor: value.i_vendor,
                                });
                            }
                        }
                    } else {
                        var i;
                        switch ((value.number.match(/#/g) || []).length) {
                            case 1:
                                for (i = 0; i < 10; i++) {
                                    results.push({
                                        number:
                                            value.number.substr(
                                                0,
                                                value.number.length - 1
                                            ) + String(i),
                                        i_vendor: value.i_vendor,
                                    });
                                }
                                break;
                            case 2:
                                for (i = 0; i < 100; i++) {
                                    results.push({
                                        number:
                                            value.number.substr(
                                                0,
                                                value.number.length - 2
                                            ) + String(i).padStart(2, '0'),
                                        i_vendor: value.i_vendor,
                                    });
                                }
                                break;
                            default:
                                results.push(value);
                                break;
                        }
                    }
                }
            });
            return results;
        },
    },
    watch: {
        formValid: function () {
            this.update();
        },
    },
    created() {
        this.addDID();
    },
};
</script>
