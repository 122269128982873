<template>
    <span>
        <v-tooltip :disabled="tooltipDisabled" :small="small" bottom open-delay="200">
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on:mouseup="showAlert = true"
                    v-on="on"
                    v-clipboard="() => value"
                    :small="small"
                    icon
                    text
                    ><v-icon :small="small">{{ icon }}</v-icon></v-btn
                >
            </template>
            <span>{{ tooltip }}</span>
        </v-tooltip>
        <v-snackbar v-if="value" v-model="showAlert" :timeout="2500">
            <span v-if="!password">Copied '{{ value }}' to clipboard</span>
            <span v-if="password">Copied password to clipboard</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="showAlert = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </span>
</template>

<script>
export default {
    name: 'CopyToClipboard',
    data() {
        return {
            showAlert: false,
        };
    },
    props: {
        icon: {
            type: String,
            default: 'mdi-content-copy',
        },
        color: {
            type: String,
            default: 'default',
        },
        tooltip: {
            type: String,
            default: 'Copy to clipboard',
        },
        value: {
            type: String,
            default: '',
        },
        small: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
        password: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        tooltipDisabled: function () {
            return !this.tooltip;
        },
    },
};
</script>
