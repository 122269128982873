<template>
        <v-row dense>
            <v-col>
                <v-card class="pa-2 mb-2">
                    <v-row>
                        <v-col cols="12" :sm="resellerId?(addButton?9:10):4">
                            <v-text-field
                                hide-details
                                outlined
                                clearable
                                dense
                                label="Name"
                                :disabled="isBusy"
                                v-model="searchFilters.name"
                            />
                        </v-col>
                        <v-col cols="12" v-if="!resellerId" sm="3">
                            <v-select
                                hide-details
                                outlined
                                dense
                                label="Status"
                                v-model="searchFilters.hidden"
                                :disabled="isBusy"
                                :items="statusOptions"
                                item-text="option"
                                item-value="value"
                            />
                        </v-col>
                      <v-col cols="12" v-if="!resellerId" :sm="addButton?2:3">
                        <v-select
                            hide-details
                            outlined
                            dense
                            label="Owner"
                            v-model="searchFilters.owner"
                            :disabled="isBusy"
                            :items="ownerOptions"
                            item-text="option"
                            item-value="value"
                        />
                      </v-col>
                        <v-col cols="12" :sm="2">
                            <v-btn
                                outlined
                                block
                                height="40"
                                :loading="isBusy"
                                @click="updateSearch"
                            >Search</v-btn>
                        </v-col>
                        <v-col cols="12" sm="1" v-if="addButton">
                          <v-tooltip left open-delay="200">
                            <template v-slot:activator="{ on }">
                              <v-fab-transition>
                                <v-btn
                                    block
                                    height="40"
                                    :loading="isBusy"
                                    color="primary"
                                    v-on="on"
                                    @click="$emit('addButtonTriggered');"
                                ><v-icon>mdi-account-plus</v-icon></v-btn>
                              </v-fab-transition>
                            </template>
                            New Partner
                          </v-tooltip>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12">
              <PaginationCardV2
                  :page="searchFilters.page"
                  :totalRecords="listData.count"
                  :totalPages="listData.pages"
                  :limit="searchFilters.limit"
                  :currentPageTotal="(listData.results) ? listData.results.length : 0"
                  :loading="isBusy"
                  @updatedPagination="updatedPagination"
                  :no-skeleton="false"
              >
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Partner Name</th>
                      <th>Company Name</th>
                      <th v-if="$vuetify.breakpoint.smAndUp">
                        Contact
                      </th>
                      <th
                          v-if="$vuetify.breakpoint.smAndUp"
                          class="text-center"
                      >
                        Dealer Code
                      </th>
                      <th
                          v-if="$vuetify.breakpoint.smAndUp"
                          class="text-center"
                      >
                        Commission Plan
                      </th>
                      <th class="text-center">Status</th>
                    </tr>
                    </thead>
                    <v-slide-x-transition
                        tag="tbody"
                        hide-on-leave
                        group
                    >
                      <tr
                          v-for="item in listData.results"
                          :key="item.i_rep"
                          v-bind:class="{
                                        'red darken-4':
                                            $vuetify.theme.dark &&
                                            item.hidden === 'Y',
                                        'red lighten-4':
                                            !$vuetify.theme.dark &&
                                            item.hidden === 'Y',
                                    }"
                      >
                        <td>
                          <router-link
                              v-if="!item.i_customer && $store.state.user.isAdmin"
                              :to="{
                                                name: 'AdminPartnerSummary',
                                                params: {
                                                    partnerId: item.i_rep,
                                                },
                                            }"
                              class="font-weight-medium"
                          >{{ item.name }}</router-link>
                          <router-link
                              v-else-if="item.i_customer"
                              :to="{
                                                name: 'ParentPartnerSummary',
                                                params: {
                                                    partnerId: item.i_rep,
                                                },
                                            }"
                              class="font-weight-medium"
                          >{{ item.name }}</router-link>
                          <p v-else>{{ item.name }}</p>
                        </td>
                        <td>{{ item.companyname }}</td>
                        <td v-if="$vuetify.breakpoint.smAndUp">
                                        <span v-if="item.salutation"
                                        >{{ item.salutation }}
                                        </span>
                          <span v-if="item.firstname"
                          >{{ item.firstname }}
                                        </span>
                          <span v-if="item.lastname">{{
                              item.lastname
                            }}</span>
                          <span
                              v-if="
                                                !item.salutation &&
                                                !item.firstname &&
                                                !item.lastname
                                            "
                          >-</span
                          >
                        </td>
                        <td
                            v-if="$vuetify.breakpoint.smAndUp"
                            class="text-center"
                        >
                                        <span v-if="item.refnum">{{
                                            item.refnum
                                          }}</span>
                          <span v-if="!item.refnum">-</span>
                        </td>
                        <td
                            v-if="$vuetify.breakpoint.smAndUp"
                            class="text-center"
                        >
                          <CommissionPlan
                              small
                              :commission-plan="
                                                item.i_commission_plan
                                            "
                          />
                        </td>
                        <td class="text-center">
                          <v-chip
                              v-if="item.hidden === 'Y'"
                              small
                              color="red"
                              text-color="white"
                          >
                            Inactive
                          </v-chip>
                          <v-chip
                              v-if="item.hidden === 'N'"
                              small
                              color="green"
                              text-color="white"
                          >
                            Active
                          </v-chip>
                        </td>
                      </tr>
                    </v-slide-x-transition>
                  </template>
                </v-simple-table>
              </PaginationCardV2>
            </v-col>
        </v-row>
</template>

<script>
import CommissionPlan from "../../pieces/Partner/CommissionPlan";
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
import apiMixin from "../../../mixins/apiMixin";
import commissionPlanDataMixin from "../../../mixins/commissionPlanDataMixin";
import PaginationCardV2 from "../templates/PaginationCardV2";

export default {
    name: 'PartnerListCard',
    mixins: [apiMixin, commissionPlanDataMixin, refreshListenerMixin],
    data: () => ({
        busy: false,
        firstLoad: true,
        filter: {
            limit: 10,
            page: 1,
            hidden: 'N',
            name: null
        },
        searchFilters:{
          name: null,
          hidden: 'N',
          owner: 'admin',
        },
        listData: {
          count:0,
          pages:0
        },
        paginationData:[],
        // localFilter: {
        //     name: null,
        //     hidden: 'N',
        //     owner: 'admin',
        // },
        statusOptions: [
            { option: 'Active', value: 'N' },
            { option: 'Hidden', value: 'Y' },
        ],
        ownerOptions: [
          { option: 'Any', value: 'any' },
          { option: 'Admin', value: 'admin' },
          { option: 'Wholesale User', value: 'wholesale-managed' },
        ],
        data: [],
    }),
    props: {
      /**
       * This act as a toggle for how this page is being viewed.
       * If set it will show the partners for a wholesale user.
       */
      resellerId: {
        type: Number,
        default: null,
      },
      addButton: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      PaginationCardV2,
        CommissionPlan,
    },
    computed: {
      isBusy: function( ){
        //return this.loading || this.busy;
        return this.busy;
      },
    },
    methods: {
        updatedPagination(data){
          this.searchFilters.page = data.page;
          this.searchFilters.limit = data.limit;
          this.update( );
        },
        updateSearch(data){
          this.searchFilters.page = 1;
          this.update( );
        },
        async update() {
            this.busy = true;
            let params = this.searchFilters;

            if(this.resellerId){
              params.owner = 'wholesale-managed';
              params.i_customer = this.resellerId;
            }

            for (const [key, value] of Object.entries(params)) {
              if(value == null || value == undefined){
                delete params[key];
              }
            }

            let queryString = Object.keys(params).map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');

            let response = null;
            if(this.resellerId){
              response = await this.Api.send('get','wholesalers/'+this.resellerId+'/partners?'+queryString);
            }else{
              response = await this.Api.send('get','partners?'+queryString);
            }

            this.busy = false;
            if (response.success) {
              this.listData = response.data;
              this.paginationData = {
                results: response.data.results,
                count: response.data.count,
                pages: response.data.pages
              };
              if (this.firstLoad) this.firstLoad = false;
            } else {
              this.listData = [];
            }

        },
    },
};
</script>
