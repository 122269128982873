<template>
  <WholesaleManagedProducts :reseller-data="resellerData" :loading="resellerLoading"/>
</template>

<script>
import resellerDataMixin from "../../mixins/resellerDataMixin";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
import WholesaleManagedProducts from "../../components/pieces/Wholesalers/WholesaleManagedProducts";
export default {
    name: 'WholesaleManagedProductList',
    mixins: [refreshListenerMixin, resellerDataMixin],
    components: {
      WholesaleManagedProducts,
    },

};
</script>