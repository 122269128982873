<template>
  <ComplexDialog
      v-model="visible"
      :title="customerData.i_customer_type == 2 ? 'Update Wholesale Users Details' : 'Update Customer Details'"
      :loading="busy"
      :maxWidth="600"
  >
    <v-alert type="info" outlined dense>
      To edit any other details please contact admin.
    </v-alert>
    <v-alert v-if="showError" type="error" outlined dense>
    </v-alert>
    <v-form>
      <CustomerIDField v-model="idField" v-on:input="setName" :currentValue="(customerData && customerData.name ? customerData.name : null)"/>
      <v-select
          v-if="isWholesale"
          outlined
          label="Customer Class"
          v-model="form.i_customer_class"
          :disabled="busy"
          :items="customerClassOptions"
          item-text="option"
          item-value="value"
          v-on:input="updateSaveDisabled()"
      />
      <v-switch
          v-if="$store.state.user.isAdmin && customerData.i_customer_type == 2"
          v-model="form.prices_include_gst"
          hide-details
          :label="form.prices_include_gst?'Prices include GST':'Prices do not include GST'"
          @click="updateSaveDisabled()"
      />
    </v-form>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="visible = false" text>Cancel</v-btn>
      <v-btn @click="update( )" text :disabled="saveDisabled" color="primary">Change</v-btn>
    </template>
  </ComplexDialog>
</template>
<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import ComplexDialog from "../templates/ComplexDialog";
import CustomerIDField from "../../pieces/Forms/CustomerIDField";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
  name: 'EditCustomerDetailsDialog',
  components: { ComplexDialog,CustomerIDField},
  mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
  data: () => ({
    busy: false,
    idField: null,
    model:false,
    saveDisabled:true,
    form:{
      i_customer_class:null,
      prices_include_gst:null,
    },
    showError: false,
    errorMessage:'',
    // updateName:null,
    customerClasses:[],
  }),
  props: {
    customerData: {
      type: Object,
      default: null
    },
  },
  methods: {
    /**
     * Takes the emitted value and assigns it to updateName
     * @param event
     *
     * Note. As I understand the $emit('input',value) process from the CustomerIDField component should populate the
     * idField value with the new inputted value.
     * However for some reason this is not the case.
     * Outputting console.log(idField) in this method will output the expected results however the UI is not updated
     * with this value. This causes issues enabling the update buttons.
     * The idField value outputted in the UI via {{idField}} is not updated.
     *
     * This method is a work around created to solve this issue. I was not able to find a solution to this issue.
     */
    setName(event) {
      this.updateName = event;
      this.updateSaveDisabled();
    },
    async update() {
      this.busy = true;
      this.showError = false;

      if(this.updateName != null && this.customerData.name != this.updateName){
        const responseUpdateId = await this.Api.send('put', 'customers/' + this.customerData.i_customer + '/updateId', {
          "fieldId": this.updateName
        }, false);
        if (responseUpdateId.success) {
          this.idField = '';
          this.showGlobalSuccessMessage('Name updated successfully');
        }else{
          this.showGlobalErrorMessage('Failed to update Name.');
        }
      }

      if(this.isWholesale) {
        const responseUpdateId = await this.Api.send('put', 'customers/' + this.customerData.i_customer, this.form, false);
        if (responseUpdateId.success) {
          this.showGlobalSuccessMessage('Customer class updated successfully');
        } else {
          this.showGlobalErrorMessage('Failed to update CustomerClass.');
        }
      }

      if(this.$store.state.user.isAdmin && this.customerData.i_customer_type == 2){
        let params = {
          "gst_included":this.form.prices_include_gst
        };
        const responseUpdate = await this.Api.send('put', 'wholesalers/' + this.customerData.i_customer + '/toggle-pricing-gst', params, false);
        if (responseUpdate.success) {
          this.showGlobalSuccessMessage('GST toggle updated successfully');
        } else {
          this.showGlobalErrorMessage('Failed to update GST toggle.');
        }
      }


      //update the customer class
      document.dispatchEvent(new CustomEvent('refreshRequested'));
      this.visible = false;
      this.busy = false;
    },

    async pullCustomerClasses( ){
      let params = this.form;
      let response = null;
      this.busy = true;
      if(this.$store.state.user.isPartner){
        response = await this.Api.send('get','partners/'+this.customerData.i_rep+'/customer-classes');
      }else{
        response = await this.Api.send('get','wholesalers/'+this.customerData.i_parent+'/customer-classes?visible=true');
      }
      this.busy = false;
      if(response.success){
        this.customerClasses = response.data;
      }
    },

    reset( ){
      this.idField = this.customerData.name;
      if(this.isWholesale) {
        this.pullCustomerClasses();
      }
      this.form = {
        i_customer_class:null,
        prices_include_gst:null,
      };
      if(this.customerData){
        if(this.customerData.i_customer_class){
          this.form.i_customer_class = this.customerData.i_customer_class;
        }
        if(this.$store.state.user.isAdmin && this.customerData.i_customer_type == 2){
          this.form.prices_include_gst = this.customerData.gst_inclusive;
        }
      }
    },

    updateSaveDisabled( ){
      if(!this.customerData){
        this.saveDisabled = true;
        return;
      }
      if(this.form.i_customer_class != this.customerData.i_customer_class){
        this.saveDisabled = false;
        return;
      }
      if(this.updateName && this.updateName != null && this.customerData.name != this.updateName){
        this.saveDisabled = false;
        return;
      }
      console.log(this.form.prices_include_gst+' != '+this.customerData.gst_inclusive);
      if(this.form.prices_include_gst != this.customerData.gst_inclusive){
        this.saveDisabled = false;
        return;
      }
      this.saveDisabled = true;
    },
  },
  watch: {
    customerData(value) {
      if(value && value.i_customer){
        this.reset( );
      }
    },
  },
  computed: {
    isWholesale( ){
      if(this.customerData == null){
        return false;
      }else if(this.customerData.i_parent == null){
        return false;
      }else{
        return true;
      }
    },
    customerClassOptions( ){
      let ret = [];
      let temp = {};
      this.customerClasses.forEach(function (item, index) {
        temp = {option:item.title, value:item.i_customer_class};
        ret.push(temp);
      })
      return ret;
    }
  },
  created() {
    if(this.customerData != null){
      this.reset( );
    }
  },
  beforeUpdate( ){
    // if(this.customerData != null){
    //   this.idField = this.customerData.name;
    // }
  }
};
</script>