export default {
    data: () => ({
        trunkSettingsData: {

            answerModes: [
                {
                    "value": 0,
                    "text": "Reject"
                },
                {
                    "value": 1,
                    "text": "Ring Only"
                },
                {
                    "value": 2,
                    "text": "Forward Only"
                },
                {
                    "value": 3,
                    "text": "Ring then forward"
                },
                {
                    "value": 4,
                    "text": "Send to Voicemail"
                },
                {
                    "value": 5,
                    "text": "Ring then voicemail"
                },
                {
                    "value": 6,
                    "text": "Forward then voicemail"
                },
                {
                    "value": 7,
                    "text": "Ring, forward, voicemail"
                },
            ],
            forwardingModes: [
                {
                    "value": 'N',
                    "text": "Disabled"
                },
                {
                    "value": 'C',
                    "text": "Simple call forwarding"
                },
                {
                    "value": 'Y',
                    "text": "Advanced call forwarding"
                },
                {
                    "value": 'U',
                    "text": "Simple SIP URI forwarding"
                },
                {
                    "value": 'F',
                    "text": "Advanced SIP URI forwarding"
                },
            ],
            forwardingSequences: [
                {
                    "value": 'Order',
                    "text": "Order"
                },
                {
                    "value": 'Random',
                    "text": "Random"
                },
                {
                    "value": 'Simultaneous',
                    "text": "Simultaneous"
                },
                {
                    "value": 'Weighted',
                    "text": "Weighted (percentages)",
                    "disabled": true
                },
            ]
        },
    }),
    computed:{
        servicePolicyRInstanceId( ) {
            if (process.env.NODE_ENV === 'production') {
                return 101;
            }else{
                return 103;
            }
        }
    }
};
