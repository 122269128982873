<template>
  <div>
    <v-row dense>
      <v-col cols="12" lg="4">
        <WholesalerInvoiceTemplateDetailsCard :invoiceTemplateData="invoiceTemplateData" :loading="isBusy"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import resellerDataMixin from "../../mixins/resellerDataMixin";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
import WholesalerInvoiceTemplateDetailsCard
  from "../../components/cards/Wholesalers/WholesalerInvoiceTemplateDetailsCard";

export default {
    name: 'WholesaleManagedInvoiceTemplate',
    data: () => ({
      invoiceTemplateData:null,
      busyCounter:0,
    }),
    mixins: [resellerDataMixin, refreshListenerMixin],
    components: {
      WholesalerInvoiceTemplateDetailsCard
    },
    methods: {
      async loadInvoiceTemplate( ){
        this.busyCounter++;
        const response = await this.Api.send('get', 'wholesalers/' + this.resellerData.i_customer + '/invoice-templates/'+this.$route.params.wholesaleManagedInvoiceTemplateTag);
        this.busyCounter--;
        this.invoiceTemplateData = null
        if(response.success){
          if(response.data){
            this.invoiceTemplateData = response.data;
          }
        }
        if(this.invoiceTemplateData != null) {
          this.buildBreadCrumbs();
        }
      },
      update( ){
        this.loadInvoiceTemplate();
      },
      buildBreadCrumbs( ){
        let breadCrumbs;
        if(this.$store.state.user.isAdmin){
          breadCrumbs = [
            {
              //name:"AdminWholesaleManagedInvoiceTemplates",
              label: "Invoice Templates",
              //params:{"customerId":this.localIReseller}
            },
            {
              name:"AdminWholesaleManagedInvoiceTemplate",
              label: this.invoiceTemplateData.label,
              params:{"customerId":this.localIReseller,"wholesaleManagedInvoiceTemplateTag":this.invoiceTemplateData.tag}
            }
          ];
        }else{
          breadCrumbs = [
            {
              //name:"WholesaleManagedInvoiceTemplates",
              label: "Invoice Templates",
              //params:{}
            },
            {
              name:"WholesaleManagedInvoiceTemplate",
              label: this.invoiceTemplateData.label,
              params:{"wholesaleManagedInvoiceTemplateTag":this.invoiceTemplateData.tag}
            }
          ];
        }
        this.$store.commit('data', {name: 'breadCrumbsAddon', data: breadCrumbs});
      }
    },
    created() {
      if(this.resellerData && this.resellerData.i_customer){
        this.update();
      }
    },
    watch: {
      localIReseller(value) {
       if(value != null){
         this.update( );
       }
      }
    },
    computed:{
      isBusy( ){
        return this.resellerLoading || this.busyCounter > 0;
      },
      localIReseller( ){
        if(this.resellerData && this.resellerData.i_customer){
          return this.resellerData.i_customer;
        }else{
          return null;
        }
      }
    }
};
</script>