<template>
  <WholesaleManagedCustomerClassList :reseller-data="resellerData" :loading="resellerLoading"/>
</template>

<script>
import resellerDataMixin from "../../mixins/resellerDataMixin";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
import WholesaleManagedCustomerClassList from "../../components/pieces/Wholesalers/WholesaleManagedCustomerClassList";
export default {
    name: 'WholesaleManagedCustomerClassListPage',
    mixins: [refreshListenerMixin, resellerDataMixin],
    components: {
      WholesaleManagedCustomerClassList,
    },

};
</script>