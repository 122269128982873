<template>
    <v-row dense>
      <v-col cols="12">
        <ManagedWholesaleTrunkListCard v-if="iCustomer!=null" :reseller-id="iCustomer" :filter="filter" :show-filters="true" @update="updateFilter"/>
      </v-col>
    </v-row>
</template>

<script>
import ManagedWholesaleTrunkListCard from "../../components/cards/Wholesalers/ManageWholesaleTrunkListCard";
import filterMixin from "../../mixins/filterMixin";
import resellerDataMixin from "../../mixins/resellerDataMixin";
export default {
    name: 'WholesaleAccountList',
    mixins: [filterMixin, resellerDataMixin],
    components: {
      ManagedWholesaleTrunkListCard,
    },
    data: () => ({
      filter: {
        active: 'Y',
        id: null,
        note:null,
        limit: 10,
        page: 1,
      },
    }),
    methods: {

    },
  computed:{
    iCustomer( ){
      if(this.resellerData && this.resellerData.i_customer){
        return this.resellerData.i_customer;
      }else{
        return null;
      }
    }
  }
};
</script>
