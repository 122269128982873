<template>
  <div>
    <v-row dense>
      <v-col cols="12" lg="4">
        <WholesalerProductDetailsCard :product-data="productData" :loading="isBusy"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <WholesaleProductAddonListCard :reseller-data="resellerData" :product-data="productData" :volumeDiscounts="volumeDiscountList" :loading="isBusy"/>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <ManagedWholesaleProductTariffTabsCard :loading="isBusy" :productData="productData" :productTariffRatesData="tariffRateDetails" :reseller-data="this.resellerData" @updateRates="getRates( )"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WholesalerProductDetailsCard from "../../components/cards/Wholesalers/WholesalerProductDetailsCard";
import resellerDataMixin from "../../mixins/resellerDataMixin";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
import WholesaleProductAddonListCard from "../../components/cards/Wholesalers/WholesaleProductAddonListCard";
import ManagedWholesaleProductTariffTabsCard
  from "../../components/cards/Wholesalers/ManageWholesaleProductTariffTabsCard";
export default {
    name: 'WholesaleManagedProduct',
    data: () => ({
      productData:null,
      tariffRateDetails:null,
      volumeDiscountList:null,
      busyCounter:0,
    }),
    mixins: [resellerDataMixin, refreshListenerMixin],
    components: {
      ManagedWholesaleProductTariffTabsCard,
      WholesaleProductAddonListCard,
      WholesalerProductDetailsCard
    },
    methods: {
      async loadProducts( ){
        this.busyCounter++;
        //const response = await this.Api.send('get', 'wholesalers/' + this.resellerData.i_customer + '/products/'+this.$route.params.productId);
        const response = await this.Api.send('get', '/products/'+this.$route.params.productId);
        this.busyCounter--;
        this.productData = null
        if(response.success){
          this.productData = response.data;
        }
        this.buildBreadCrumbs();
      },
      async getRates( ){
        this.busyCounter++;
        const response = await this.Api.send('get', '/products/'+this.$route.params.productId+'/rates');
        this.busyCounter--;
        this.tariffRateDetails = null
        if(response.success){
          this.tariffRateDetails = response.data;
        }
      },
      async getDiscountPlans( ){
        this.busyCounter++;
        const response = await this.Api.send('get', '/wholesalers/' + this.resellerData.i_customer + '/addon-product-volume-discounts');
        this.busyCounter--;
        this.volumeDiscountList = null
        if(response.success){
          this.volumeDiscountList = response.data;
        }
      },
      update( ){
        this.loadProducts();
        this.getRates();
        this.getDiscountPlans();
      },
      buildBreadCrumbs( ){
        let breadCrumbs;
        if(this.$store.state.user.isAdmin){
          breadCrumbs = [
            {
              name:"AdminWholesaleManagedProducts",
              label: "Products",
              params:{"customerId":this.localIReseller}
            },
            {
              name:"AdminWholesaleManagedProduct",
              label: this.productData.end_user_name,
              params:{"customerId":this.localIReseller,"productId":this.productData.i_product}
            }
          ];
        }else{
          breadCrumbs = [
            {
              name:"WholesaleManagedProducts",
              label: "Products",
              params:{}
            },
            {
              name:"WholesaleManagedProduct",
              label: this.productData.end_user_name,
              params:{"productId":this.productData.i_product}
            }
          ];
        }
        this.$store.commit('data', {name: 'breadCrumbsAddon', data: breadCrumbs});
      }
    },
    created() {
      if(this.resellerData && this.resellerData.i_customer){
        this.loadProducts();
        this.getRates();
        this.getDiscountPlans();
      }
    },
    watch: {
      localIReseller(value) {
       if(value != null){
         this.loadProducts();
         this.getRates();
         this.getDiscountPlans();
       }
      }
    },
    computed:{
      isBusy( ){
        return this.resellerLoading || this.busyCounter > 0;
      },
      localIReseller( ){
        if(this.resellerData && this.resellerData.i_customer){
          return this.resellerData.i_customer;
        }else{
          return null;
        }
      }
    }
};
</script>