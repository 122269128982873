<template>
  <div>
    <CustomerAlerts :customerData="resellerData" />
    <v-row dense>
         <!-- Customer Details Card -->
        <v-col cols="12" sm="6" lg="4">
            <CustomerCard
                :data="resellerData"
                :loading="resellerLoading"
            />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <CustomerAccountCard :data="resellerData" :loading="resellerLoading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <CustomerBillingCard :data="resellerData" :loading="resellerLoading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <AddressCard :data="resellerData" :loading="resellerLoading" @changeAddress="changeAddress()"/>
        </v-col>
        <v-col cols="12" sm="6" lg="4">
            <PrimaryContactCard title="Primary Contact Details" :data="resellerData" :loading="resellerLoading" @editDetails="editPrimaryContactDetails()"/>
        </v-col>
        <v-col cols="12" sm="6" lg="4" v-if="resellerData.i_customer_type == 1">
            <CustomerLoginCard :data="resellerData" :loading="resellerLoading" />
        </v-col>
        <v-col cols="12" sm="6" lg="4" v-if="resellerData.i_customer_type == 1">
          <CustomerDefaultCallerIDCard :customerData="resellerData" :loading="resellerLoading" />
        </v-col>
      <UpdateAddressDialog v-model="changeAddressDialog" :customerId="resellerData.i_customer" typeSwitch="customer"/>
      <UpdatePrimaryContactDetailsDialog v-model="changePrimaryContactDialog" :customerId="resellerData.i_customer"/>
    </v-row>
  </div>
</template>

<script>
import CustomerCard from "../../components/cards/Customer/CustomerCard";
import AddressCard from "../../components/cards/AddressCard";
import CustomerAccountCard from "../../components/cards/Customer/CustomerAccountCard";
import CustomerLoginCard from '../../components/cards/Customer/CustomerLoginCard';
import CustomerBillingCard from '../../components/cards/Customer/CustomerBillingCard';
import resellerDataMixin from "../../mixins/resellerDataMixin";
import UpdateAddressDialog from '../../components/dialogs/Addresses/UpdateAddressDialog';
import PrimaryContactCard from "../../components/cards/PrimaryContactCard";
import UpdatePrimaryContactDetailsDialog from "../../components/dialogs/Customer/UpdatePrimaryContactDetailsDialog";
import CustomerDefaultCallerIDCard from "../../components/cards/Customer/CustomerDefaultCallerIDCard";
import CustomerAlerts from "../../components/pieces/Customer/CustomerAlerts";
import refreshListenerMixin from "../../mixins/refreshListenerMixin";
export default {
    name: 'WholesaleHome',
    data: () => ({
      changeAddressDialog:false,
      changePrimaryContactDialog: false,
    }),
    mixins: [resellerDataMixin, refreshListenerMixin],
    components: {
      CustomerDefaultCallerIDCard,
        CustomerBillingCard,
        CustomerLoginCard,
        CustomerAccountCard,
        AddressCard,
        CustomerCard,
        UpdateAddressDialog,
        PrimaryContactCard,
        UpdatePrimaryContactDetailsDialog,
        CustomerAlerts
    },
    methods: {
      changeAddress( ){
        this.changeAddressDialog = true;
      },
      editPrimaryContactDetails( ){
        this.changePrimaryContactDialog = true;
      },
      update( ){
        this.get(this.resellerId, true,'reseller');
      }
    },
  computed:{
      label( ){
        if(this.resellerData.i_customer_type == 2){
          return 'Wholesale User';
        }else{
          return 'Customer'
        }
      }
  }

};
</script>