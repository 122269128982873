import { render, staticRenderFns } from "./MoveDIDDialog.vue?vue&type=template&id=7a0f0f54&scoped=true&"
import script from "./MoveDIDDialog.vue?vue&type=script&lang=js&"
export * from "./MoveDIDDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a0f0f54",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VCol,VExpandTransition,VIcon,VProgressCircular,VProgressLinear,VRow,VSimpleTable,VSpacer,VWindow,VWindowItem})
