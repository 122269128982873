<template>
    <BasicCard title="Address Details" :loading="loading">
        <p class="title text--primary">
            <span v-if="data.baddr1">{{ data.baddr1 }}, </span>
            <span v-if="data.address_line_2">{{ data.address_line_2 }}, </span>
            <span v-if="data.city">{{ data.city }} </span>
            <span v-if="data.state">{{ data.state }} </span>
            <span v-if="data.zip">{{ data.zip }} </span>
        </p>

        <v-row dense v-if="data.baddr1">
            <v-col cols="4" class="font-weight-bold"> Address Line 1 </v-col>
            <v-col cols="8">
                {{ data.baddr1 }}
            </v-col>
        </v-row>
        <v-row dense v-if="data.address_line_2">
            <v-col cols="4" class="font-weight-bold"> Address Line 2 </v-col>
            <v-col cols="8">
                {{ data.address_line_2 }}
            </v-col>
        </v-row>
        <v-row dense v-if="data.city">
            <v-col cols="4" class="font-weight-bold"> Suburb </v-col>
            <v-col cols="8">
                {{ data.city }}
            </v-col>
        </v-row>
        <v-row dense v-if="data.state">
            <v-col cols="4" class="font-weight-bold"> State </v-col>
            <v-col cols="8">
                {{ data.state }}
            </v-col>
        </v-row>
        <v-row dense v-if="data.zip">
            <v-col cols="4" class="font-weight-bold"> Postcode </v-col>
            <v-col cols="8">
                {{ data.zip }}
            </v-col>
        </v-row>

        <Alert
            v-if="!data.baddr1 || !data.city || !data.state || !data.zip"
            type="warning"
            message="Address is incomplete!"
        />
        <template v-slot:actions v-if="!hideActions">
            <ButtonWithTooltip
                text="Edit"
                icon="mdi-pencil"
                flat
                @click="$emit('changeAddress')"
                :disabled="data.status == 'closed'"
            />
        </template>
    </BasicCard>
</template>

<script>
import Alert from '@/components/pieces/Alert';
import BasicCard from './templates/BasicCard';
import ButtonWithTooltip from '../pieces/ButtonWithTooltip';
export default {
    name: 'AddressCard',
    props: {
        data: {},
        loading: {
            type: Boolean,
            default: false,
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
    },
    components: { ButtonWithTooltip, BasicCard, Alert },
};
</script>
