<template>
  <ComplexDialog
      v-model="visible"
      title="Update Primary Contact Details"
      :loading="isBusy"
      :maxWidth="1000"
      noText="Cancel"
      yesText="Change"
      @click="changePrimaryContactDetails( )"
  >
    <ExistingContactDetailsForm
        v-model="contactDetails"
        v-if="visible"
        show-search
        :customerId="customerId"
    />
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="visible=false" text>Cancel</v-btn>
      <v-btn @click="changePrimaryContactDetails( )" :disabled="(contactDetails == null)" text color="primary">Change</v-btn>

    </template>
  </ComplexDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import ExistingContactDetailsForm from "../../pieces/Forms/ExistingContactDetailsForm";

import ComplexDialog from "../templates/ComplexDialog";
export default {
  name: 'UpdatePrimaryAddressDetailsDialog',
  components: { ComplexDialog,ExistingContactDetailsForm},
  mixins: [apiMixin, dialogMixin],
  data: () => ({
    busy: false,
    contactDetails: null,
  }),
  props: {
    customerId: {
      type: Number,
      default: null
    },
  },
  methods: {
    async changePrimaryContactDetails() {
      this.busy = true;
      const responseNewAddress = await this.Api.send('put','customers/' + this.customerId + '/primary-contact-details',this.contactDetails, false);
      this.contactDetails = null;
      document.dispatchEvent(new CustomEvent('refreshRequested',{'detail':this.$options._componentTag}));
      this.busy = false;
      this.visible = false;
    },
  },
  computed: {
    isBusy: function( ){
      return this.busy
    }
  }
};
</script>