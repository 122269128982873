<template>
  <SimpleDialog
      v-model="visible"
      :title="'Account/Trunks for Addon Product  \''+(productAddonData && productAddonData.end_user_name?productAddonData.end_user_name:null)+'\''"
      :loading="busy"
      okOnly
      styledHeader
      :maxWidth="1000"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <PaginationCardV2
              :page="searchFilters.page"
              :totalRecords="listData.count"
              :totalPages="listData.pages"
              :limit="searchFilters.limit"
              :currentPageTotal="(listData.results) ? listData.results.length : 0"
              :loading="isBusy"
              @updatedPagination="updatedPagination"
              :no-skeleton="false"
              :updateOnCreate="false"
          >
            <v-simple-table>
              <thead>
              <tr>
                <th class="text-center">Auth ID</th>
                <th class="text-center">Description</th>
                <th class="text-center"></th>
              </tr>
              </thead>
              <v-slide-x-transition tag="tbody" hide-on-leave group>
                <tr v-if="listData.results && listData.results.length === 0" key="nodata">
                  <td colspan="100%" class="text-center">
                    No results to show.
                  </td>
                </tr>
                <tr
                    v-for="item in listData.results"
                    :key="item.i_account"
                >
                  <td class="text-center">
                    {{item.id}}
                  </td>
                  <td class="text-center">
                    {{item.note}}
                  </td>
                  <td class="text-center">
                    <ButtonWithTooltip
                        icon="mdi-eye"
                        text="View"
                        tooltip="View account page"

                        :to="{name: 'TrunkSummary', params: {customerId:item.i_customer, trunkId: item.i_account}}"
                    />
                  </td>
                </tr>
              </v-slide-x-transition>
            </v-simple-table>
          </PaginationCardV2>
        </v-col>
      </v-row>
    </v-container>
  </SimpleDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import SimpleDialog from "../templates/SimpleDialog";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import PaginationCardV2 from "../../cards/templates/PaginationCardV2";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
export default {
    name: 'WholesaleAddonProductAccountListDialog',
    components: {ButtonWithTooltip, SimpleDialog, PaginationCardV2},
    mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
    data: () => ({
      searchFilters: {
        page:1,
        limit: 10,
      },
      busy: false,
      listData: {
        count:0,
        pages:0,
        results:[],
      },
      // paginationData:[],
    }),

    props: {
      resellerData: {
        type: Object,
        default: null
      },
      productData: {
        type: Object,
        default: null
      },
      productAddonData: {
        type: Object,
        default: null
      },
      loading:{
        type: Boolean,
        default:false
      }
    },
    methods: {
      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        this.update( );
      },
      updateSearch(data){
        this.searchFilters.page = 1;
        this.update( );
      },

      async update() {
        this.busy = true;
        let params = this.searchFilters;

        for (const [key, value] of Object.entries(params)) {
          if(value == null || value == undefined){
            delete params[key];
          }
        }

        let queryString = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');

        const response = await this.Api.send('get','/wholesalers/'+this.resellerData.i_customer+'/products/' + this.productData.i_product + '/addon-products/'+this.productAddonData.i_product+'/accounts?'+queryString);
        this.busy = false;
        if (response.success) {
          this.listData = response.data;
        } else {
          this.listData = {
            count:0,
            pages:0,
            results:[],
          };
        }
      },
    },
  watch: {
    visible: function (value) {
      if (value && !this.isBusy) {
        this.updatedPagination(this.searchFilters);
      }
    },
  },
  computed: {
    isBusy() {
      return this.loading || this.busy;
    },
  }
};
</script>