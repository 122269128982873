<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="productTariffRateData"
        :items-per-page="productTariffRateData.length > 10 ? 10 : -1"
        :hide-default-footer="productTariffRateData.length > 10?false:true"
        :search="searchKeyword"
        class="elevation-1"
    >
    <template v-slot:top>
      <v-text-field
          v-model="searchKeyword"
          label="Search"
          class="mx-4"
          v-if="productTariffRateData.length > 5"
      ></v-text-field>
    </template>
    <template v-slot:[`item.actions`]="{item}">
      <ButtonWithTooltip v-if="item.destination != 611800 && productData.admin_blocked_at == null" icon="mdi-pencil" tooltip="Edit the fee" @click="currentEditDialogRecord=item;showEditDialogToggle=true"/>
      <ButtonDisabledWithTooltip v-if="item.destination != 611800 && productData.admin_blocked_at != null" icon="mdi-pencil" tooltip="Edit the fee" tooltip-disabled="Disabled by admin"/>
    </template>
    <template v-slot:no-data>
      No rates
    </template>
  </v-data-table>
    <EditWholesaleProductRatePriceDialog v-model="showEditDialogToggle" :typeTag="typeTag" :productData="productData" :productTariffRateData="productTariffRateData" :rateData="currentEditDialogRecord" @updateRates="updateRates"/>
  </div>
</template>

<script>
import PaginationCard from "../../cards/templates/PaginationCard";
import TrunkStatus from '../Trunk/TrunkStatus';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import ButtonWithTooltip from "../ButtonWithTooltip";
import EditWholesaleProductRatePriceDialog from "../../dialogs/Wholesalers/EditWholesaleProductRatePriceDialog";
import ButtonDisabledWithTooltip from "../ButtonDisabledWithTooltip.vue";
export default {
    name: 'ManagedWholesaleProductTariffRatesListTable',
    mixins: [apiMixin],
    data: () => ({
        showEditDialogToggle:false,
        currentEditDialogRecord:null,
        searchKeyword:null,

        show:false,
    }),
    components: {
      ButtonDisabledWithTooltip,
      ButtonWithTooltip,
      EditWholesaleProductRatePriceDialog,
      //ButtonWithTooltip
    },
    props: {
      productTariffRateData:{
        type: Array,
        default:null,
      },
      productData: {
        type: Object,
        default: null
      },
      typeTag:{
        type: String,
        default:null,
      }
    },
    methods: {
      openEditDialog(record){
        this.currentEditDialogRecord= record;
        this.showEditDialogToggle = true;
      },
      updateRates( ){
        this.$emit('updateRates');
      }
    },
  computed:{
      headers( ){
        let headers = [
          {
            text: 'Destination',
            align: 'start',
            sortable: true,
            value: 'destination_display',
          }
        ];
        if(this.productTariffRateData.type_tag == 'standard'){
          headers.push({ text: 'Country', value: 'country' });
        }
        headers.push({ text: 'Charge', value: 'formula_details.formula_string' });
        if("productTariffRateData.tariff_reseller_data"){
          headers.push({ text: 'BC Charge', value: 'reseller-details.formula_details.formula_string' });
        }
        headers.push({ text: '',value:'actions',sortable:false});
        return headers;
      }
  }
};
</script>
