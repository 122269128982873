<template>
    <ComplexDialog
        :title="'New Partner: Step ' + step"
        v-model="visible"
        :step="step"
        :loading="loading || busy"
        :step-end="5"
        @step-end="finish"
        step-end-text="Go To New Partner"
        @step-back="step--"
        @step-next="next"
        :step-next-disabled="(step === 1 && step1Disabled( )) ||
             (step === 2 && !contact) ||
             (step === 3 && !address)"
        :max-width="1000"
        :close-confirmation="true"
    >
        <v-window v-model="step" touchless>
            <v-window-item :value="1">
              <v-expand-transition>
                <div>
                <p class="subtitle-1 text--primary">
                  Partner Details
                </p>
                  <p>You may search ABN/ACN register to prefill values below. Note an ABN/ACN is not required to create a Partner.</p>
                  <ABRSearch
                      @selected="searchSelected"
                      :show-manual-entry="false"
                      />
                  <br />
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <p>Reference name for the partner.</p>
                    <v-text-field
                        v-model="partner.companyname"
                        :label="'Company Name *'"
                        outlined
                        :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <p>Display name will be displayed in the portal.</p>
                    <PartnerDisplayNameField v-model="partner.name" />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <p>Username is used for login. Must be unique in the Portal.</p>
                    <UsernameField v-model="partner.username" />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <p>ABN/ACN</p>
                    <v-text-field
                        v-model="partner.abn"
                        :label="'ABN/ACN'"
                        outlined
                    />
                  </v-col>
                </v-row>
                </div>
              </v-expand-transition>
            </v-window-item>
            <v-window-item :value="2" v-if="visible">
                <p class="subtitle-1 text--primary">
                    Customer Contact
                </p>
                <p>Please provide details for the primary contact. Bills and account notifications will be delivered to the nominated email address.</p>
                <ContactDetailsForm
                    v-model="contact"
                    show-bcc
                />
            </v-window-item>
            <v-window-item :value="3" v-if="visible">
                <p class="subtitle-1 text--primary">
                    Customer Address
                </p>
                <p>Please provide the Partners physical address - cannot be a postal address. This should be the main location from which the services will be used. This information will be used in the <a href="https://www.acma.gov.au/give-information-ipnd" target="_blank">Integrated Public Number Database (IPND)</a> which is used by emergency services.</p>
                <AddressDetailsForm
                    v-model="address"
                    show-search
                />
            </v-window-item>
          <v-window-item :value="4" v-if="visible">
            <p class="subtitle-1 text--primary">
                Review
            </p>
            <p>Please review the below information to ensure it is correct before creating the new Partner.</p>
            <v-sheet
                v-if="$store.state.user.isAdmin"
                :color="'grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')"
                class="pa-4 mb-4"
                rounded
            >
              <p class="subtitle-1 text--primary">
                Owner Wholesale User
              </p>
              <v-row dense>
                <v-col cols="4" class="font-weight-bold">
                  ID
                </v-col>
                <v-col cols="8">
                  {{resellerData.i_customer}}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4" class="font-weight-bold">
                  Name
                </v-col>
                <v-col cols="8">
                  {{resellerData.name}}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="4" class="font-weight-bold">
                  Dealer Code
                </v-col>
                <v-col cols="8">
                  {{resellerData.refnum}}
                </v-col>
              </v-row>

            </v-sheet>


                <v-sheet
                    :color="'grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')"
                    class="pa-4 mb-4"
                    rounded
                    v-if="step==4"
                >
                  <v-row dense>
                      <v-col cols="4" class="font-weight-bold">
                          Partner
                      </v-col>
                      <v-col cols="8">
                          {{partner.name}}
                      </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="4" class="font-weight-bold">
                      Company Name
                    </v-col>
                    <v-col cols="8">
                      {{partner.companyname}}
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="4" class="font-weight-bold">
                      Username
                    </v-col>
                    <v-col cols="8">
                      {{partner.username}}
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="4" class="font-weight-bold">
                      ABN/ACN
                    </v-col>
                    <v-col cols="8">
                      {{partner.abn}}
                    </v-col>
                  </v-row>
                  <v-row dense>
                      <v-col cols="4" class="font-weight-bold">
                          Contact Person
                      </v-col>
                      <v-col cols="8">
                          <span v-if="!!contact.title">{{contact.title}} </span>{{contact.firstname}} {{contact.lastname}}
                      </v-col>
                      <v-col cols="4" class="font-weight-bold">
                          Contact Email
                      </v-col>
                      <v-col cols="8">
                          {{contact.email}}<span v-if="!!contact.bcc" class="text--disabled"> (BCC to {{contact.bcc}})</span>
                      </v-col>
                  </v-row>
                  <v-row v-if="!!contact.phone1" dense>
                      <v-col cols="4" class="font-weight-bold">
                          Business Phone
                      </v-col>
                      <v-col cols="8">
                          {{contact.phone1}}
                      </v-col>
                  </v-row>
                  <v-row v-if="!!contact.phone2" dense>
                      <v-col cols="4" class="font-weight-bold">
                          Mobile Phone / SMS
                      </v-col>
                      <v-col cols="8">
                          {{contact.phone2}}
                      </v-col>
                  </v-row>
                  <v-row v-if="address" dense>
                      <v-col cols="4" class="font-weight-bold">
                          Address
                      </v-col>
                      <v-col cols="8">
                          <AddressText :data="address" />
                      </v-col>
                  </v-row>
                </v-sheet>
                <v-alert type="info" v-if="contact">
                    A welcome email will be sent to <span class="font-weight-bold">{{contact.email}}</span><span v-if="!!contact.bcc"> (and BCC to {{contact.bcc}})</span>.
                </v-alert>
            </v-window-item>

          <v-window-item :value="5" v-if="visible">
            <v-row no-gutters>
              <v-col cols="12" class="text-center py-4">
                <v-icon size="120" color="success"
                >mdi-check-circle-outline</v-icon
                >
                <p class="text-h6">New Partner created successfully</p>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import AddressDetailsForm from '../../pieces/Forms/AddressDetailsForm';
import ContactDetailsForm from '../../pieces/Forms/ContactDetailsForm';
import AddressText from '../../pieces/Address/AddressText';
import ABRSearch from "../../pieces/Forms/ABRSearch";
import UsernameField from "../../pieces/Forms/UserNameField";
import PartnerDisplayNameField from "../../pieces/Forms/PartnerDisplayNameField";
export default {
    name: 'NewPartnerDialog',
    components: {
      PartnerDisplayNameField,
      UsernameField,
      ABRSearch,
      AddressText,
      ContactDetailsForm,
      AddressDetailsForm,
      ComplexDialog,
    },
    mixins: [dialogMixin, apiMixin],
    data: () => ({
        step: 1,
        busy: false,
        manualEntry: false,
        showForm: false,
        partner:{
          companyname: null,
          name: null,
          username:null,
          abn: null,
        },
        contact: null,
        address: null,
        new_i_rep:null,
      rules: {
        number: [
          (v) => !!v || 'Required',
          (v) =>
              /^\d+(?:\.\d{1,2})?$/i.test(v) ||
              'Enter a valid number',
          (v) => parseFloat(v) !== 0 || 'Cannot be zero',
        ],
      },
    }),
    methods: {
      setup: function( ){

      },
      searchSelected(data) {
        this.showForm = true;
        this.partner.abn = data.abn_acn;
        this.contact = {};
        this.contact.firstname = data.firstname;
        this.contact.lastname = data.lastname;
        if(data.business_names.length > 0){
          this.partner.companyname = data.business_names[0];
          this.partner.name = data.business_names[0];
        }else{
          this.partner.companyname = data.searchName;
          this.partner.name = data.searchName;
        }
      },
      step1Disabled: function( ){
        if(!this.partner.companyname){
          return true;
        }else if(!this.partner.name){
          return true;
        }else if(!this.partner.username){
          return true;
        }
        return false;
      },
        next: function() {
            if (this.step===4) {
                this.create();
            } else {
                this.step++;
            }
        },

        clearData: function () {
            this.step = 1;
            this.partner = {
              companyname: null,
              name: null,
              username:null,
              abn: null,
            }
            this.contact = null;
            this.address = null;
            this.new_i_rep = null;
        },
        async create() {
          this.busy = true;

          let params = {
            partner: this.partner,
            contact: this.contact,
            address: this.address,
            type: this.type
          };
          params.partner.firstname = params.contact.firstname;
          params.partner.lastname = params.contact.lastname;
          params.partner.phone1 = params.contact.phone;
          params.partner.phone2 = params.contact.mobile;
          params.partner.email = params.contact.email;

            const response = await this.Api.send('post', 'wholesalers/'+this.resellerData.i_customer+'/partners', params);
            if (response.success) {
                this.new_i_rep = response.data.i_rep;
                document.dispatchEvent(new CustomEvent('refreshRequested'));
                this.step++;
            }
            this.busy = false;
        },
      finish() {
          this.visible = false;
          this.$emit('partner', this.new_i_rep);
      }
    },
    watch: {
        visible(value) {
            if (!value) {
              this.clearData( );

            }else{
              let typeValues = ['wholesale-partner'];
              if(!typeValues.includes(this.type)){
                throw ('Invalid type ('+this.type+'). Process cannot continue.');
              }

              if(this.type == 'wholesale-partner' && !this.resellerData){
                throw ('Missing reseller data. Cannot continue.');
              }
              this.setup();
            }

        },
        business: {
            handler(data) {
                this.contact = (data && data.contact) ? data.contact : null
            },
            deep: true
        },
        personal: {
            handler(data) {
                this.contact = (data && data.contact) ? data.contact : null
            },
            deep: true
        },
    },
    props: {
        resellerData:{
          default: null,
        },
        type: {
          required:true,
          type:String,
        }
    },
  computed:{

  }
};
</script>

<style scoped></style>
