<template>
    <BasicCard
        :loading="loading"
        :no-skeleton="!!data.i_customer"
        class="background_accent"
    >
        <template v-slot:feature>
            <v-chip class="primary ml-2">{{label}}</v-chip>
            <v-icon right x-large>mdi-account-circle</v-icon>
        </template>
        <p class="title text--primary" v-if="data.name">{{ data.name }}</p>
        <v-row dense>
            <v-col cols="5">
                <span class="font-weight-bold">Account No</span>
            </v-col>
            <v-col cols="7">
                {{ data.i_customer }}
            </v-col>
          <v-col cols="5">
            <span class="font-weight-bold">Account Type <span v-if="data.i_parent != null">/ Customer Class</span></span>
          </v-col>
          <v-col cols="7">
            {{ data.customer_class_label }}
            <IconWithTooltip
                right
                v-if="data.i_customer_class_label == 'Unknown'"
                tooltip="Non-standard customer class in use"
                small
            />
          </v-col>

          <v-col cols="5" v-if="$store.state.user.isAdmin">
            <span class="font-weight-bold" v-if="label == 'Customer'">Type</span>
            <span class="font-weight-bold" v-else>Customer Type</span>
          </v-col>
          <v-col cols="7" v-if="$store.state.user.isAdmin">
            <span v-if="data.i_parent != null">Wholesale Customer</span>
            <span v-else-if="data.i_rep != null">Standard</span>
            <span v-else-if="data.i_customer_type == 2">Wholesale Parent</span>
            <span v-else-if="data.i_customer_class == byoCustomerClass">BYO Wholesale</span>
            <span v-else>Admin</span>
          </v-col>

            <v-col cols="5">
                <span class="font-weight-bold">Company</span>
            </v-col>
            <v-col cols="7">
                <IconWithTooltip
                    v-if="!data.companyname"
                    tooltip="No company name"
                    small
                />
                {{ data.companyname }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Created</span>
            </v-col>
            <v-col cols="7">
                <LocalDateTime :value="data.creation_date" />
            </v-col>

          <v-col cols="5" v-if="data.i_customer_type == 2 && data.customer_class_tag == 'wholesale-managed'">
            <span class="font-weight-bold">Prices include GST</span>
          </v-col>
          <v-col cols="7">
            <v-icon
                v-if="data.gst_inclusive"
                color="success"
                class="mr-2"
            >mdi-check</v-icon>
            <v-icon
                v-else
                color="error"
                class="mr-2"
            >mdi-close</v-icon>
          </v-col>
        </v-row>
        <template v-slot:actions v-if="data.bill_status !== 'C'">
          <ButtonWithTooltip
            v-if="data.i_customer_type == 1 && ($store.state.user.isAdmin || ($store.state.user.isManagedWholesale && resellerId == data.i_parent))"
            text="Transfer Ownership"
            flat
            :tooltip="'Transfer ownership of '+label"
            icon="mdi-transfer"
            @click="showTransferOwnershipDialog = true"
          />
          <ButtonWithTooltip
              text="Edit"
              flat
              :tooltip="'Edit '+label+' Details'"
              icon="mdi-pencil"
              @click="showEditContactDetailsDialog = true"
          />
          <ButtonWithTooltip
              v-if="showDeleteButton( )"
              text="Delete"
              flat
              :tooltip="'Terminate '+label"
              icon="mdi-delete"
              @click="showTerminateDialog = true"
          />
          <EditCustomerDetailsDialog
              v-model="showEditContactDetailsDialog"
              :customerData="data"
          />
          <TerminateCustomerDialog
              v-model="showTerminateDialog"
              :data="data"
          />
          <CustomerTransferOwnershipDialog
            v-model="showTransferOwnershipDialog"
            :loading="loading"
            :customerData="data"
          />
        </template>
    </BasicCard>
</template>

<script>
//import Alert from '../../pieces/Alert';
import IconWithTooltip from '../../pieces/IconWithTooltip';
import BasicCard from '../templates/BasicCard';
import LocalDateTime from '../../pieces/LocalDateTime';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import TerminateCustomerDialog from '../../dialogs/Customer/TerminateCustomerDialog';
import EditCustomerDetailsDialog from "../../dialogs/Customer/EditCustomerDetailsDialog";
import CustomerTransferOwnershipDialog from "../../dialogs/Customer/CustomerTransferOwnershipDialog";
export default {
    name: 'CustomerCard',
    components: {
      CustomerTransferOwnershipDialog,
      TerminateCustomerDialog,
      ButtonWithTooltip,
      LocalDateTime,
      BasicCard,
      IconWithTooltip,
      EditCustomerDetailsDialog,
    },
    props: {
      data: {
        type: Object,
        default: null
      },
      loading:{
        type: Boolean,
        default:false
      }
    },
    data() {
      return {
        showTerminateDialog: false,
        showEditContactDetailsDialog: false,
        showTransferOwnershipDialog:false
      };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
      showDeleteButton( )
      {
        if(this.data.i_customer_type == 2 && this.$store.state.user.isAdmin){
          return true;
        }else if(this.data.i_customer_type == 1){
          if(this.$store.state.user.isAdmin){
            return true;
          }else if(this.$store.state.user.isPartner){
            return true;
          }else if(this.$store.state.user.isManagedWholesale){
            return true;
          }
        }

        return false;
      }
    },
  computed:{
    label( ){
      if(this.data.i_customer_type == 2){
        return 'Managed Wholesale User';
      }else if(this.data.i_customer_class == process.env.VUE_APP_BYO_WHOLESALE_I_CUSTOMER_CLASS){
        return 'BYO Wholesale User';
      }else{
        return 'Customer'
      }
    },
    byoCustomerClass( ){
      return process.env.VUE_APP_BYO_WHOLESALE_I_CUSTOMER_CLASS;
    }
  }
};
</script>
