<template>
  <SimpleDialog
      v-model="visible"
      :title="'Edit pricing for '+titleLabel+ ' for destination '+(rateData == null ? '' : rateData.destination_display)"
      :loading="busy"
      styledHeader
      :maxWidth="600"
      noText="Cancel"
      yesText="Save"
      :yesDisabled="!valid"
      @click="saveRate"
  >
    <p style="font-size:larger"><strong>Fee Structure</strong><br />
      <ul v-if="hasCharge && valid">
        <li v-if="form.connection_fee != null && form.connection_fee > 0">Connection fee of <Currency :value="parseFloat(form.connection_fee)" :precision="5"/></li>
        <li v-if="form.interval_seconds != null && form.interval_seconds > 0 && form.interval_fee != null && form.interval_fee>0"><span v-if="form.connection_fee != null && form.connection_fee > 0">Then first</span><span v-else>First</span> {{ form.interval_seconds }} seconds at <Currency :value="parseFloat(form.interval_fee)" :precision="5"/> per minute</li>
        <li v-if="form.base_fee != null && form.base_fee>0"><span v-if="(form.interval_seconds != null && form.interval_seconds > 0 && form.interval_fee != null && form.interval_fee>0) || (form.connection_fee != null && form.connection_fee > 0)">Then </span><Currency :value="parseFloat(form.base_fee)" :precision="5"/> per minute</li>
      </ul>
      <ul v-if="!hasCharge && valid">
        <li>No Charge</li>
      </ul>
      <ul v-if="!valid">
        <li>Invalid inputs</li>
      </ul>
    </p>

    <v-form v-model="valid">
      <v-row dense>
        <v-col cols="6">
          <v-text-field outlined dense
                    label="Base Fee"
                    v-model="form.base_fee"
                    type="number"
                    :rules="[rules.numeric,rules.positiveNumber]"
                    min="0"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field outlined dense
                    label="Connection Fee"
                    v-model="form.connection_fee"
                    type="number"
                    :rules="[rules.numeric,rules.positiveNumber]"
                    min="0"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="6">
          <v-text-field outlined dense
                    label="First Interval Seconds"
                    v-model="form.interval_seconds"
                    type="number"
                    :rules="[rules.numeric,rules.positiveNumber,rules.wholeNumber]"
                    min="0"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field outlined dense
                    label="First Interval Fee"
                    v-model="form.interval_fee"
                    type="number"
                    :rules="[rules.numeric,rules.positiveNumber]"
                    min="0"
          />
        </v-col>
      </v-row>
    </v-form>
  </SimpleDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import SimpleDialog from "../templates/SimpleDialog";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import Currency from "../../pieces/Currency";
export default {
  name: 'EditWholesaleProductRatePriceDialog',
  components: {Currency, SimpleDialog},
  mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
  data: () => ({
    busy: false,
    valid:false,
    form:{
      base_fee:null,
      connection_fee:null,
      interval_fee:null,
      interval_seconds:null,
    },
    rules: {
      required: v => !!v || 'Required.',
      numeric: v => (!v || !isNaN(v)) || 'Must be numeric',
      positiveNumber: v => v >= 0 || 'Must be greater or equal to zero',
      wholeNumber: v => v % 1 == 0 || 'Must be a whole number without decimal',
    },
  }),

  props: {
    resellerData: {
      type: Object,
      default: null
    },
    productData: {
      type: Object,
      default: null
    },
    productTariffRateData: {
      type: Array,
      default: null
    },
    rateData: {
      type: Object,
      default: null
    },
    typeTag:{
      type: String,
      default:null
    },
    loading:{
      type: Boolean,
      default:false
    }
  },
  methods: {
    reset( ){
      this.form = {
          base_fee:null,
          connection_fee:null,
          interval_fee:null,
          interval_seconds:null,
      };

      if(this.rateData != null){
        this.form = {
          base_fee:this.rateData.formula_details.base_fee,
          connection_fee:this.rateData.formula_details.connection_fee,
          interval_fee:this.rateData.formula_details.interval_fee,
          interval_seconds:this.rateData.formula_details.interval_seconds,
        };
      }
    },

    async saveRate() {
      this.busy = true;
      let params = this.form;
      const response = await this.Api.send('put','products/'+this.productData.i_product + '/rates/'+this.rateData.i_rate, params);
      this.busy = false;
      if (response.success) {
        this.showGlobalSuccessMessage('Rate updated successfully.');
        this.$emit('updateRates');
      }
      this.visible = false;
    },
  },
  watch: {
    visible: function (value) {
      if (value && !this.isBusy) {
        this.reset();
      }
    },
    iTariff:function(value){
      if(value != null){
        this.reset( );
      }
    }
  },
  computed: {
    isBusy() {
      return this.loading || this.busy;
    },
    iTariff(){
      if(this.rateData == null){
        return null;
      }else{
        return this.rateData.i_tariff;
      }
    },
    hasCharge( ){
      if(this.form.base_fee != null && this.form.base_fee > 0){
        return true;
      }

      if(this.form.connection_fee != null && this.form.connection_fee > 0){
        return true;
      }

      if(this.form.interval_fee != null && this.form.interval_fee > 0 && this.form.interval_seconds != null && this.form.interval_seconds > 0){
        return true;
      }

      return false;
    },
    titleLabel(){
      if(this.productTariffRateData == null){
        return '';
      }
      if(this.typeTag == 'standard'){
        return 'Standard Rate';
      }else if(this.typeTag == '1300'){
        return '1300 Rate';
      }else if(this.typeTag == '1800'){
        return '1800 Rate';
      }else{
        return 'Unknown';
      }
    }
  }
};
</script>