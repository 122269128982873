<template>
    <ComplexDialog
        :title="title"
        v-model="visible"
        :max-width="650"
        :loading="busy"
    >
        <v-window v-model="step" touchless v-if="visible">
            <v-window-item :value="1">
                <p class="subtitle-1 text--primary">
                    Where would you like to move the selected DIDs?
                </p>
                <v-expand-transition>
                    <p v-if="!!customerId && !changeCustomer">
                        <a @click="showCustomerChoice">Click here</a> to transfer to a different customer
                    </p>
                </v-expand-transition>
                <v-expand-transition>
                    <CustomerDropdown
                        v-if="!customerId || changeCustomer"
                        v-model="newCustomerId"
                        @clear="newTrunkId = null"
                        @update="updateCustomer"
                        autofocus
                    />
                </v-expand-transition>
                <v-expand-transition>
                    <div
                        v-if="(customerId && !changeCustomer) || newCustomerId"
                    >
                        <TrunkDropdown
                            v-model="newTrunkId"
                            :customer-id="
                                newCustomerId ? newCustomerId : customerId
                            "
                            :error-messages="
                                trunkId && trunkId === newTrunkId
                                    ? 'The selected DIDs are already assigned to this account'
                                    : ''
                            "
                            @update="updateTrunk"
                            :label="'Account'"
                            init-dropdown
                        />
                    </div>
                </v-expand-transition>
            </v-window-item>
            <v-window-item :value="2">
                <v-row dense v-if="newTrunkId">
                    <v-col cols="12">
                        <p class="subtitle-1 text--primary">
                            <span v-if="!busy && !taskProgress"
                                >Please review the list of
                                {{ didListLocal.length }} DID<span
                                    v-if="didListLocal.length !== 1"
                                    >s</span
                                >
                                to be moved to {{ trunkData ? trunkData.account_type_label : 'Trunk' }} '{{
                                    newTrunkData ? newTrunkData.id : ''
                                }}' (ID
                                {{
                                    newTrunkData ? newTrunkData.i_account : ''
                                }}).</span
                            >
                            <span v-if="busy"
                                >Please wait while the DIDs are moved...</span
                            >
                            <span v-if="!busy && taskProgress"
                                >Operation completed, please check results
                                below.</span
                            >
                        </p>
                        <v-progress-linear
                            rounded
                            height="25"
                            v-if="busy || taskProgress > 0"
                            class="my-4"
                            :value="taskProgress"
                        >
                            <template v-slot:default="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                        </v-progress-linear>
                        <v-simple-table dense fixed-header height="400">
                            <thead>
                                <tr>
                                    <th>Phone Number</th>
                                    <th v-if="changeCustomer || !customerId">
                                        Existing Customer
                                    </th>
                                    <th v-if="changeCustomer || !customerId">
                                        New Customer
                                    </th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in didListLocal"
                                    v-bind:key="item.number"
                                    dense
                                >
                                    <td>{{ item.number }}</td>
                                    <td v-if="changeCustomer || !customerId">
                                        {{
                                            item.i_customer
                                                ? item.customer_name
                                                : '-'
                                        }}
                                    </td>
                                    <td v-if="changeCustomer || !customerId">
                                        {{
                                            newCustomerData
                                                ? newCustomerData.name
                                                : '-'
                                        }}
                                    </td>
                                    <td>
                                        <span v-if="!busy && !taskProgress">
                                            <span
                                                v-if="
                                                    item.i_account ||
                                                    item.i_customer
                                                "
                                                >Move</span
                                            >
                                            <span v-else>Assign</span>
                                            <span v-if="newTrunkId > 0">
                                                DID to trunk '{{
                                                    newTrunkData
                                                        ? newTrunkData.id
                                                        : ''
                                                }}'</span
                                            >
                                            <span v-else-if="item.i_customer">
                                                to customer<span
                                                    v-if="newCustomerData"
                                                >
                                                    '{{
                                                        newCustomerData.id
                                                    }}'</span
                                                ></span
                                            >
                                        </span>
                                        <span v-if="busy || taskProgress">
                                            <v-icon
                                                color="success"
                                                class="mr-2"
                                                v-if="item.success"
                                                >mdi-check-circle</v-icon
                                            >
                                            <v-icon
                                                color="warning"
                                                class="mr-2"
                                                v-if="item.error"
                                                >mdi-alert</v-icon
                                            >
                                            <v-progress-circular
                                                v-if="item.busy"
                                                class="mr-2"
                                                :width="3"
                                                :size="20"
                                                color="primary"
                                                indeterminate
                                            ></v-progress-circular
                                            >{{ item.status }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
        <template v-slot:actions>
            <v-btn
                v-if="step === 2"
                text
                :disabled="busy || taskProgress > 0"
                @click="step--"
                >Back</v-btn
            >
            <v-spacer />
            <v-btn
                @click="step++"
                color="primary"
                :disabled="
                    busy ||
                    (step === 1 && !newTrunkId) ||
                    (trunkId && trunkId === newTrunkId)
                "
                v-if="step !== 2"
                text
                >Next</v-btn
            >
            <v-btn
                @click="moveDIDs"
                color="primary"
                :disabled="busy"
                v-if="step === 2 && taskProgress < 100"
                text
                >Move {{ didListLocal.length }} DID<span
                    v-if="didListLocal.length !== 1"
                    >s</span
                ></v-btn
            >
            <v-btn
                @click="visible=false"
                :disabled="busy"
                color="primary"
                tooltip="Close this window"
                v-if="step === 2 && taskProgress === 100"
                text
                >Close</v-btn
            >
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import CustomerDropdown from '../../pieces/Forms/CustomerDropdown';
import TrunkDropdown from '../../pieces/Forms/TrunkDropdown';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'MoveDIDDialog',
    mixins: [apiMixin, dialogMixin],
    components: {
        TrunkDropdown,
        CustomerDropdown,
        ComplexDialog,
    },
    data: () => ({
        step: 1,
        busy: false,
        fail: false,
        tasksComplete: 0,
        didListLocal: [],
        newCustomerId: null,
        newTrunkId: null,
        newTrunkData: null,
        changeCustomer: false,
        newCustomerData: null,
    }),
    methods: {
        showCustomerChoice: function () {
            this.newTrunkId = null;
            this.newTrunkData = null;
            this.changeCustomer = true;
        },
        updateTrunk: function (val) {
            this.newTrunkData = val;
        },
        updateCustomer: function (val) {
            this.newCustomerData = val;
        },
        async moveDID(did) {
            let data = {};
            if (did.i_account) data.i_account_old = did.i_account;
            if (this.newTrunkId) data.i_master_account = this.newTrunkId;
            await this.$nextTick();
            let response = await this.Api.send('post',
                'dids/' + did.number + '/transfer',
                data
            );
            if (response.success) {
                return { success: true, message: 'DID moved successfully' };
            } else {
                return { success: false, message: response.errorDetail };
            }
        },
        moveDIDs: function () {
            this.busy = true;
            const maxNumOfWorkers = 1;
            var numOfWorkers = 0;
            var taskIndex = 0;
            return new Promise((done) => {
                const handleResult = (index) => (result) => {
                    this.didListLocal[index].busy = false;
                    this.didListLocal[index].status = result.message;
                    if (result.success) {
                        this.didListLocal[index].success = true;
                    } else {
                        this.didListLocal[index].error = true;
                    }
                    numOfWorkers--;
                    this.tasksComplete++;
                    getNextTask();
                };
                const getNextTask = () => {
                    if (
                        numOfWorkers < maxNumOfWorkers &&
                        taskIndex < this.didListLocal.length
                    ) {
                        this.didListLocal[taskIndex].status = 'Please wait...';
                        this.didListLocal[taskIndex].busy = true;
                        this.moveDID(this.didListLocal[taskIndex])
                            .then(handleResult(taskIndex))
                            .catch(handleResult(taskIndex));
                        taskIndex++;
                        numOfWorkers++;
                        getNextTask();
                    } else if (
                        numOfWorkers === 0 &&
                        taskIndex === this.didListLocal.length
                    ) {
                        taskIndex++;
                      document.dispatchEvent(new CustomEvent('refreshRequested'));
                      this.busy = false;
                        done();
                    }
                };
                getNextTask();
            });
        },
    },
    watch: {
        transferType: function (val) {
            if (val !== 1) {
                this.carrier = null;
                this.accountNumber = null;
            }
            if (val !== 2) {
                this.migrationType = null;
            }
            this.editCustomerDetail = null;
        },
        visible: function (value) {
            if (value) {
                this.didListLocal = this.didList;
                this.newCustomerId = null;
            } else {
                this.step = 1;
                this.changeCustomer = false;
                this.newTrunkId = null;
                this.newTrunkData = null;
                this.didListLocal = [];
                this.tasksComplete = 0;
            }
        },
    },
    props: {
        title: {
            type: String,
            default: 'Transfer DIDs',
        },
        customerId: {
            type: Number,
            default: null,
        },
        trunkData: {
            type: Object,
            default: null,
        },
        didList: {
            default: null,
        },
    },
    computed: {
        taskProgress: function () {
            if (!this.didListLocal.length) {
                return 0;
            }
            return (this.tasksComplete / this.didListLocal.length) * 100;
        },
        trunkId: function ( ){
          if(this.trunkData == null){
            return null;
          }else{
            return this.trunkData.i_account;
          }
        }
    },
};
</script>

<style scoped></style>
