<template>
  <ComplexDialog
      v-model="visible"
      :title="'Transfer Customer Ownership'"
      :loading="isBusy"
      :maxWidth="600"
  >
    <v-form>
      <p v-if="customerData.i_rep != null && customerPartner != null">
        Current owner: Partner - {{customerPartner.companyname}}
      </p>
      <p v-else-if="customerData.i_parent != null && resellerData != null">
        Current owner: Wholesale User - {{resellerData.companyname}}
      </p>
      <v-radio-group
          v-model="form.switchType"
          row
      >
        <v-radio
            label="Send to Partner"
            value="to-partner"
            :disabled="partners.length == 0 || customerData.i_rep != null"
            @click="updateSaveDisabled()"
        ></v-radio>
        <v-radio
            label="Claim from Partner"
            value="from-partner"
            :disabled="customerData.i_rep == null"
            @click="updateSaveDisabled()"
        ></v-radio>
        <v-radio
            label="Switch Partners"
            value="between-partners"
            :disabled="partners.length < 2 || customerData.i_rep == null"
            @click="updateSaveDisabled()"
        ></v-radio>
      </v-radio-group>
      <v-select
          v-if="form.switchType == 'to-partner' || form.switchType == 'between-partners'"
          outlined
          label="Transfer Ownership to"
          v-model="form.transfer_i_rep"
          :disabled="isBusy"
          :items="selectPartners"
          item-text="option"
          item-value="value"
          v-on:input="updateSaveDisabled()"
      />
    </v-form>
    <template v-slot:actions>
      <v-spacer/>
      <v-btn @click="visible = false" text>Cancel</v-btn>
      <v-btn @click="update( )" text :disabled="saveDisabled" color="primary">Change</v-btn>
    </template>
  </ComplexDialog>
</template>
<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import ComplexDialog from "../templates/ComplexDialog";
import CustomerIDField from "../../pieces/Forms/CustomerIDField";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
  name: 'CustomerTransferOwnershipDialog',
  components: {ComplexDialog},
  mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
  data: () => ({
    busyCounter:0,
    form:{
      switchType:null,
      transfer_i_rep:null,
    },
    partners:[],
    customerPartner:null,
    selectPartners:[],
    resellerData:null,
    saveDisabled:true,
  }),
  props: {
    customerData: {
      type: Object,
      default: null
    },
  },
  methods: {
    async pullPartners( ){
      this.busyCounter++;
      let response = await this.Api.send('get','wholesalers/'+this.customerData.i_parent+'/partners');
      this.busyCounter--;
      if(response.success){
        this.partners = response.data.results;
        let self = this;
        this.partners.forEach(function (partner, index) {
          if(self.customerData.i_rep == partner.i_rep){
            self.customerPartner = partner;
          }else{
            self.selectPartners.push({option:'#'+partner.i_rep+' '+partner.companyname, value:partner.i_rep});
          }
        });
      }
    },

    async pullWholesaleUser( ){
      this.busyCounter++;
      let response = await this.Api.send('get','customers/'+this.customerData.i_parent);
      this.busyCounter--;
      if(response.success){
        this.resellerData = response.data;
      }
    },

    reset( ){
      this.form = {
        switchType:null,
        transfer_i_rep:null
      };
      this.partners = [];
      this.customerPartner = null;
      this.selectPartners = [];
      this.saveDisabled = true;
      this.pullPartners();
      this.pullWholesaleUser();
    },

    updateSaveDisabled( ){
      if(this.form.switchType == null){
        this.saveDisabled = true;
      }

      if(this.form.switchType == 'to-partner' && this.form.transfer_i_rep == null){
        this.saveDisabled = true;
      }

      if(this.form.switchType == 'from-partner'){
        this.saveDisabled = false;
      }

      if(this.form.switchType == 'between-partners' && this.form.transfer_i_rep == null){
        this.saveDisabled = true;
      }

      this.saveDisabled = false;
    },

    async update() {
      this.busyCounter++;
      let params = {
        'switch_type':this.form.switchType,
        'i_rep':this.form.transfer_i_rep,
      };

      let queryString = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      }).join('&');

      const response = await this.Api.send('put','wholesalers/'+this.customerData.i_parent+'/customers/' + this.customerData.i_customer + '/switch-ownership?'+queryString, {}, false);
      this.busyCounter--;
      if(response.success){
        this.showGlobalSuccessMessage('Ownership switch successfully');
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
      }else{
        this.showGlobalErrorMessage('Failed to switch ownership.');
      }
    },
  },
  computed:{
    isBusy( ){
      if(this.loading){
        return true;
      }else if(this.busyCounter > 0){
        return true;
      }
      return false;
    }
  },
  watch: {
    visible(value) {
      if(value){
        this.reset( );
      }
    },
  },
};
</script>