<template>
    <BasicCard
        :loading="loading"
        :no-skeleton="!!data.balance"
        title="Account Balance"
        show-help
        @help="help"
    >
        <SimpleDialogOld
            title="Account Balance"
            :visible="showHelpDialog"
            @close="showHelpDialog = false"
            ok-only
        >
            <p>
                <strong>Account balance</strong>: for Postpaid accounts this is
                the sum of any
                <span class="font-italic">unpaid invoices</span> plus
                <span class="font-italic">call charges not yet billed</span>
                (such as PAYG and International calls). Service charges are not
                applied until the bill is generated.
            </p>
            <p>
                <strong>Credit limit</strong>: if the
                <strong>account balance</strong> exceeds this threshold
                <span class="font-italic"
                    >all outgoing calls will be barred</span
                >. Barring may also affect inbound 1300/1800 services. The
                credit limit for a Prepaid account is $0.
            </p>
        </SimpleDialogOld>

        <p
            class="display-1 text--primary mt-1 mb-1"
            v-bind:class="{
                'error--text': data.credit_exceed === 1,
            }"
        >
            <Currency
                :value="
                    data.i_balance_control_type == '1'
                        ? data.balance
                        : -data.balance
                "
            /><span v-if="data.i_balance_control_type == '2'"> credit</span>
        </p>
        <h5 v-if="data.unpaid_invoice_info && data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.outstanding_balance>0" class="text--disabled mb-1">
            includes <Currency :value="data.unpaid_invoice_info.invoices_summary.outstanding_balance" hide-tooltip /> in unpaid invoices
        </h5>
        <h3 class="mb-2">
            <span v-if="data.i_balance_control_type == '1'"
                ><Currency :value="data.credit_limit" hide-tooltip /> credit
                limit</span
            >
            <span v-if="data.i_balance_control_type == '2'"
                >Prepaid account</span
            >
            <IconWithTooltip
                icon="mdi-arrow-up-thin-circle-outline"
                v-if="data.temp_credit_limit"
                tooltip="Credit limit temporarily increased"
                right
            />
        </h3>
        <v-alert type="error" text class="mt-4 mb-0" v-if="data.credit_exceed">
            Credit limit exceeded!
        </v-alert>
        <v-alert type="info" text class="mt-4 mb-0" v-else-if="data.temp_credit_limit">
            Temporary credit limit increase of <Currency :value="data.temp_credit_limit" /> expires
            <LocalDateTime :value="data.credit_limit_until" relative />
        </v-alert>
        <template v-slot:feature>
            <v-progress-circular
                v-if="data.i_balance_control_type == '1'"
                :size="120"
                :width="20"
                :value="balanceUsed"
                :color="balanceColor"
            >
                <span v-if="balanceUsed !== null"
                    >{{ Math.round(balanceUsed) }}% <br />used</span
                >
                <span v-if="balanceUsed === null">N/A</span>
            </v-progress-circular>
        </template>
        <template v-slot:actions>
            <ButtonWithTooltip
                tooltip="Edit Credit Limit and/or notifications"
                text="Edit"
                icon="mdi-pencil"
                flat
                @click="showCreditLimitDialog = true"
                :disabled="data.status == 'closed'"
            />
        </template>
        <CustomerCreditLimitDialog
            :data="data"
            :visible="showCreditLimitDialog"
            @close="showCreditLimitDialog = false"
            @refresh="$emit('refresh')"
        />
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import SimpleDialogOld from '../../dialogs/templates/SimpleDialogOld';
import Currency from '../../pieces/Currency';
import IconWithTooltip from '../../pieces/IconWithTooltip';
import CustomerCreditLimitDialog from '../../dialogs/Customer/CustomerCreditLimitDialog';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import LocalDateTime from '../../pieces/LocalDateTime';
export default {
    name: 'CustomerAccountCard',
    components: {
        LocalDateTime,
        ButtonWithTooltip,
        CustomerCreditLimitDialog,
        IconWithTooltip,
        Currency,
        SimpleDialogOld,
        BasicCard,
    },
    data: () => ({
        showHelpDialog: false,
        showCreditLimitDialog: false,
    }),
    methods: {
        help: function () {
            this.showHelpDialog = true;
        },
    },
    computed: {
        balanceUsed: function () {
            return this.data.credit_limit
                ? this.data.balance < this.data.credit_limit &&
                  this.data.balance > 0
                    ? (this.data.balance / this.data.credit_limit) * 100
                    : this.data.balance > 0
                    ? 100
                    : 0
                : null;
        },
        balanceColor: function () {
            if (this.balanceUsed > 95) {
                return 'error';
            }
            if (this.balanceUsed > 75) {
                return 'warning';
            }
            return 'success';
        },
      label( ){
        if(this.data.i_customer_type == 2){
          return 'Wholesale User';
        }else{
          return 'Customer'
        }
      }
    },
    props: {
        data: {
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
