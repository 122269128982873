<template>
  <ComplexDialog
      :title="'Select '+ (type == 'admin-wholesale' ? 'Wholesale User' : 'Customer') + ' Account Type'"
      v-model="visible"
      :maxWidth="customerClasses.length > 1 ? 750 : 400"
      :loading="busy"
  >
    <v-container>
      <v-row class="pb-4">
        <p v-if="customerClasses.length == 0">
          No Customer Classes are available to select from.
          <span v-if="$store.state.user.isPartner">Your Wholesaler has the ability to enable and disable customer classes.</span>
          <span v-else>Customer Classes need to be set to visible in order to be shown here.<br /><br />
            <router-link v-if="$store.state.user.isAdmin" :to="{name: 'AdminWholesaleManagedCustomerClasses', params: {customerId: this.resellerId}}"><a>View customer classes.</a></router-link>
            <router-link v-else :to="{name: 'WholesaleManagedCustomerClasses'}"><a>View customer classes.</a></router-link>
          </span>
        </p>
        <template v-for="(item,index) in customerClasses">
          <v-col cols="12" :sm="customerClasses.length > 1 ? 6 : 12" :key="index">
            <div class="type-select-div">
              <h2>{{ item.title }}</h2>
              <div class="hint-container" v-if="item.href ||item.hint ">
              <p v-if="item.href"><a @click="openLink(item.href);">+ information</a></p>
              <p v-else-if="item.hint">{{item.hint}}</p>
              </div>
              <v-btn
                  elevation="2"
                  x-large
                  color="primary"
                  @click="submit(item)">Select</v-btn>
            </div>
            <v-divider class="d-sm-none mt-4"></v-divider>
          </v-col>
          <v-divider v-if="customerClasses.length > 1 && index/2 == Math.floor(index/2)" :key="index+100" class="d-none d-sm-block" vertical></v-divider>
        </template>
      </v-row>
    </v-container>
  </ComplexDialog>
</template>

<script>

import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from "../../../mixins/dialogMixin";
import ComplexDialog from "../templates/ComplexDialog";
export default {
    name: 'NewCustomerAccountTypeDialog',
    mixins: [apiMixin,dialogMixin],
    components: {ComplexDialog},
    data() {
        return {
          busy: false,
          customerClasses: [],
        };
    },
    props: {
      repId: {
        default: null,
      },
      resellerId: {
        default: null,
      },
      type:{
        default: null,
      },
      // customerAccountType: {
      //   required:true,
      //   type: String
      // },
    },
    created: function( ){
      this.update( );
    },
    methods: {
      submit: function (type) {
        this.visible = false;
        this.$emit('type-selected',type);
      },
      openLink(href){
        window.open(href,'_blank');
      },
      async update() {
        this.busy = true;
        let response = null;
        if(this.type == 'admin-wholesale'){
          response = await this.Api.send('get','wholesalers/customer-classes');
        }else if(this.type == 'partner-customer'){
            response = await this.Api.send('get','partners/'+this.repId+'/customer-classes');
        }else if(this.type == 'wholesale-managed-customer'){
            response = await this.Api.send('get','wholesalers/'+this.resellerId+'/customer-classes?visible=true');
        }else{
          response = {"success":false};
        }

        this.busy = false;
        if (response.success) {
          this.customerClasses = response.data;
        } else {
          this.customerClasses = [];
        }
      },
    }
};
</script>

<style scoped>
.type-select-div{
  text-align:center;
}
.type-select-div h2{
  margin-bottom:1rem;
}

.hint-container{
  height:3rem;
}
</style>
