<template>
  <ComplexDialog
      :title="(productAddonData == null) ? 'Create Addon Product' : 'Edit \''+productAddonData.end_user_name+'\' Addon Product'"
      v-model="visible"
      :loading="isBusy"
  >
    <v-form ref="form" v-model="isValid">
      <v-switch class="ma-0 pa-2" v-model="form.addon_product.visible" hide-details :label="form.addon_product.visible?'Visible to users':'Hidden from users'" />
      <v-switch class="ma-0 pa-2 mb-6" :disabled="!form.addon_product.visible" v-model="form.addon_product.available_to_partners" hide-details :label="form.addon_product.available_to_partners?'Available to Partners':'Not available to Partners'" />
      <v-text-field v-model="form.addon_product.end_user_name" outlined label="Name" :rules="rules.name" :counter="34" hint="EG: 10 Lines"/>
      <v-text-field v-model="form.addon_product.end_user_description" outlined label="Sub Name" :rules="rules.subName" :counter="41" hint="EG: Special note"/>
      <v-switch class="ma-0 pa-2 mb-6" v-model="subscriptionToggle" hide-details :label="subscriptionToggle ? 'Include subscription' : 'No subscription'" />
      <v-expand-transition>
        <v-card
            variant="outlined"
            v-if="subscriptionToggle"
            class="background_accent mb-10"
        >
          <v-card-text>
            <p
                class="overline mb-2"
            >
              Subscription details
            </p>
            <v-text-field v-model="form.subscription.fee" outlined label="Monthly Subscription Fee" :rules="rules.fee" type="number"/>
            <v-text-field v-model="form.subscription.invoice_description" outlined label="Invoice description" :rules="rules.invoice_description"/>
          </v-card-text>
        </v-card>
      </v-expand-transition>
      <v-text-field v-model="form.addon_product.channel_count" type="number" outlined label="Amount of channel/lines" :rules="rules.channel_count"/>
      <v-select
          hide-details
          outlined
          label="Volume Discount Plan"
          v-model="form.addon_product.i_vd_plan"
          :disabled="isBusy"
          :items="volumeDiscountOptions"
          item-text="option"
          item-value="value"
      />
    </v-form>
    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="isBusy"
          @click="submit"
          text
          :disabled="!isValid"
      >{{(productAddonData == null) ? 'Create' : 'Update'}}</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
  name: 'EditWholesaleProductAddonDialog',
  mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
  components: { ComplexDialog },
  data: () => ({
    form: {
      addon_product: {
        end_user_name: null,
        end_user_description:null,
        i_vd_plan:null,
        visible:false,
        available_to_partners:false,
        channel_count:0,
      },
      subscription:{
        fee:null,
        invoice_description:null,
      },
    },
    subscriptionToggle: false,
    busy: false,
    isValid: false,
    rules: {
      name: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1) || 'Must contain at least 2 characters.',
        (v) => (!!v && v.length < 35) || 'Must contain at less than 34 characters.'
      ],
      subName: [
        (v) => (!v || v.length > 1) || 'Must contain at least 2 characters.',
        (v) => (!v || v.length < 42) || 'Must contain at less than 41 characters.'
      ],

      invoice_description: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1 && v.length < 101) || 'Invoice Description must be between 4 and 100 characters.'
      ],

      price: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length >= 0) || 'Price must be zero or greater.'
      ],

      channel_count:[
        (v) => !isNaN(v) || 'Required',
        (v) => (!isNaN(v) && v > -1) || 'Must be zero or a whole number.',
        (v) => (!isNaN(v) && v < 33) || 'Can be no greater than 32.'
      ]

      // email: [
      //   (v) => !!v || 'Required',
      //   (v) => /^.+@.+\..+$/.test(v) || 'Invalid email',
      // ],
      // phone1: [
      //   (v) => !!v || 'Required',
      //   (v) => (/^(?:0[2,3,5-9]\d{8})?$/.test(v) || /^(?:04\d{8})?$/.test(v)) || 'Invalid Phone/Mobile number.'
      // ],
    }

  }),
  methods: {
    async submit() {
      this.busy = true;
      const param = this.form;
      if(!this.subscriptionToggle){
        param.subscription={
          fee:null,
          invoice_description:null,
        }
      }
      let response;
      if(this.productAddonData == null){
        response = await this.Api.send('post', 'wholesalers/' + this.productData.i_customer + '/products/'+this.productData.i_product+'/addon-products', param);
      }else{
        response = await this.Api.send('put', 'wholesalers/' + this.productData.i_customer + '/products/'+this.productData.i_product+'/addon-products/'+this.productAddonData.i_product, param);
      }
      if (response.success) {
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
        if(this.productAddonData == null){
          this.showGlobalSuccessMessage('Addon product added successfully.');
        }else{
          this.showGlobalSuccessMessage('Addon product updated successfully.');
        }
      }else{
        this.showGlobalErrorMessage('Process failed.');
      }
      this.busy = false;
    },
    loadEditData( ){
      this.form.addon_product.end_user_name=this.productAddonData.end_user_name;
      this.form.addon_product.end_user_description=this.productAddonData.end_user_description;
      this.form.addon_product.i_vd_plan=this.productAddonData.i_vd_plan;
      this.form.addon_product.channel_count=this.productAddonData.channel_count;
      this.form.subscription.fee=this.productAddonData.subscription.fee;
      this.form.subscription.invoice_description=this.productAddonData.subscription.invoice_description;
      if(this.productAddonData.subscription.fee != '' && this.productAddonData.subscription.fee!=null){
        this.subscriptionToggle = true;
      }
    },
    reset( ){
      this.subscriptionToggle = false;

      this.form = {
        addon_product: {
          end_user_name: null,
          end_user_description:null,
          i_vd_plan:null,
          channel_count:0,
          visible:false,
          available_to_partners:false,
        },
        subscription:{
          fee:null,
          invoice_description:null,
        },
      };

      if(this.productAddonData != null) {
        if (this.productAddonData.visible_at == null) {
          this.form.addon_product.visible = false;
        } else {
          this.form.addon_product.visible = true;
        }
        if (this.productAddonData.partner_enabled_at == null) {
          this.form.addon_product.available_to_partners = false;
        } else {
          this.form.addon_product.available_to_partners = true;
        }
      }
    }
  },
  props: {
    productData: {
      type: Object,
      default: null
    },
    productAddonData:{
      type: Object,
      default: null
    },
    volumeDiscounts:{
      type:Array,
      default:null
    },
    loading:{
      type: Boolean,
      default: false,
    }
  },
  created(){
    this.reset();
    if(this.productAddonData != null) {
      this.loadEditData();
    }
  },
  computed: {
    isBusy() {
      return this.loading || this.busy;
    },
    volumeDiscountOptions(){
      let ret = [];
      ret.push({"option":"[No Volume Discount]","value":null});
      for(let i = 0; i<this.volumeDiscounts.length; i++){
        ret.push({ option: this.volumeDiscounts[i].description, value: this.volumeDiscounts[i].i_vd_plan});
      }
      return ret;
    }
  },
  watch: {
    visible(value) {
      if(value){
        this.reset();
        if(this.productAddonData != null) {
          this.loadEditData();
        }
      }
    }
  }
};
</script>