<template>
    <SimpleDialog
        :loading="busy"
        v-model="visible"
        @close="close"
        yes-text="Confirm"
        @click="terminate"
        :yesDisabled="!yesEnabled"
        no-text="Cancel"
        :title="'Delete '+(data.customer_class_tag == 'wholesale-managed' ? 'Wholesale User' : 'Customer') +' \'' + data.name + '\'?'"
        persistent
        styledHeader
    >

      <span  v-if="validationMessage != null">
        <p class="text--primary">Unable to delete Trunk.</p>
        <v-alert type="error" v-if="validationMessage != null">{{validationMessage}}</v-alert>
      </span>
      <span  v-if="validationMessage == null">
        <v-alert type="error">This operation cannot be undone.</v-alert>

        <p class="text--primary">This customer will be deleted immediately.</p>

        <p class="text--primary">
            If there are any pending charges a final bill will be generated at
            the end of the billing period.
        </p>

        <p class="text--primary">
            To delete this customer, type 'delete' in the box below:
        </p>

        <v-text-field
            v-model="confirmDeletion"
            autofocus
            outlined
            dense
            :rules="rules"
            :disabled="busy"
        />
      </span>
        <v-alert v-if="error" type="warning">Error: {{ error }}</v-alert>
    </SimpleDialog>
</template>

<script>
import SimpleDialog from '../templates/SimpleDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from "../../../mixins/dialogMixin";
export default {
    name: 'TerminateCustomerDialog',
    mixins: [apiMixin,dialogMixin],
    components: { SimpleDialog},
    data() {
        return {
            busy: false,
            display: true,
            confirmDeletion: '',
            Api: [],
            rules: [(v) => /^delete$/i.test(v) || "Enter 'delete' to confirm"],
            error: null,
            validationMessage:null,
        };
    },
    props: {
        data: {},
    },
    methods: {
        async terminate() {
            this.busy = true;
            const response = await this.Api.send('delete',
                'customers/' + this.data.i_customer
            );
            if (response.success) {
              if(response.data.success){
                this.close( );
                document.dispatchEvent(new CustomEvent('refreshRequested'));
              }else{
                this.error = response.data.message;
              }
            } else {
              this.error = response.data.description;
            }
            this.busy = false;
        },
        close() {
            this.confirmDeletion = '';
            this.error = null;
            this.$emit('close');
        },
        reset( ){
            this.confirmDeletion = '';
            this.error = null;
            this.validationMessage = null;
        },
        async validate() {
            this.busy = true;
            const response = await this.Api.send('get',
                'customers/' + this.data.i_customer + '/delete-validation'
            );
            if (response.data.success) {
              this.busy = false;
              this.validationMessage = null;
            } else {
              this.busy = false;
              this.validationMessage = response.data.message;
            }
        },
    },
  watch: {
    visible(value) {
      if (this.value!==value) this.$emit('input', value);

      //reset on close and open.
      this.reset( );

      if(value){
        this.validate( );
      }

    },
  },
  computed:{
    yesEnabled:function(){
      return this.confirmDeletion.toLowerCase() === 'delete' && !this.busy && this.validationMessage == null;
    },
  }
};
</script>
