<template>
    <ComplexDialog
        :title="title + ': Step ' + (step + stepDiff)"
        v-model="visible"
        :max-width="1000"
        :loading="busy"
        :close-confirmation="dirty"
    >
        <v-window v-model="step" touchless>
            <v-window-item :value="1">
                <p class="subtitle-1 text--primary">
                    What type of DID request is this?
                </p>
                <RadioSelect
                    v-model="addType.value"
                    :items="typeRadio"
                />
            </v-window-item>
            <v-window-item :value="2">
                <v-row dense>
                    <v-col cols="12" v-if="addType.value === 3">
                        <DIDListInput
                            show-vendor
                            validate-numbers
                            show-carrier-status
                            @update="updateDidList"
                        />
                    </v-col>
                  <v-col cols="12" v-if="addType.value === 1">
                    <DIDPoolSelect :trunkData="trunkData" :customerData="customerData" :filter="DIDPoolfilter" :type="DIDPoolSelectType" :reseller-id="DIDPoolSelectResellerId" showFilters statusValuePerm="I" v-model="poolDidList"/>
                  </v-col>
                </v-row>
            </v-window-item>
            <v-window-item :value="3">
                <v-row dense>
                    <v-col cols="12" v-if="addType.value === 3">
                        <p class="subtitle-1 text--primary">
                            <span v-if="!busy && !taskProgress"
                                >Please review the list of
                                {{ didList.length }} DID<span
                                    v-if="didList.length !== 1"
                                    >s</span
                                >
                                to be added</span
                            >
                            <span v-if="busy"
                                >Please wait while the DIDs are added...</span
                            >
                            <span v-if="!busy && taskProgress"
                                >Operation completed, please check results
                                below.</span
                            >
                        </p>
                        <v-progress-linear
                            rounded
                            height="25"
                            v-if="busy || taskProgress > 0"
                            class="my-4"
                            :value="taskProgress"
                        >
                            <template v-slot:default="{ value }">
                                <strong>{{ Math.ceil(value) }}%</strong>
                            </template>
                        </v-progress-linear>
                        <v-simple-table dense fixed-header height="400">
                            <thead>
                                <tr>
                                    <th>Phone Number</th>
                                    <th>Vendor</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="item in didList"
                                    v-bind:key="item.number"
                                    dense
                                >
                                    <td>{{ item.number }}</td>
                                    <td>
                                        <span v-if="item.i_vendor == 6"
                                            >NetSIP</span
                                        ><span v-if="item.i_vendor == 8"
                                            >VX Group</span
                                        >
                                    </td>
                                    <td>
                                        <span v-if="!busy && !taskProgress"
                                            >Add DID to inventory<span
                                                v-if="trunkId"
                                            >
                                                and assign to trunk</span
                                            ><span
                                                v-if="customerId && !trunkId"
                                            >
                                                and assign to customer</span
                                            ></span
                                        >
                                        <span v-if="busy || taskProgress">
                                            <v-icon
                                                color="success"
                                                class="mr-2"
                                                v-if="item.success"
                                                >mdi-check-circle</v-icon
                                            >
                                            <v-icon
                                                color="warning"
                                                class="mr-2"
                                                v-if="item.error"
                                                >mdi-alert</v-icon
                                            >
                                            <v-progress-circular
                                                v-if="item.busy"
                                                class="mr-2"
                                                :width="3"
                                                :size="20"
                                                color="primary"
                                                indeterminate
                                            ></v-progress-circular
                                            >{{ item.status }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                  <v-col cols="12" v-if="addType.value === 1">
                    <div class="pa-2 pt-0 pl-3 pr-3" v-if="this.processTypeSwitch == 'reseller' && addType.value == 1">
                      <v-switch
                          inset
                          v-model="didSubscriptionToggle"
                      >
                        <template v-slot:label>
                          Assign DID Subscription
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs"
                                      v-on="on" v-html="'mdi-help-circle-outline'" />
                            </template>
                            <span> Link a DID Subscription to the selected DID(s) that will be applied to the Account/Trunk the DID is assigned to. </span>
                          </v-tooltip>
                        </template>
                      </v-switch>

                      <v-expand-transition>
                        <div v-if="didSubscriptionToggle">
                          <v-select
                              outlined
                              dense
                              label="DID Subscription"
                              v-model="didSubscriptionSelected"
                              :items="didSubscriptionOptions"
                              item-text="option"
                              item-value="value"
                          />
                        </div>
                      </v-expand-transition>
                    </div>
                    <span v-if="!busy && !taskProgressPercent">Review selected DIDs then click next to assign them to the {{processTypeSwitchLabel}}.</span>
                    <span v-if="busy">Please wait while the DIDs are added...</span>
                    <span v-if="!busy && taskProgressPercent">Operation completed, please check results below.</span>
                    <v-progress-linear
                        rounded
                        height="25"
                        v-if="busy || taskProgressPercent > 0"
                        class="my-4"
                        :value="taskProgressPercent"
                    >
                      <template v-slot:default="{ value }">
                        <strong>{{ Math.ceil(value) }}%</strong>
                      </template>
                    </v-progress-linear>
                    <v-simple-table dense fixed-header >
                      <thead>
                      <tr>
                        <th>Phone Number</th>
                        <th v-if="$store.state.user.isAdmin">Vendor</th>
                        <th>Status</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="item in poolDidList"
                          v-bind:key="item.number"
                          dense
                      >
                        <td>{{ item.number }}</td>
                        <td v-if="$store.state.user.isAdmin">{{item.vendor_name}}</td>
                        <td>
                          <span v-if="!item.processingStatus">No yet added.</span>
                          <span v-if="item.processingStatus == 'uploading'"><v-progress-circular
                              class="mr-2"
                              :width="3"
                              :size="20"
                              color="primary"
                              indeterminate
                          ></v-progress-circular> Processing request.</span>
                          <span v-if="item.processingStatus == 'success'"><v-icon
                              color="success"
                              class="mr-2"
                          >mdi-check-circle</v-icon
                          > Successfully Added</span>
                          <span v-if="item.processingStatus == 'failed'"><v-icon
                              color="warning"
                              class="mr-2"
                          >mdi-alert</v-icon
                          > Failed to add: {{item.processingError}}</span>

                        </td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
            </v-window-item>
            <v-window-item :value="4">
                <v-row dense>
                    <v-col cols="12" v-if="addType.value === 3">
                        <p class="subtitle-1 text--primary">
                            Please wait while the DIDs are processed
                        </p>
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
        <template v-slot:actions>
            <v-btn
                @click="step--"
                v-if="((step + stepDiff) > 1) && step < 3"
                :disabled="busy || taskProgressPercent > 0"
                text
                >Back</v-btn
            >
            <v-btn
                @click="reset( )"
                :disabled="busy"
                color="primary"
                v-if="step === 3 && (addType.value === 3 ||  addType.value === 1) && (taskProgress == 100 || taskProgressPercent === 100)"
                text
            >Reset</v-btn>
            <v-spacer />
            <v-btn
                @click="step++"
                color="primary"
                :disabled="
                    busy ||
                    (step === 1 && !addType.value) ||
                    (step === 2 && ((addType.value == 1 && poolDidList.length==0) || (addType.value  == 3 && didList.length==0)))
                "
                v-if="!(step === 3 && (addType.value === 3 || addType.value === 1))"
                text
                >Next</v-btn
            >
            <v-btn
                @click="createDIDs"
                color="primary"
                :disabled="busy"
                v-if="step === 3 && addType.value === 3 && taskProgress < 100"
                text
                >Add {{ didList.length }} DID<span v-if="didList.length !== 1"
                    >s</span
                ></v-btn
            >
            <v-btn
                @click="processDidsFromPool"
                color="primary"
                :disabled="busy"
                v-if="step === 3 && addType.value === 1 && taskProgressPercent < 100"
                text
            >Add {{ poolDidList.length }} DID<span v-if="poolDidList.length !== 1"
            >s</span></v-btn>

            <v-btn
                @click="visible = false"
                :disabled="busy"
                color="primary"
                v-if="step === 3 && (addType.value === 3 ||  addType.value === 1) && (taskProgress == 100 || taskProgressPercent === 100)"
                text
            >Close</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import DIDListInput from '../../pieces/DID/DIDListInput';
import RadioSelect from '../../pieces/Forms/RadioSelect';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import DIDPoolSelect from "../../pieces/DID/DIDPoolSelect";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
export default {
    name: 'AddDIDDialog',
    mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
    components: {
        RadioSelect,
        DIDListInput,
        ComplexDialog,
        DIDPoolSelect,
    },
    data: () => ({
        step: 1,
        stepDiff: 0,
        didList: [],
        poolDidList: [],
        dirty: false,
        addType: {
            value: null,
            options: [
                {
                    value: 1,
                    label: 'Search pool',
                    description:
                        'Search available DIDs that already exist in the pool',
                    disabled: false,
                },
                {
                    value: 2,
                    label: 'Order from carrier',
                    description: 'Order new DIDs from upstream carrier',
                    disabled: true,
                },
                {
                    value: 3,
                    label: 'Create (manual entry)',
                    description:
                        'Manually enter numbers to be created in the DID inventory',
                },
            ],
        },
        isValid: false,
        busy: false,
        customer: null,
        trunk: null,
        fail: false,
        transferType: null,
        tasksComplete: 0,
        taskProgressPercent: 0,
        DIDPoolfilter: {
          usage: 'A',
          number: null,
          page: 1,
          limit: 10,
        },

        didSubscriptionOptions: [], //hold list of selectable DID Subscriptions for the user to select from the UI.
        didSubscriptionSelected:null,//the selected DID Subscription
        didSubscriptionToggle:false,//for the toggle switch that allow users to link a DID Subscription
        resellerDidSubscriptions:[],//the source DID Subscriptions returned by the UI.
    }),
    methods: {
      /**
       * Resets the model
       * @param clearLists
       * @param percent
       *
       */
        reset(clearLists = true, percent = true){
          if(clearLists) {
            this.didList = [];
            this.poolDidList = [];
          }
          if(percent) {
            this.taskProgressPercent = 0;
          }
          let radios = this.typeRadio;
          this.step = 1;
          if(radios.length == 1){
            if(this.step == 1) {
              this.step = 2;
            }//else leave at current step
            this.stepDiff = -1;//used for naming steps.
            this.addType.value = 1;
          }

          if(this.processTypeSwitch == 'reseller') {
            this.getDidSubscriptions();
          }

        },
        updateDidList: function (value) {
            this.didList = value;
            this.dirty = true;
        },
      async getDidSubscriptions()
      {
        if(this.processTypeSwitch != 'reseller'){
          throw 'Invalid type to up[date DID subscriptions';
        }
        this.busy = true;
        this.resellerDidSubscriptions = [];
        let response = await this.Api.send('get','wholesalers/'+this.customerId+'/did-subscriptions');
        this.busy = false;
        if (response.success) {
          this.resellerDidSubscriptions =  response.data.results;
        }
      },
        async processDidsFromPool()
        {
          this.busy = true;
          this.taskProgressPercent = 0;
          let max = this.poolDidList.length;
          let completed = 0;
          let completedSuccess = 0;
          let params = {};
          let http = this.Api.buildHttpObject( );
          let response = null;
          let url = null;
          let urlMethod = null;
          if(this.processTypeSwitch == 'customer'){
            url = 'customers/'+this.customerId + '/dids/assign';
            urlMethod = 'put';
          }else if(this.processTypeSwitch == 'trunk') {
            url = 'trunks/' + this.trunkId + '/did/assign';
            urlMethod = 'post';
          }else if(this.processTypeSwitch == 'reseller'){
            url = 'wholesalers/'+this.customerId + '/dids/assign';
            urlMethod = 'put';
          }else{
            throw Error('Unrecognised processTypeSwitch('+this.processTypeSwitch+')');
          }
          for(let i=0; i<max; i++) {
            this.poolDidList[i].processingStatus = 'uploading';
            params = {};
            if(this.processTypeSwitch == 'customer' || this.processTypeSwitch == 'reseller'){
              params.i_did_number = this.poolDidList[i].i_did_number;
              if(this.processTypeSwitch == 'reseller'){
                //add DID Subscription is need be.
                if(this.didSubscriptionSelected != null){
                  params.i_do_batch = this.didSubscriptionSelected;
                }
              }
            }else if(this.processTypeSwitch == 'trunk'){
              params.did = this.poolDidList[i].number;
            }else{
              throw Error('Unrecognised processTypeSwitch('+this.processTypeSwitch+')');
            }

            response = http.request({
              method: urlMethod,
              url: url,
              data: params
            }).then((response) => {
              this.poolDidList[i].processingStatus = 'success';
            }).catch((error) => {
                this.poolDidList[i].processingStatus = 'failed';
                this.poolDidList[i].processingError = error.response.data.description;
              })
            .finally(() => {
              completed++;
              if(this.poolDidList[i].processingStatus == 'success'){
                completedSuccess++;
              }
              if(completed >= max){
                this.taskProgressPercent = 100;
                this.busy = false;
                document.dispatchEvent(new CustomEvent('refreshRequested'));
              }else{
                this.taskProgressPercent = Math.floor(((completed) / max) * 100);
              }
              this.$nextTick();
            });
          }
        },
        async createDID(did) {
            let data = {
                number: did.number,
                i_vendor: did.i_vendor,
            };
            if(this.processTypeSwitch == 'trunk'){
              data.i_account = this.trunkId;
            }else if(this.processTypeSwitch == 'customer'){
              data.i_customer = this.customerId;
            }else if(this.processTypeSwitch != 'pool'){
              throw Error('Unrecognised processTypeSwitch ('+this.processTypeSwitch+')');
            }

            await this.$nextTick();
            let response = await this.Api.send('post','dids/create', data);
            if (response.success) {
                if(this.processTypeSwitch == 'trunk'){
                  return {
                    success: true,
                    message: 'DID created and assigned to trunk',
                  };
                }else if(this.processTypeSwitch == 'customer'){
                  return { success: true, message: 'DID added to inventory' };
                }else if(this.processTypeSwitch == 'pool'){
                  return { success: true, message: 'DID added to inventory' };
                }
            } else {
                return { success: false, message: response.errorDetail };
            }
        },
        createDIDs: function () {
            this.busy = true;

            const maxNumOfWorkers = 1;
            var numOfWorkers = 0;
            var taskIndex = 0;

            return new Promise((done) => {
                const handleResult = (index) => (result) => {
                    this.didList[index].busy = false;
                    this.didList[index].status = result.message;
                    if (result.success) {
                        this.didList[index].success = true;
                    } else {
                        this.didList[index].error = true;
                    }
                    numOfWorkers--;
                    this.tasksComplete++;
                    getNextTask();
                };
                const getNextTask = () => {
                    if (
                        numOfWorkers < maxNumOfWorkers &&
                        taskIndex < this.didList.length
                    ) {
                        this.didList[taskIndex].status = 'Please wait...';
                        this.didList[taskIndex].busy = true;
                        this.createDID(this.didList[taskIndex])
                            .then(handleResult(taskIndex))
                            .catch(handleResult(taskIndex));
                        taskIndex++;
                        numOfWorkers++;
                        getNextTask();
                    } else if (
                        numOfWorkers === 0 &&
                        taskIndex === this.didList.length
                    ) {
                        taskIndex++;
                        document.dispatchEvent(new CustomEvent('refreshRequested'));
                        this.busy = false;
                        this.dirty = false;
                        done();
                    }
                };
                getNextTask();
            });
        },
    },
    watch: {
        transferType: function (val) {
            if (val !== 1) {
                this.carrier = null;
                this.accountNumber = null;
            }
            if (val !== 2) {
                this.migrationType = null;
            }
            this.editCustomerDetail = null;
        },
        visible(value) {
            if (value) {
                this.addType.value = null;
                this.didList = [];
                this.tasksComplete = 0;
                this.reset( );
            }
        },
      resellerDidSubscriptions: function(val)
      {
        this.didSubscriptionOptions = [];
        let parent = this;
        val.forEach(function (item, index) {
          parent.didSubscriptionOptions.push({option:item.description,value:item.i_do_batch});
        })
      },

    },
    props: {
        title: {
            type: String,
            default: 'Add DIDs',
        },
        customerData: {
            default: null,
        },
        trunkData: {
            default: null,
        },
      /*
        used to trigger how the dialog will process DID
        'customer' = create and/or assign DIDs to the customer
        'reseller' = create and/or assign DIDs to a managed wholesale user
        'trunk'=>create and/or assign DIDs to the trunk.
        'pool' => create DIDs into the pool.
       */
        processTypeSwitch:{
          type: String,
          required: true,
        },
      /**
       * Defines the options displayed in the 'type' radio.
       */
        validOptions:{
          type: Object,
          default() {
            return {"admin":[1,2,3]}
            /**
             * {"admin":[1,2,3],"partner":[2,3],"customer":[3],"all":[1]}
             */
          }
        }
    },
    computed: {
      /**
       * For certain processes we need to pass the reseller id to the DID pool select.
       * This is use for different reasons depending on the 'type' value that is passed to the DIDPoolSelect
       * @constructor
       */
      DIDPoolSelectResellerId: function (){
        if(this.processTypeSwitch=='reseller'){
          return this.customerId;
        }else if(this.trunkData && this.trunkData.isWholesaleManaged){
          return this.trunkData.i_parent;
        }else if(this.processTypeSwitch=='customer' && this.customerData.i_parent != null){
          return this.customerData.i_parent;
        }
        return null;
      },

      /**
       * Here we determine what the use case of the DIDPoolSelect is.
       * IE How it will use its values internally.
       * @constructor
       */
      DIDPoolSelectType: function(){
        if(this.processTypeSwitch=='reseller'){
          return 'assign-to-reseller';
        }else if(this.processTypeSwitch=='customer'){
          if(this.customerData.i_parent != null){
            return 'assign-to-managed-customer';
          }else{
            return 'assign-to-customer';
          }
        }else if(this.processTypeSwitch=='trunk'){
          return 'link-to-account';
        }else if(this.processTypeSwitch == 'pool'){
          return 'create-dids-to-pool';
        }else{
          throw 'Unrecognised processTypeSwitch ('+this.processTypeSwitch+').';
        }
      },
      processTypeSwitchLabel: function( ){
        if(this.processTypeSwitch == 'trunk'){
          return this.trunkData.account_type_label;
        }else if(this.processTypeSwitch == 'customer'){
          return 'Customer';
        }else if(this.processTypeSwitch == 'pool'){
          return 'Pool';
        }else if(this.processTypeSwitch == 'reseller'){
          return 'Wholesale User';
        }
        return null;
      },
        taskProgress: function () {
            if (!this.didList.length) {
                return 0;
            }
            return (this.tasksComplete / this.didList.length) * 100;
        },
        typeRadio: function( ){
          let radios = [];
          let options = [];

          if(this.$store.state.user.isAdmin && this.validOptions.admin != undefined){
            options = this.validOptions.admin;
          }else if(this.$store.state.user.isPartner && this.validOptions.partner != undefined){
            options = this.validOptions.partner;
          }else if((this.$store.state.user.isCustomer || this.$store.state.user.isByoWholesale) && this.validOptions.customer != undefined){
            options = this.validOptions.customer;
          }else if(this.$store.state.user.isManagedWholesale && this.validOptions.managedWholesale != undefined){
            options = this.validOptions.managedWholesale;
          }else if(this.validOptions.all){
            options = this.validOptions.all;
          }
          for(let i=0;i<options.length; i++)
          {
            radios.push(this.addType.options[(options[i] - 1)]);
          }
          return radios;
        },
      trunkId: function( ){
          if(this.trunkData == null){
            return null;
          }else{
            return this.trunkData.i_account;
          }
      },
      customerId: function( ){
        if(this.customerData == null){
          return null;
        }else{
          return this.customerData.i_customer;
        }
      }
    },
  /**
   * The reset process happen when the dialog is visible.
   * Hence this is not needed. This I believe to be the case. Is comment out for now.
   */
    // created( ){
    //   if(this.customerId != null){
    //     //note this does not fire if this page is reloaded.
    //     this.reset( );
    //   }
    // }
}
</script>