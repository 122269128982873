<template>
    <BasicCard
        :loading="loading"
        :no-skeleton="!!data.i_customer"
        title="Billing Info"
    >
        <v-alert type="error" text class="mt-2" v-if="data.unpaid_invoice_info && data.unpaid_invoice_info.invoices && data.unpaid_invoice_info.invoices.some((e) => e.invoice_status_desc==='Overdue')">
            This {{ label }} has {{data.unpaid_invoice_info.invoices.filter((e) => e.invoice_status_desc==='Overdue').length}} overdue invoice<span v-if="data.unpaid_invoice_info.invoices.filter((e) => e.invoice_status_desc==='Overdue').length>1">s</span>!
        </v-alert>
        <v-alert type="info" text class="mt-2" v-else-if="data.unpaid_invoice_info && data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.last_payment_amount===0">
            This {{ label }} has not made any account payments yet.
        </v-alert>
        <v-row dense>
            <v-col cols="5">
                <span class="font-weight-bold">Billing email</span>
            </v-col>
            <v-col cols="7">
                {{ data.email }}
            </v-col>
        </v-row>
        <v-row dense v-if="data.bcc">
            <v-col cols="5">
                <span class="font-weight-bold">BCC email</span>
            </v-col>
            <v-col cols="7">
                {{ data.bcc }}
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="5">
                <span class="font-weight-bold">Current bill cycle</span>
            </v-col>
            <v-col cols="7">
                <LocalDateTime :value="data.billed_to" /> to <LocalDateTime :value="data.next_billed_to" />
            </v-col>
        </v-row>
        <v-row dense v-if="data.unpaid_invoice_info && data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.overdue_balance>0" class="error--text font-weight-bold">
            <v-col cols="5">Overdue balance</v-col>
            <v-col cols="7">
                <Currency :value="data.unpaid_invoice_info.invoices_summary.overdue_balance" hide-tooltip />
            </v-col>
        </v-row>
        <v-row dense v-if="data.unpaid_invoice_info && data.unpaid_invoice_info.invoices_summary">
            <v-col cols="5">
                <span class="font-weight-bold">Outstanding balance</span>
            </v-col>
            <v-col cols="7">
                <Currency :value="data.unpaid_invoice_info.invoices_summary.outstanding_balance" hide-tooltip />
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Last payment</span>
            </v-col>
            <v-col cols="7">
                <span v-if="data.unpaid_invoice_info.invoices_summary && data.unpaid_invoice_info.invoices_summary.last_payment_amount">
                    <Currency :value="data.unpaid_invoice_info.invoices_summary.last_payment_amount" hide-tooltip /> on <LocalDateTime :value="data.unpaid_invoice_info.invoices_summary.last_payment_date" />
                </span>
                <span v-else>
                    None
                </span>
            </v-col>
        </v-row>

        <template v-slot:actions v-if="false">
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn
                        text
                        disabled
                        v-on="on"
                        v-on:click="showDialog = true"
                        v-if="false"
                    >
                        <v-icon left>mdi-pencil</v-icon> Edit
                    </v-btn>
                </template>
                <span>Change Billing Details</span>
            </v-tooltip>
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import LocalDateTime from '../../pieces/LocalDateTime';
import Currency from '../../pieces/Currency';
export default {
    name: 'CustomerBillingCard',
    components: { Currency, LocalDateTime, BasicCard },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal(refresh) {
            this.showDialog = false;
            if (refresh) {
                this.$emit('update');
            }
        },
    },
    computed:{
      label( ){
        if(this.data.i_customer_type == 2){
          return 'Wholesale User';
        }else{
          return 'Customer'
        }
      }
    }
};
</script>
