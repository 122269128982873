<template>
    <v-row dense>
      <v-col cols="12">
        <DidSubscriptionListCard v-if="resellerData && resellerData.i_customer" :resellerData="resellerData" :filter="filter" :addButton="true" @update="updateFilter"/>
      </v-col>
    </v-row>
</template>

<script>
import DidSubscriptionListCard from "../../components/cards/DID/DidSubscriptionListCard";
import filterMixin from "../../mixins/filterMixin";
import resellerDataMixin from "../../mixins/resellerDataMixin";
export default {
    name: 'WholesaleManagedDidSubscriptionList',
    mixins: [filterMixin, resellerDataMixin],
    components: {
      DidSubscriptionListCard,
    },
    data: () => ({
      filter: {
        active: 'Y',
        id: null,
        note:null,
        limit: 10,
        page: 1,
      },
    }),
};
</script>
