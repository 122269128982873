<template>
  <BasicCard
      :loading="isBusy"
      :no-skeleton="false"
      title="Addon Products"
  >
    * Drag to reorder<br />
    <span v-if="resellerData.gst_inclusive">* Prices are GST inclusive</span>
    <span v-else>* Prices are GST exclusive</span>
    <v-simple-table dense fixed-header v-if="productData!=null && !isBusy" >
      <thead>
      <tr>
        <th>Name</th>
        <th class="hidden-lg-and-down">Sub Name</th>
        <th>Monthly Price</th>
        <th>Channel Count</th>
        <th class="hidden-md-and-down">Volume Discount</th>
        <th class="hidden-lg-and-down">Invoice Description</th>
        <th class="hidden-sm-and-down">Visible to users</th>
        <th class="hidden-sm-and-down">Available to partners</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="workingAddonProduct == null || workingAddonProduct.length == 0">
        <td colspan="9" style="text-align:center;">Currently no addon product exists for this product.</td>
      </tr>
      <tr
          v-for="item in workingAddonProduct"
          v-bind:key="item.i_product"
          dense
          draggable
          @dragstart="startDrag($event, item)"

          @drop="onDrop($event, item)"
          @dragover.prevent
          @dragenter="onDragEnter($event, item)"
          @dragleave="onDragLeave($event, item)"
      >
        <td>{{ item.end_user_name }}</td>
        <td class="hidden-lg-and-down">{{ item.end_user_description }}</td>
        <td>
          <Currency v-if="item.subscription.fee!=null" :value="item.subscription.fee" />
          <span v-if="item.subscription.fee==null">No Charge</span>
        </td>
        <td>{{item.channel_count}}</td>
        <td class="hidden-md-and-down">{{getVolumeDiscountName(item.i_vd_plan)}}</td>
        <td class="hidden-lg-and-down">
          <span v-if="item.subscription.fee==null">N/A</span>
          <span v-else>{{ item.subscription.invoice_description }}</span>
        </td>
        <td class="hidden-sm-and-down"><v-icon
            v-if="item.visible_at != null"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>
          <v-icon
              v-else
              color="error"
              class="mr-2"
          >mdi-close</v-icon></td>
        <td class="hidden-sm-and-down"><v-icon
            v-if="item.partner_enabled_at != null"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>
          <v-icon
              v-else
              color="error"
              class="mr-2"
          >mdi-close</v-icon></td>
        <td>
          <ButtonWithTooltip
              :disabled="isBusy"
              flat
              tooltip="Edit addon product"
              icon="mdi-pencil"
              @click="showEditDialog(item)"
          />
          <ButtonWithTooltip
              :disabled="isBusy"
              flat
              tooltip="Delete addon product"
              icon="mdi-delete"
              @click="showDeleteConfirm(item)"
          />
          <ButtonWithTooltip
              :disabled="isBusy"
              flat
              tooltip="List accounts using this Addon Product"
              icon="mdi-format-list-bulleted"
              @click="showAccountListDialog(item)"
          />
        </td>
      </tr>
      </tbody>
    </v-simple-table>
    <template v-slot:actions>
      <ButtonWithTooltip
        text="Create Addon Product"
        v-if="(productData != null && workingAddonProduct.length < 10 && productData.admin_blocked_at == null)"
        :disabled="false"
        flat
        :tooltip="'Add new addon product.'"
        icon="mdi-plus-box"
        @click="showNewDialog( )"
      />

      <ButtonDisabledWithTooltip
        text="Create Addon Product"
        v-if="(productData != null && workingAddonProduct.length > 9)"
        :disabled="true"
        flat
        :tooltip="'Add new addon product.'"
        :tooltip-disabled="'Maximum amount of 10 add product already exists.'"
        icon="mdi-plus-box"
      />

      <ButtonDisabledWithTooltip
        text="Create Addon Product"
        v-if="(productData != null && !$store.state.user.isAdmin && productData.admin_blocked_at != null)"
        :disabled="true"
        flat
        :tooltip="'Add new addon product.'"
        :tooltip-disabled="'Product is disabled by admin.'"
        icon="mdi-plus-box"
      />

    </template>
    <EditWholesaleProductAddonDialog v-if="volumeDiscounts != null" v-model="showProductAddonDialog" :product-data="productData" :productAddonData="editingAddonProductData" :volumeDiscounts="volumeDiscounts"/>
    <WholesaleAddonProductAccountListDialog v-model="showProductAddonAccountListDialog" :reseller-data="resellerData" :product-data="productData" :productAddonData="accountListAddonProductData"/>
    <SimpleDialog v-if="deleteAddonProductData!=null" v-model="deleteConfirmDialog"
                  yesText="Confirm Delete" yesColor="red" title="Delete Addon Product?"
                  :yesDisabled="deleteAddonProductData.is_used == 1 || deleteAddonProductData.is_used == true" @click="deleteAddonProduct( )">
      <p v-if="deleteAddonProductData.is_used == 0 || deleteAddonProductData.is_used == false">Unable to delete product '{{deleteAddonProductData.end_user_name}}'. Product is still in use. Addon Products cannot be removed if currently being used by an account.
      <br /><br />
        <a @click="accountListAddonProductData = deleteAddonProductData;deleteConfirmDialog=false;showProductAddonAccountListDialog=true;">View list of accounts using this Addon Product.</a>
      </p>
      <p v-else>Are you sure you wish to delete add on product '{{deleteAddonProductData.end_user_name}}'?</p>
    </SimpleDialog>
  </BasicCard>
</template>

<script>
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import EditWholesaleProductAddonDialog from "../../dialogs/Wholesalers/EditWholesaleProductAddonDialog";
import Currency from "../../pieces/Currency";
import SimpleDialog from "../../dialogs/templates/SimpleDialog";
import WholesaleAddonProductAccountListDialog from "../../dialogs/Wholesalers/WholesaleAddonProductAccountListDialog";
import ButtonDisabledWithTooltip from "../../pieces/ButtonDisabledWithTooltip.vue";
export default {
    name: 'WholesaleProductAddonListCard',
    mixins: [apiMixin, refreshListenerMixin],
    data: () => ({
      busy:false,
      showProductAddonDialog:false,
      showProductAddonAccountListDialog: false,
      editingAddonProductData:null,
      accountListAddonProductData:null,
      deleteConfirmDialog:false,
      deleteAddonProductData:null,
      workingAddonProduct:[],
    }),
    components: {
      ButtonDisabledWithTooltip,
      WholesaleAddonProductAccountListDialog,
      SimpleDialog,
      Currency,
      EditWholesaleProductAddonDialog,
      BasicCard,
      ButtonWithTooltip,
    },
    props: {
      productData: {
        type: Object,
        default:null
      },
      resellerData: {
        type: Object,
        default: null
      },
      volumeDiscounts:{
        type:Array,
        default:null
      },
      loading:{
        type: Boolean,
        default:false,
      },
    },
    methods: {
      reset() {
        this.localFilter.id = null;
        this.localFilter.null = null;
      },
      showDeleteConfirm(data){
        this.deleteConfirmDialog = true
        this.deleteAddonProductData = data;
      },
      showEditDialog(data){
        this.showProductAddonDialog = true
        this.editingAddonProductData = data;
      },
      showNewDialog( ){
        this.editingAddonProductData = null;
        this.showProductAddonDialog = true
      },
      showAccountListDialog(data){
        this.showProductAddonAccountListDialog = true
        this.accountListAddonProductData = data;
      },
      getVolumeDiscountName(i_vd_plan){
        if(i_vd_plan == null){
          return '';
        }else{
          let filtered = this.volumeDiscounts.filter(e => e.i_vd_plan == i_vd_plan);
          if(filtered.length > 0){
            return filtered[0].description;
          }else{
            return '';
          }
        }
      },

      /**
       * Here we conduct the following.
       * We take a copy of the addon products and reorder the local lost of addon based on the ids passed to this method.
       * Build a list of i_product is based on the current order of addon list and send this to the API.
       * On success we update the product addon order. Else we leave.
       */
      async reorderAddonProduct(droppedItem, reOrderIProduct) {
        const params = {
          //i_product_order:this.productData.addons.map(item => item.i_product)
          i_product_order:[]
        };

        const currentOrder = []; //used to revert on failure

        //determine the new order
        this.productData.addons.forEach(function(item, index){
          if(item.i_product == droppedItem.i_product){
            //we place the item in front
            params.i_product_order.push(reOrderIProduct);
            params.i_product_order.push(droppedItem.i_product);
          }else if(item.i_product != reOrderIProduct){
            params.i_product_order.push(item.i_product);
          }//else matches the reOrderIProduct so we need to exclude

          //we update the current order
          currentOrder.push(item.i_product);
        });

        //Now we have the new order we update the current display.
        //Note that the display is update before API submission. Provide a smoother experience.
        //Note this does assume success. Which is expected.

        this.workingAddonProduct.sort(function(a, b){
          //return params.i_product_order.indexOf(a) - params.i_product_order.indexOf(b);
          return params.i_product_order.indexOf(a.i_product) - params.i_product_order.indexOf(b.i_product);
        });

        const response = await this.Api.send('put', 'wholesalers/' + this.productData.i_customer + '/products/'+this.productData.i_product+'/reorder' , params);

        if (response.success) {
          //we again update the display order as there can be concurrent request. So ultimate we update from the return of the slowest/last request.
          this.workingAddonProduct.sort(function(a, b){
            return params.i_product_order.indexOf(a.i_product) - params.i_product_order.indexOf(b.i_product);
          });
        }else{
          //on failure we need to revert the order. As pre-order the display before submission to the API.
          this.workingAddonProduct.sort(function(a, b){
            return currentOrder.indexOf(a.i_product) - currentOrder.indexOf(b.i_product);
          });
        }
      },

      async deleteAddonProduct() {
        this.busy = true;
        const param = this.form;
        const response = await this.Api.send('delete', 'wholesalers/' + this.productData.i_customer + '/products/'+this.productData.i_product+'/addon-products/'+this.deleteAddonProductData.i_product, param);

        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
            this.showGlobalSuccessMessage('Add on product deleted successfully.');
        }else{
          this.showGlobalErrorMessage('Process failed.');
        }
        this.busy = false;
      },

      startDrag(evt, item) {
        evt.dataTransfer.dropEffect = 'move';
        evt.dataTransfer.effectAllowed = 'move';
        evt.dataTransfer.setData('iproduct', item.i_product);
      },
      onDrop(evt, item) {
        let trNode = this.findClosestTr(evt.target,0);
        if(trNode != null){
          trNode.classList.remove('dragenter');
        }
        this.reorderAddonProduct(item,parseInt(evt.dataTransfer.getData('iproduct')));
      },
      onDragEnter(evt, item) {
        let trNode = this.findClosestTr(evt.target,0);
        if(trNode != null){
          trNode.classList.add('dragenter');
        }
      },
      onDragLeave(evt, item) {
        let trNode = this.findClosestTr(evt.target,0);
        if(trNode != null){
          trNode.classList.remove('dragenter');
        }
      },
      findClosestTr(element,loop){
        if(element.tagName.toLowerCase() == 'tr'){
          return element;
        }else if(loop == 5){
          return null;
        }else{
          loop++;
          return this.findClosestTr(element.parentNode,loop);
        }
      }
    },
    computed: {
      isBusy: function( ){
        return this.loading || this.busy;
      },
      productAddons: function( ){
        if(this.productData == null){
          return [];
        }else{
          return this.productData.addons;
        }
      }
    },
    watch: {
      productAddons(value) {
          this.workingAddonProduct = this.productData.addons;
      }
    },
}
</script>
<style scoped>
.dragenter{
  background-color: #eec7a0;
}

.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
</style>