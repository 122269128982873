<template>
  <v-row>
    <v-col cols="4"
        v-for="customerClass in customerClasses"
        :key="customerClass.i_customer_claass"
    >
      <WholesalerCustomerClassListCard :loading="isBusy" :customer-class="customerClass" />
    </v-col>
    <v-col cols="4"
           v-if="isBusy"
    >
      <BasicCard
          :loading="true"
          :no-skeleton="false"
      />
    </v-col>
  </v-row>
</template>

<script>
import BasicCard from "../../cards/templates/BasicCard";
import WholesalerCustomerClassListCard from "../../cards/Wholesalers/WholesalerCustomerClassListCard";


export default {
    name: 'WholesaleManagedCustomerClassList',
    data: () => ({
      busy: false,
      customerClasses:null,
    }),
    components: {
      WholesalerCustomerClassListCard,
      BasicCard,
    },
    methods: {
      async getCustomerClasses() {
        this.busy = true;
        const response = await this.Api.send('get','wholesalers/'+this.resellerData.i_customer+'/customer-classes');
        this.busy = false;
        this.products = null;
        if(response.success){
          this.customerClasses = response.data;
        }
      },
    },
  created( ){
      if(this.resellerData && this.resellerData.i_customer) {
        this.getCustomerClasses();
      }
  },
  computed:{
    isBusy( ){
      return this.loading || this.busy;
    },
  },
  watch: {
    resellerData(value) {
      if (value.i_customer) {
        this.getCustomerClasses();
      }
    }
  },
  props: {
    resellerData:{
      type: Object,
      default: null,
    },
    loading:{
      type: Boolean,
      default: false
    }
  },
};
</script>