<template>
  <ComplexDialog
      :title="'Edit Customer Class ('+customerClassData.title+') Details'"
      v-model="visible"
      :loading="busy"
  >
    <v-form ref="form" v-model="isValid">
      <v-switch class="ma-0 pa-2" v-model="form.visible" hide-details  :label="form.visible?'Visible to users':'Hidden from users'" />
      <v-switch class="ma-0 pa-2 mb-6" :disabled="!form.visible" v-model="form.available_to_partners" hide-details :label="form.available_to_partners?'Available to Partners':'Not available to Partners'"/>
      <v-text-field v-model="form.description" outlined label="Name" :rules="rules.name" :counter="50"/>
    </v-form>
    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="busy"
          @click="update"
          text
          :disabled="!isValid"
      >Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
export default {
  name: 'EditWholesaleCustomerClassDetailsDialog',
  mixins: [apiMixin, dialogMixin],
  components: { ComplexDialog },
  data: () => ({
    valid: false,
    form: {
      visible:null,
      available_to_partners:null,
      description:null,
    },
    busy: false,
    isValid: false,
    rules: {
      name: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1) || 'Must contain at least 2 characters.',
        (v) => (!!v && v.length < 51) || 'Must contain at less than 50 characters.'
      ],
      subName: [
        (v) => (!v || v.length > 1) || 'Must contain at least 2 characters.',
        (v) => (!v || v.length < 64) || 'Must contain at less than 63 characters.'
      ],
      price: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length >= 0) || 'Price must be zero or greater.'
      ],
    }

  }),
  methods: {
    async update() {
      this.busy = true;
      const param = this.form;
      const response = await this.Api.send('put', 'wholesalers/' + this.customerClassData.i_customer + '/customer-classes/'+this.customerClassData.i_customer_class, param);
      if (response.success) {
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
      }
      this.busy = false;
    },

  },

  props: {
    customerClassData: {
      type: Object,
      default: null
    },
    loading:{
      type: Boolean,
      default: false,
    }
  },
  created(){
    if(this.customerClassData.visible_at == null){
      this.form.visible = false;
    }else{
      this.form.visible = true;
    }
    if(this.customerClassData.partner_enabled_at == null){
      this.form.available_to_partners = false;
    }else{
      this.form.available_to_partners = true;
    }
    this.form.description = this.customerClassData.title;
  },
  computed: {
    isBusy() {
      return this.loading || this.busy;
    },
  }
};
</script>