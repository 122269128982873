<template>
  <BasicCard
      :loading="loading"
      :no-skeleton="false"
  >
    <template v-slot:feature>
      <v-chip v-if="productData != null && productData.admin_blocked_at != null" class="warning ml-2">Disabled by Admin</v-chip>
      <v-icon right x-large>mdi-package-variant-closed</v-icon>
    </template>
    <p v-if="productData != null" class="title text--primary">{{productData.end_user_name}}</p>
    <v-row dense v-if="productData != null">

      <v-col cols="4">
        <span class="font-weight-bold">Sub Name</span>
      </v-col>
      <v-col cols="8">
        {{productData.end_user_description}}
      </v-col>

      <v-col cols="4">
        <span class="font-weight-bold">Visible to users</span>
      </v-col>
      <v-col cols="8">
        <v-icon
            v-if="productData.visible_at != null"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>
        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-close</v-icon>
      </v-col>

      <v-col cols="4">
        <span class="font-weight-bold">Available to Partners</span>
      </v-col>
      <v-col cols="8">
        <v-icon
            v-if="productData.partner_enabled_at != null"
            color="success"
            class="mr-2"
        >mdi-check</v-icon>
        <v-icon
            v-else
            color="error"
            class="mr-2"
        >mdi-close</v-icon>
      </v-col>

      <v-col cols="4">
        <span class="font-weight-bold">Count Addon Products</span>
      </v-col>
      <v-col cols="8">
        {{productData.addons.length}}
      </v-col>

    </v-row>
    <v-row dense v-if="($store.state.user.isAdmin || $store.state.user.isManagedWholesale) && productData != null">

    </v-row>
    <template v-slot:actions>
      <ButtonWithTooltip
          v-if="productData != null && ($store.state.user.isAdmin || productData.admin_blocked_at == null)"
          text="Edit"
          flat
          :tooltip="'Edit '+productData.end_user_name+' Details'"
          icon="mdi-pencil"
          @click="showEditDialog = true"
      />
      <ButtonDisabledWithTooltip
        v-if="productData != null && productData.admin_blocked_at != null && !$store.state.user.isAdmin"
        text="Edit"
        disabled="disabled"
        flat
        :tooltip="'Edit '+productData.end_user_name+' Details'"
        :tooltip-disabled="'Disabled by admin'"
        icon="mdi-pencil"
      />
      <ButtonWithTooltip
          v-if="$store.state.user.isAdmin"
          :text="(productData != null && productData.admin_blocked_at != null) ? 'Enable for Wholesaler' : 'Disable for Wholesaler'"
          flat
          :tooltip="'Disabled the product from being used or edited by non admin users. Useful to enable to product after admin has set the rates in PortaOne.'"
          :icon="(productData != null && productData.admin_blocked_at != null) ? 'mdi-lock-open-outline' : 'mdi-lock-outline'"
          @click="toggleAdminDisable"
      />
    </template>
    <EditWholesaleProductDetailsDialog  v-model="showEditDialog" :product-data="productData"/>
  </BasicCard>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import resellerDataMixin from "../../../mixins/resellerDataMixin";
import IconWithTooltip from "../../pieces/IconWithTooltip";
import EditWholesaleProductDetailsDialog from "../../dialogs/Wholesalers/EditWholesaleProductDetailsDialog";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import ButtonDisabledWithTooltip from "../../pieces/ButtonDisabledWithTooltip.vue";
export default {
    name: 'WholesalerProductDetailsCard',
    mixins: [apiMixin, resellerDataMixin, GlobalHelperMixin],
    data: () => ({
      showEditDialog:false,
      busy: false,
    }),
    components: {
      ButtonDisabledWithTooltip,
      EditWholesaleProductDetailsDialog,
      BasicCard,
      ButtonWithTooltip,
    },
    props: {
        productData:{
          type: Object,
          default: null,
        },
      loading:{
          type: Boolean,
          default:false,
      }
    },
  computed:{

  },
    methods: {
      async toggleAdminDisable() {
        this.busy = true;
        const param = this.form;
        var disable = (this.productData.admin_blocked_at == null);
        const response = await this.Api.send('put', 'wholesalers/' + this.productData.i_customer + '/products/'+this.productData.i_product+'/admin-block-toggle', param);
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          if(disable){
            this.showGlobalSuccessMessage('Product disabled successfully.');
          }else{
            this.showGlobalSuccessMessage('Product enabled successfully.');
          }
        }else{
          this.showGlobalErrorMessage('Failed to update.');
        }
        this.busy = false;
      },
    },
};
</script>