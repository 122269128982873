<template>
      <SimpleDialog
          :title="'\''+invoiceTemplateData.label+'\' preview options'"
          :loading="busy"
          v-model="visible"
          yesText="close"
          okOnly
          :maxWidth="300"
      >
        <div style="text-align:center">
          <button-with-tooltip
              text="Working Summary"
              tooltip="The current working version of the invoice template in summary mode."
              :href="linkBase + 'invoice-templates/'+invoiceTemplateData.working_summary_i_template+'/preview?session-token=' + this.$store.state.session.token"
              hrefNewWindow
              icon="mdi-open-in-new"
          /><br /><br />
          <button-with-tooltip
              text="Working Detailed"
              tooltip="The current working version of the invoice template in detailed mode."
              :href="linkBase + 'invoice-templates/'+invoiceTemplateData.working_detailed_i_template+'/preview?session-token=' + this.$store.state.session.token"
              hrefNewWindow
              icon="mdi-open-in-new"
          /><br /><br />
          <button-with-tooltip
              text="Published Summary"
              tooltip="The current published version of the invoice template in summary mode."
              :href="linkBase + 'invoice-templates/'+invoiceTemplateData.published_summary_i_template+'/preview?session-token=' + this.$store.state.session.token"
              hrefNewWindow
              icon="mdi-open-in-new"
              color="primary"
          /><br /><br />
          <button-with-tooltip
              text="Published Detailed"
              tooltip="The current published version of the invoice template in detailed mode."
              :href="linkBase + 'invoice-templates/'+invoiceTemplateData.published_detailed_i_template+'/preview?session-token=' + this.$store.state.session.token"
              hrefNewWindow
              icon="mdi-open-in-new"
              color="primary"
          />
        </div>

      </SimpleDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import SimpleDialog from "../templates/SimpleDialog";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
export default {
    name: 'InvoiceTemplateDemoOptionsDialog',
    components: {ButtonWithTooltip, SimpleDialog,},
    mixins: [apiMixin, dialogMixin],
    data: () => ({
        busy: false,
        linkBase: process.env.VUE_APP_API_BASE || '/api/',
    }),
    props: {
      invoiceTemplateData: {
        type: Object,
        default: null
      },
    },
    methods: {

    },
  watch: {

  },
};
</script>