<template>
  <BasicCard
      :loading="loading"
      :no-skeleton="false"
      title="Financial"
  >
    <v-row dense v-if="customerClassData != null">
      <v-col cols="4">
        <span class="font-weight-bold">Send statistics</span>
      </v-col>
      <v-col cols="8">
        <span v-if="customerClassData.send_statistics == 'N'">Do not send</span>
        <span v-if="customerClassData.send_statistics == 'S'">Send Summary</span>
        <span v-if="customerClassData.send_statistics == 'F'">Send Full Statistics</span>
      </v-col>
    </v-row>
    <template v-slot:actions>
      <ButtonWithTooltip
          v-if="customerClassData != null"
          text="Edit"
          flat
          :tooltip="'Edit'+customerClassData.title+' Details'"
          icon="mdi-pencil"
          @click="showEditDialog = true"
      />
    </template>

    <EditWholesaleCustomerClassFinanceDetailsDialog v-model="showEditDialog" :customer-class-data="customerClassData"/>

  </BasicCard>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import BasicCard from "../templates/BasicCard";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip";
import resellerDataMixin from "../../../mixins/resellerDataMixin";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import EditWholesaleCustomerClassFinanceDetailsDialog
  from "../../dialogs/Wholesalers/EditWholesaleCustomerClassFinanceDetailsDialog";
export default {
    name: 'WholesalerCustomerClassFinanceDetailsCard',
    mixins: [apiMixin, resellerDataMixin, GlobalHelperMixin],
    data: () => ({
      showEditDialog:false,
      busy: false,
    }),
    components: {
      EditWholesaleCustomerClassFinanceDetailsDialog,
      BasicCard,
      ButtonWithTooltip,
    },
    props: {
        customerClassData:{
          type: Object,
          default: null,
        },
      loading:{
          type: Boolean,
          default:false,
      }
    },
  computed:{

  },
    methods: {
      async toggleAdminDisable() {
        this.busy = true;
        const param = this.form;
        var disable = (this.productData.admin_blocked_at == null);
        const response = await this.Api.send('put', 'wholesalers/' + this.productData.i_customer + '/products/'+this.productData.i_product+'/admin-block-toggle', param);
        if (response.success) {
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          if(disable){
            this.showGlobalSuccessMessage('Product disabled successfully.');
          }else{
            this.showGlobalSuccessMessage('Product enabled successfully.');
          }
        }else{
          this.showGlobalErrorMessage('Failed to update.');
        }
        this.busy = false;
      },
    },
};
</script>