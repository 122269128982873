<template>
  <SimpleDialog
      v-model="visible"
      :title="'DIDs using DID Subscription \''+iDoBatchData.description+'\''"
      :loading="busy"
      okOnly
      styledHeader
      :maxWidth="1000"
  >
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-row class="px-4">
              <v-col cols="12" md="5">
                <v-text-field
                    v-model="searchFilters.number"
                    label="DID Number"
                    clearable
                    dense
                    :disabled="isBusy"
                    hide-details
                    outlined

                />
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                    v-model="searchFilters.i_customer"
                    label="Customer ID"
                    clearable
                    dense
                    :disabled="isBusy"
                    hide-details
                    outlined
                />
              </v-col>
              <v-col cols="12" sm="2">
                <v-btn
                    outlined
                    block
                    height="40"
                    :loading="isBusy"
                    @click="updateSearch"
                >Search</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <PaginationCardV2
              :page="searchFilters.page"
              :totalRecords="listData.count"
              :totalPages="listData.pages"
              :limit="searchFilters.limit"
              :currentPageTotal="(listData.results) ? listData.results.length : 0"
              :loading="isBusy"
              @updatedPagination="updatedPagination"
              :no-skeleton="false"
          >
            <v-simple-table>
              <thead>
              <tr>
                <th class="text-center">Number</th>
                <th class="text-center">Customer</th>
                <th class="text-center">In use</th>
              </tr>
              </thead>
              <v-slide-x-transition tag="tbody" hide-on-leave group>
                <tr v-if="listData.results && listData.results.length === 0" key="nodata">
                  <td colspan="100%" class="text-center">
                    No results to show.
                  </td>
                </tr>
                <tr
                    v-for="item in listData.results"
                    :key="item.i_did_number"
                >
                  <td class="text-center">
                    <router-link
                      :to="{name: 'WholesaleManagedDIDDetail', params: { did: item.number }}"
                      style="cursor: pointer"
                      class="pa-4"
                    >
                      <span class="font-weight-medium">{{ item.number }}</span>
                    </router-link>
                  </td>
                  <td class="text-center">
                    <router-link
                        :to="{name: 'CustomerSummary', params: { customerId: item.i_customer}}"
                        style="cursor: pointer"
                        class="pa-4"
                    >
                      <span class="font-weight-medium">{{item.i_customer}}</span>
                    </router-link>
                  </td>
                  <td class="text-center">
                    <v-icon v-text="item.i_account && item.i_account != null ? 'mdi-check-circle' : 'mdi-close-circle'" :color="item.i_account && item.i_account != null ? 'success' : 'error'" />
                  </td>
                </tr>
              </v-slide-x-transition>
            </v-simple-table>
          </PaginationCardV2>
        </v-col>
      </v-row>
    </v-container>
  </SimpleDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import SimpleDialog from "../templates/SimpleDialog";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import PaginationCardV2 from "../../cards/templates/PaginationCardV2";
export default {
    name: 'DidOwnerBatchDidListDialog',
    components: {SimpleDialog, PaginationCardV2},
    mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
    data: () => ({
      searchFilters: {
        page:1,
        limit: 10,
      },
      busy: false,
      listData: {
        total_records:0,
        total_pages:0
      },
      // paginationData:[],
    }),

    props: {
      resellerData: {
        type: Object,
        default: null
      },
      iDoBatchData: {
        type: Object,
        default: null
      },
      loading:{
        type: Boolean,
        default:false
      }
    },
    methods: {
      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        this.update( );
      },
      updateSearch(data){
        this.searchFilters.page = 1;
        this.update( );
      },

      async update() {
        this.busy = true;
        let params = this.searchFilters;

        for (const [key, value] of Object.entries(params)) {
          if(value == null || value == undefined){
            delete params[key];
          }
        }

        let queryString = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');

        const response = await this.Api.send('get','/wholesalers/'+this.resellerData.i_customer+'/did-subscriptions/' + this.iDoBatchData.i_do_batch + '/dids?'+queryString);
        this.busy = false;
        if (response.success) {
          this.listData = response.data;
          // this.paginationData = {
          //   data: response.data.results,
          //   count: response.data.count,
          //   pages: response.data.pages
          // };
        } else {
          this.listData = [];
        }
      },
    },
  watch: {
    visible: function (value) {
      if (value) {
        this.update();
      }
    },
  },
  computed: {
    isBusy() {
      return this.loading || this.busy;
    },
  }
};
</script>