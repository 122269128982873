<template>
  <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-form v-model="valid" ref="dateTimeForm" v-on:submit.prevent="">
        <v-text-field
            v-model="date"
            :label="label"
            append-icon="mdi-calendar"
            outlined
            :dense="dense"
            :clearable="clearable"
            :hint="hint"

            @click:append="menu = !menu"
            @click:clear="clear"

            :disabled="loading"
            :loading="loading"
            :rules="[validateDate]"

            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </v-form>
    </template>
    <v-date-picker
        v-model="datePicker"
        no-title
        v-if="!loading"
        scrollable
        @click:date="$refs.menu.save(date)"
        :min="min"
        :max="max"
        ref="datePicker"
    >
      <v-btn v-if="clearable" text block @click="clear">Clear</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
//const DateRegex = /^(\d{4}-(?:0[1-9]|1[0-2])-+(?:0[1-9]|[1-2][0-9]|3[0-1]))$/i;
const DateRegex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/i;

export default {
    name: 'DatePickerV2',
    data: () => ({
      menu: false,
      valid: false,
      datePicker: null,
      date: null,
    }),
    props: {
      label: {
        type: String,
        default: 'Date',
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      hint: {
        type: String,
        default: null,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      initialValue: {
        type: String,
        default: null,
      },
      min:{
        type:String,
        default:undefined
      },
      max:{
        type:String,
        default:undefined
      }
    },
    methods: {
      clear: function () {
        this.date = null;
        this.datePicker = null;
        this.$refs.menu.save(this.date);
      },
      validateDate(dateIn){
        if(!dateIn){
          return 'Date is required.'
        }
        if(!DateRegex.test(dateIn)){
          return 'Invalid date.';
        }
        return true;
      },
    },

    watch: {
      datePicker: function (value) {
        this.date = value;
        //we need to emit this is the form was valid and a different valid input in set.
        if(this.valid){
          this.$emit('input', this.date);
        }
      },
      valid: function (value) {
        if(!value){
          this.$emit('input', null);
        }else{
          this.$emit('input', this.date);
        }
      },
    },

    created() {
      if(this.initialValue != null && DateRegex.test(this.initialValue)){
        this.date = this.initialValue;
        this.datePicker = this.initialValue;
      }
    },
};
</script>
