<template>
  <SimpleDialog
      v-model="visible"
      :title="iDoBatchData ? 'Edit DID Subscription \''+iDoBatchData.description+'\'' : 'Create DID Subscription'"
      :loading="busy"
      :yesText="iDoBatchData ? 'Update' : 'Create'"
      :yesDisabled="!valid"
      @click="submit"
      noText="Cancel"
      styledHeader
  >
    <v-alert type="error" text class="mt-2" v-if="iDoBatchData && iDoBatchData.total_dids_used > 0">
      DID Subscription is linked to {{iDoBatchData.total_dids_used}} DIDs that are currently in use.
    </v-alert>
    <v-form v-model="valid" :disabled="isBusy">
    <SpinnerOverlay absolute v-if="isBusy" />
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
            v-model="form.description"
            label="Name"
            :rules="[rules.required]"
            outlined
        />
      </v-col>
    </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
              v-model="form.activation_fee"
              label="Activation Fee"
              :rules="[rules.required,rules.numeric]"
              outlined
              prefix="$"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
              v-model="form.base_periodic_fee"
              label="Monthly Fee"
              :rules="[rules.required,rules.numeric]"
              outlined
              prefix="$"
          />
        </v-col>
      </v-row>
    </v-form>
  </SimpleDialog>
</template>

<script>
import dialogMixin from '../../../mixins/dialogMixin';
import apiMixin from '../../../mixins/apiMixin';
import SimpleDialog from "../templates/SimpleDialog";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import SpinnerOverlay from "../../pieces/Global/SpinnerOverlay";
export default {
    name: 'CreateEditDIDSubscriptionDialog',
    components: {SimpleDialog,SpinnerOverlay},
    mixins: [apiMixin, dialogMixin, GlobalHelperMixin],
    data: () => ({
      busy: false,
      valid:false,
      rules: {
        required: v => !!v || 'Required.',
        numeric: v => !isNaN(v) || 'Must be whole number',
      },
      form:{
        description:null,
        activation_fee:null,
        base_periodic_fee: null
      }
    }),
    props: {
      resellerData: {
        type: Object,
        default: null
      },
      iDoBatchData: {
        type: Object,
        default: null
      },
      loading:{
        type: Boolean,
        default:false
      }
    },
    methods: {
      reset( ){
        this.form = {
          description:null,
          activation_fee:null,
          base_periodic_fee: null
        }
        if(this.iDoBatchData != null){
          this.form = {
            description:this.iDoBatchData.description,
            activation_fee:this.iDoBatchData.markup_options.activation_fee,
            base_periodic_fee: this.iDoBatchData.markup_options.base_periodic_fee,
          }
        }
      },
      async submit( ){
        this.busy = true;
        let params = this.form;
        let response = null;
        let newTog = false;
        if(this.iDoBatchData == null) {
          response = await this.Api.send('post', 'wholesalers/'+this.resellerData.i_customer+'/did-subscriptions', params);
        }else{
          newTog = false;
          response = await this.Api.send('put', 'wholesalers/'+this.resellerData.i_customer+'/did-subscriptions/'+this.iDoBatchData.i_do_batch, params);
        }
        if (response.success) {
          if(newTog) {
            this.showGlobalSuccessMessage('New DID Subscription has been created successfully.');
          }else{
            this.showGlobalSuccessMessage('Successfully updated DID Subscription.');
          }
          document.dispatchEvent(new CustomEvent('refreshRequested'));
          this.visible = false;
        }
        this.busy = false;
      },
    },
  watch: {
    visible(value) {
      if(value){
        this.reset();
      }
    }
  },
  computed: {
    isBusy() {
      return this.loading || this.busy;
    },
  }
};
</script>