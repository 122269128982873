<template>
        <v-row dense>
            <v-col>
                <v-card class="pa-2 mb-2">
                  <v-row>
                    <v-col cols="12" :sm="resellerId?2:3">
                      <v-text-field
                          hide-details
                          outlined
                          clearable
                          dense
                          label="Name"
                          :disabled="isBusy"
                          v-model="searchFilters.name"
                      />
                    </v-col>
                    <v-col cols="12" :sm="resellerId?2:3">
                      <v-select
                          hide-details
                          outlined
                          dense
                          label="Account Type"
                          v-model="searchFilters.iCustomerClass"
                          :disabled="isBusy"
                          :items="iCustomerClassesOptions"
                          item-text="option"
                          item-value="value"
                      />
                    </v-col>
                    <v-col cols="12" :sm="2" v-if="resellerId">
                      <v-select
                          hide-details
                          outlined
                          dense
                          label="Owner Type"
                          v-model="searchFilters.resellerCustomerToggle"
                          :disabled="isBusy"
                          :items="resellerCustomerToggleOptions"
                          item-text="option"
                          item-value="value"
                      />
                    </v-col>
                    <v-col cols="12" :sm="addButton?3:4">
                      <v-select
                          hide-details
                          outlined
                          dense
                          label="Status"
                          v-model="searchFilters.status"
                          :disabled="isBusy"
                          :items="statusOptions"
                          item-text="option"
                          item-value="value"
                      />
                    </v-col>
                    <v-col cols="12" :sm="2">
                      <v-btn
                          outlined
                          block
                          height="40"
                          :loading="isBusy"
                          @click="updateSearch"
                      >Search</v-btn>
                    </v-col>
                    <v-col cols="12" sm="1" v-if="addButton">
                      <v-tooltip left open-delay="200">
                        <template v-slot:activator="{ on }">
                          <v-fab-transition>
                            <v-btn
                                block
                                height="40"
                                :loading="isBusy"
                                color="primary"
                                v-on="on"
                                @click="$emit('addButtonTriggered');"
                            ><v-icon>mdi-account-plus</v-icon></v-btn>
                          </v-fab-transition>
                        </template>
                        New Customer
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card>
            </v-col>
            <v-col cols="12">
              <PaginationCardV2
                  :page="searchFilters.page"
                  :totalRecords="listData.count"
                  :totalPages="listData.pages"
                  :limit="searchFilters.limit"
                  :currentPageTotal="(listData.results) ? listData.results.length : 0"
                  :loading="isBusy"
                  @updatedPagination="updatedPagination"
                  :no-skeleton="false"
              >
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th>Customer</th>
                      <th>Account type</th>
                      <th>Company</th>
                      <th>Balance</th>
                      <th v-if="showRepNames">Partner</th>
                      <th v-if="showResellerNames">Wholesale User</th>
                      <th v-if="$vuetify.breakpoint.smAndUp">Contact</th>
                      <th v-if="$vuetify.breakpoint.smAndUp">Address</th>
                      <th class="text-center">Status</th>
                    </tr>
                    </thead>
                    <v-slide-x-transition
                        tag="tbody"
                        hide-on-leave
                        group
                    >
                      <tr v-if="!listData.results || listData.results.length == 0" key="nodata">
                        <td :colspan="7" class="text-center">
                          No customers found
                        </td>
                      </tr>
                      <tr
                          v-for="item in listData.results"
                          :key="item.i_customer"
                          v-bind:class="{
                            'red darken-4':
                                $vuetify.theme.dark &&
                                item.bill_status !== 'C' &&
                                (item.blocked === 'Y' ||
                                    (item.bill_status === 'S' &&
                                        item.bill_suspension_delayed !== 1) ||
                                    item.balance > item.credit_limit),
                            'red lighten-4':
                                !$vuetify.theme.dark &&
                                item.bill_status !== 'C' &&
                                (item.blocked === 'Y' ||
                                    (item.bill_status === 'S' &&
                                        item.bill_suspension_delayed !== 1) ||
                                    item.balance > item.credit_limit),
                            'grey darken-3':
                                $vuetify.theme.dark && item.bill_status === 'C',
                            'grey lighten-3':
                                !$vuetify.theme.dark && item.bill_status === 'C',
                        }"
                      >
                        <td><router-link
                            tag="td"
                            :to="{
                                name: 'CustomerSummary',
                                params: { customerId: item.i_customer },
                            }"
                            style="cursor: pointer"
                        >
                          <a class="font-weight-medium">{{ item.name }}</a>
                        </router-link></td>
                        <td>{{ item.customer_class_label}}</td>
                        <td>{{ item.companyname }}</td>
                        <td>
                          <Currency
                              :value="
                    item.i_balance_control_type == '1'
                        ? item.balance
                        : -item.balance
                "
                          />
                        </td>
                        <td v-if="showRepNames">
                          <router-link :to="{name: 'AdminPartnerSummary', params: {partnerId: item.i_rep}}">{{
                              item.representative_name
                            }}</router-link>
                        </td>
                        <td v-if="showResellerNames">
                          <router-link :to="{name: 'AdminWholesaleHome', params: {customerId: item.i_parent}}">{{
                              item.reseller_name
                            }}</router-link>
                        </td>
                        <td v-if="$vuetify.breakpoint.smAndUp">
                            <span v-if="item.salutation"
                            >{{ item.salutation }}
                            </span>
                          <span v-if="item.firstname"
                          >{{ item.firstname }}
                            </span>
                          <span v-if="item.lastname">{{
                              item.lastname
                            }}</span>
                          <span
                              v-if="
                                    !item.salutation &&
                                    !item.firstname &&
                                    !item.lastname
                                "
                          >-</span
                          >
                        </td>
                        <td v-if="!small && item.baddr1">
                          <span v-if="item.baddr1">{{ item.baddr1 }}, </span>
                          <span v-if="item.address_line_2"
                          >{{ item.address_line_2 }},
                            </span>
                          <span v-if="item.city">{{ item.city }} </span>
                          <AddressState :value="item.state" />
                          <span v-if="item.zip"> {{ item.zip }}</span>
                        </td>
                        <td v-if="!small && !item.baddr1">None</td>
                        <td class="text-center">
                          <CustomerStatus :data="item" />
                        </td>
                      </tr>
                    </v-slide-x-transition>
                  </template>
                </v-simple-table>
              </PaginationCardV2>
            </v-col>
        </v-row>
</template>

<script>
import refreshListenerMixin from "../../../mixins/refreshListenerMixin";
import apiMixin from "../../../mixins/apiMixin";
import PaginationCardV2 from "../templates/PaginationCardV2";
import CustomerStatus from "../../pieces/Customer/CustomerStatus";
import AddressState from "../../pieces/Address/AddressState";
import Currency from "../../pieces/Currency";

/**
 * This is a variation of the normal admin customer list that cater for dynamic i_customer_classes
 * Update to use the PaginationComponentV2 and does not use the local filter logic.
 */
export default {
    name: 'CustomerListCardV2',
    mmixins: [refreshListenerMixin,apiMixin],
    components: {
      PaginationCardV2,
      CustomerStatus,
      AddressState,
      Currency,
    },
    data: () => ({
        busy: false,
        firstLoad: true,
        iCustomerClassesOptions:[],
        filter: {
            limit: 10,
            page: 1,
            hidden: 'N',
            name: null,
            resellerCustomerToggle:false,
        },
        searchFilters:{
          name: null,
          status: null,
          accountType: null,
          resellerCustomerToggle:false,
        },
        listData: {
          count:0,
          pages:0
        },
        paginationData:[],
        statusOptions: [
          { option: 'Active (all)', value: null },
          { option: 'Active (not suspended)', value: 'O' },
          { option: 'Active (suspended)', value: 'S' },
          { option: 'Terminated', value: 'C' },
        ],
        resellerCustomerToggleOptions: [
          { option: 'Any', value: false },
          { option: 'Ignore Partner Customers', value: true},
        ],
        // accountTypeOptions: [
        //   { option: 'Any', value: null},
        //   { option: 'SIP Trunk', value: 'sip-trunk' },
        //   { option: 'Hosted Extension', value: 'hosted-extension' },
        // ],
        data: [],
    }),
    props: {
      repId: {
        type: Number,
        default: null,
      },
      resellerId: {
        type: Number,
        default: null,
      },
      addButton: {
        type: Boolean,
        default: false,
      },
      showRepNames: {
        type: Boolean,
        default: false,
      },
      showResellerNames: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },
  /**
   * For some reason refreshListenerMixin is not mounting the listeners.
   * So I manually add the listeners here. Totally unsure why.
   */
    mounted() {
      document.addEventListener('refreshRequested', this.update);
    },
    beforeDestroy() {
      document.removeEventListener('refreshRequested', this.update);
    },
    computed: {
      isBusy: function( ){
        return this.busy || this.loading;
      },
    },
    created(){
      this.getCustomerClasses( );
    },
    methods: {
        updatedPagination(data){
          this.searchFilters.page = data.page;
          this.searchFilters.limit = data.limit;
          this.update( );
        },
        updateSearch(data){
          this.searchFilters.page = 1;
          this.update( );
        },
        async update() {
            this.busy = true;
            let params = this.searchFilters;

            if(this.resellerId){
              //params.owner = 'wholesale';
              //params.i_customer = this.resellerId;
            }else if(this.repId){
              //params.owner = 'partner';
              //params.i_rep = this.repId;
            }

            if (this.repId > 0) {
              params.i_rep = this.repId;
            } else {
              if (this.getRepNames) params.get_rep_names = 1;
            }

            for (const [key, value] of Object.entries(params)) {
              if(value == null || value == undefined){
                delete params[key];
              }
            }

            let queryString = Object.keys(params).map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');

            let response = null;
            if(this.resellerId){
              response = await this.Api.send('get','wholesalers/'+this.resellerId+'/customers?'+queryString);
            }else if(this.repId){
              response = await this.Api.send('get','partners/'+this.repId+'/customers?'+queryString);
            }else{
              response = await this.Api.send('get','customers?'+queryString);
            }

            this.busy = false;
            if (response.success) {
              this.listData = response.data;
              this.paginationData = {
                results: response.data.results,
                count: response.data.count,
                pages: response.data.pages
              };
              if (this.firstLoad) this.firstLoad = false;
            } else {
              this.listData = [];
            }
        },
        async getCustomerClasses() {
          this.busy = true;

          let response = null;
          if(this.resellerId){
            response = await this.Api.send('get','wholesalers/'+this.resellerId+'/customer-classes');
          }else if(this.repId){
            response = await this.Api.send('get','partners/'+this.repId+'/customer-classes');
          }else{
            alert('Update here. Return all i_customer_types for Admin.');
          }

          this.busy = false;
          let newList = [];
          newList.push({option: 'Any', value: null});
          if (response.success) {
            response.data.forEach(function (item, index) {
              newList.push({option: item.title, value: item.i_customer_class});
            });
          }

          this.iCustomerClassesOptions = newList;
        },
    },
};
</script>
