<template>
    <v-row dense>
        <v-col cols="12" v-if="customerData.status === 'suspended'">
            <v-alert class="mb-0" type="warning">{{label}} is suspended due to non-payment!</v-alert>
        </v-col>
        <v-col cols="12" v-else-if="customerData.status === 'credit_exceeded'">
            <v-alert class="mb-0" type="warning">{{label}} has exceeded credit limit!</v-alert>
        </v-col>
        <!-- non-dismissable alert only shows if status is blocked -->
        <v-col cols="12" v-else-if="customerData.status === 'blocked'">
            <PageAlert
                :loading="isLoading"
                type="error"
                button
                button-text="Unblock now"
                @click="unblockDialog = true"
            >
                This {{label}} account is blocked.<span v-if="$store.state.user.isNotAdmin"> Please contact Breeze Connect for more information.</span>
            </PageAlert>
        </v-col>
        <!-- non-dismissable alert only shows if status is closed -->
        <v-col cols="12" v-else-if="customerData.status === 'closed'">
            <PageAlert
                    :loading="isLoading"
                    type="error"
            >
                This account is closed.
            </PageAlert>
        </v-col>
        <v-col cols="12" v-else-if="customerData.terminate_on">
            <PageAlert
                    :loading="isLoading"
                    type="warning"
            >
               {{label}} account is pending termination. Account due to be terminated <LocalDateTime :value="customerData.terminate_on" relative />.
            </PageAlert>
        </v-col>
        <v-col cols="12" v-else-if="customerData.status === 'suspension_delayed'">
            <PageAlert
                    :loading="isLoading"
                    type="info"
            >
               {{label}} account is overdue and extension has been applied.<br/>
                Suspension has been delayed until <LocalDateTime :value="customerData.suspension_delay_date" relative />.
            </PageAlert>
        </v-col>
        <v-col cols="12" v-else-if="!!customerData && (customerData.credit_limit * 0.8)<customerData.balance">
            <PageAlert
                :loading="isLoading"
                type="info"
            >
               {{label}} has consumed more than 80% of credit limit!
            </PageAlert>
        </v-col>
    </v-row>
</template>

<script>
import PageAlert from "../../core/PageAlert";
import LocalDateTime from "../LocalDateTime";

export default {
    name: 'CustomerAlerts',
    props: {
      customerData: {
        required:true,
        default: {},
      },
    },
    data: () => ({
      changeAddressDialog:false,
      changePrimaryContactDialog: false,
    }),
    components: {
        PageAlert,
        LocalDateTime,
    },
  computed:{
      label( ){
        if(this.customerData.i_customer_type == 2){
          return 'Wholesale User';
        }else{
          return 'Customer'
        }
      },
    isLoading( ){
        if(this.customerLoading){
          return this.customerLoading;
        }else if(this.resellerLoading){
          return this.resellerLoading;
        }else{
          return false;
        }
    }
  }

};
</script>