<template>
    <div>
      <v-card v-if="addButton " class="pa-2 mb-2">
        <v-row>
          <v-col cols="12" :sm="8">
          </v-col>
          <v-col cols="12" sm="4" style="vertical-align: middle;text-align:right">
            <v-tooltip bottom :disabled="canCreate">
              <template v-slot:activator="{ on }">
                <div v-on="on" class="d-inline-block">
                  <v-btn block
                         height="40"
                         :loading="isLoading"
                         @click="openCreateExtensionDialog()"
                         color="primary"
                         :disabled="!canCreate">Create new DID Subscription <v-icon>mdi-plus</v-icon></v-btn>
                </div>
              </template>
              <span>Limit of {{resellerData.did_subscription_limit}} DID Subscriptions has been reached.</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
      <PaginationCardV2
          :page="searchFilters.page"
          :totalRecords="listData.count"
          :totalPages="listData.pages"
          :limit="searchFilters.limit"
          :currentPageTotal="(listData.results) ? listData.results.length : 0"
          :loading="isBusy"
          @updatedPagination="updatedPagination"
          :no-skeleton="false"
      >
        <v-simple-table>
          <thead>
          <tr>
            <th v-if="$store.state.user.isAdmin">ID</th>
            <th>Name</th>
            <th class="text-center">DIDs assigned/in use</th>
            <th class="text-center">Activation Fee</th>
            <th class="text-center">Monthly Fee</th>
            <th></th>
          </tr>
          </thead>

          <v-slide-x-transition tag="tbody" hide-on-leave group>
            <tr v-if="listData.results && listData.results.length === 0" key="nodata">
              <td colspan="100%" class="text-center">
                No Subscriptions found
              </td>
            </tr>
            <tr
                v-for="item in listData.results"
                :key="item.i_do_batch"
            >
              <td v-if="$store.state.user.isAdmin">
               {{ item.i_do_batch }}
              </td>
              <td>
                {{ item.description }}
              </td>
              <td class="text-center">
                {{ item.total_dids }} / {{ item.total_dids_used }}
              </td>
              <td class="text-center">
                {{ item.markup_options.activation_fee }}
              </td>
              <td class="text-center">
                {{ item.markup_options.base_periodic_fee }}
              </td>
              <td class="text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="openAssignDidsDialog(item);"
                    >
                      <v-icon v-html="'mdi-numeric'" />
                    </v-btn>
                  </template>
                  <span>View assigned DIDs</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="openUpdateExtensionDialog(item);"
                    >
                      <v-icon v-html="'mdi-border-color'" />
                    </v-btn>
                  </template>
                  <span>Edit Settings</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="openDeleteSubscriptionDialog(item);"
                    >
                      <v-icon v-html="'mdi-delete-forever'" />
                    </v-btn>
                  </template>
                  <span>Delete Subscription</span>
                </v-tooltip>
              </td>
            </tr>
          </v-slide-x-transition>
        </v-simple-table>
      </PaginationCardV2>
      <CreateEditDIDSubscriptionDialog v-model="addEditDialog" :resellerData="resellerData" :iDoBatchData="updatingSubscription"/>
      <DidOwnerBatchDidListDialog v-model="didListDialog" :resellerData="resellerData" :iDoBatchData="didListSubscription"/>
      <SimpleDialog
          v-model="deleteDialog"
          :title="'Delete Subscription'"
          :loading="busy"
          :yesText="'Confirm Delete'"
          @click="deleteSubscription"
          noText="Cancel"
          :yesDisabled="deletingSubscription && deletingSubscription.total_dids > 0"

          styled-header
          errorHeader
          yesColor="error"
      >
        <span v-if="deletingSubscription && deletingSubscription.total_dids > 0">
           <v-alert type="error" text class="mt-2">
             Unable to delete DID Subscription '{{deletingSubscription.description}}'.
           </v-alert>
          <p>DID Subscription is currently linked to {{deletingSubscription.total_dids}} DIDs.</p>
          <p v-if="deletingSubscription.total_dids_used">DID Subscription is currently linked to {{deletingSubscription.total_dids_used}} DIDs that are currently in use.</p>
        </span>
        <span v-else>
          <p>Are you sure you wish to delete DID Subscription '<span v-if="deletingSubscription">{{deletingSubscription.description}}</span>'?</p>
        </span>
      </SimpleDialog>
    </div>
</template>

<script>
import PaginationCardV2 from "../templates/PaginationCardV2";
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import CreateEditDIDSubscriptionDialog from "../../dialogs/DID/CreateEditDIDSubscriptionDialog";
import SimpleDialog from "../../dialogs/templates/SimpleDialog";
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import DidOwnerBatchDidListDialog from "../../dialogs/DID/DidOwnerBatchDidListDialog";
export default {
    name: 'DidSubscriptionListCard',
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin, GlobalHelperMixin],
    data: () => ({
      busy:false,
      addEditDialog:false,
      deleteDialog:false,
      deletingSubscription:null,
      updatingSubscription:null,
      didListDialog:false,
      didListSubscription:{},
      updating: false, //this helps prevent a due update on page load.
      searchFilters: {
        page:1,
        limit: 10,
      },
      listData: {
        count:0,
        pages:0
      },
    }),
    components: {
      DidOwnerBatchDidListDialog,
      SimpleDialog,
      CreateEditDIDSubscriptionDialog,
      PaginationCardV2,
    },
    props: {
        resellerData: {
          type: Object,
          default: null,
        },
        addButton: {
          type: Boolean,
          default: false,
        },
    },
  computed: {
    isBusy: function( ){
      return this.loading || this.busy;
    },
    canCreate: function( ){
      if(!this.resellerData){
        return false;
      }else{
        if(this.resellerData.did_subscription_count < this.resellerData.did_subscription_limit){
          return true;
        }else{
          return false
        }
      }
    }
  },
    methods: {
      openAssignDidsDialog(subscription){
        this.didListSubscription = subscription;
        this.didListDialog = true;
      },
      openDeleteSubscriptionDialog(subscription){
          this.deletingSubscription = subscription;
          this.deleteDialog = true;
      },
      openUpdateExtensionDialog(subscription){
        this.updatingSubscription = subscription;
        this.addEditDialog = true;
      },
      openCreateExtensionDialog(){
        this.updatingSubscription = null;
        this.addEditDialog = true;
      },
      updatedPagination(data){
        this.searchFilters.page = data.page;
        this.searchFilters.limit = data.limit;
        //Note that the create method in the refreshMixin runs an update. And the create method in PaginationV2 run this method.
        //Hence on page load we refresh the list twice. This prevents that.
        if(!this.updating){
          this.update( );
        }
      },
      async deleteSubscription(){
        this.busy = true;
        this.updating = true;

        const response = await this.Api.send('delete','/wholesalers/'+this.resellerData.i_customer+'/did-subscriptions/' + this.deletingSubscription.i_do_batch);
        this.busy = false;
        this.updating = false;
        if (response.success) {
          this.showGlobalSuccessMessage('DID Subscription ('+this.deletingSubscription.description+') delete successfully.');
          if(this.resellerData.did_subscription_count == this.resellerData.did_subscription_limit){
            document.dispatchEvent(new CustomEvent('refreshRequested'));
          }else {
            this.update();
          }
          this.deletingSubscription = {};
          this.deleteDialog = false;
        }
      },

      async update() {
        this.busy = true;
        this.updating = true;
        let params = this.searchFilters;

        for (const [key, value] of Object.entries(params)) {
          if(value == null || value == undefined){
            delete params[key];
          }
        }

        let queryString = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        const response = await this.Api.send('get','/wholesalers/'+this.resellerData.i_customer+'/did-subscriptions?' + queryString);

        this.busy = false;
        this.updating = false;
        if (response.success) {
          this.listData = response.data;
          this.paginationData = {
            data: response.data.results,
            count: response.data.count,
            pages: response.data.pages
          };
          if (this.firstLoad) this.firstLoad = false;
        } else {
          this.listData = [];
        }
      },
    },
};
</script>
