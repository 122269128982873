<template>
  <ComplexDialog
      :title="'Edit '+customerClassData.title+' Invoicing Details'"
      v-model="visible"
      :loading="busy"
      :maxWidth="600"
  >
    <v-form ref="form" v-model="isValid">
      <v-text-field
          v-if="false"
          outlined dense
                    label="Do not charge below (AUD)"
                    hint="Do not try to collect the payment if the amount due is less than"
                    type="number"
                    :rules="rules.positiveNumber"
                    min="0"
      />
      <v-text-field outlined dense
                    label="Payment due days"
                    hint="Amount of days payment is expected within."
                    v-model="form.grace_period"
                    type="number"
                    :rules="rules.positiveNumber"
                    min="7"
                    max="28"
      />
      <v-text-field outlined dense
                    label="Notify due date in advance (days)"
                    hint="Comma-separated format: 7, 5, 3. Send due date notification in advance. Can set multiple days with comma-separated format."
                    v-model="form.notific_days"
                    type="text"
                    :rules="[ruleCommaSeparatedFormat]"
      />
      <v-switch class="mt-0" v-model="resend_days_tog" :label="resend_days_tog?'Resend invoice if overdue':'Do not resend invoice if overdue'" />
      <v-text-field
          v-if="resend_days_tog"
          outlined dense
                    label="Resend the invoice after the due date (days)"
                    hint="Comma-separated format: 7, 5, 3. Set how many days after the due date to trigger a resend. Can set multiple days (for multiple resends) with comma-separated format."
                    v-model="form.resend_days"
                    type="text"
                  :rules="[ruleCommaSeparatedFormat]"
      />
      <v-switch class="mt-0" v-model="suspension_days_tog" :label="suspension_days_tog?'Set days to suspend customer after due':'Do not suspend customer after due'" />
      <v-text-field outlined dense
                    v-if="suspension_days_tog"
                    label="Overdue days until suspension (days)"
                    hint="Set how many days after the due date when the customer will be suspended."
                    v-model="form.suspension_days"
                    type="number"
                    :rules="rules.positiveNumber"
                    min="0"
                    max="30"
      />
      <v-text-field outlined dense
                    label="Warning of suspension (days)"
                    hint="Comma-separated format: 7, 5, 3. Days before suspension to send out a warning notification. Can set multiple days (for multiple resends) with comma-separated format."
                    v-model="form.warning_days"
                    type="text"
                    :rules="[ruleCommaSeparatedFormat]"
      />
    </v-form>
    <template v-slot:actions>
      <v-spacer />
      <v-btn
          :loading="busy"
          @click="update"
          text
          :disabled="!isValid"
      >Save</v-btn>
    </template>
  </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
import trunkSettingsDataMixin from '@/mixins/trunkSettingsDataMixin';
export default {
  name: 'EditWholesaleCustomerClassInvoicingDetailsDialog',
  mixins: [apiMixin, dialogMixin],
  components: { ComplexDialog },
  data: () => ({
    isValid: false,
    busy: false,
    form: {
      grace_period:null,
      notific_days:null,
      resend_days:null,
      suspension_days:null,
      warning_days:null,
    },
    resend_days_tog:false,
    suspension_days_tog:false,
    rules: {
      positiveNumber:[
        (v) => (!v || !isNaN(v)) || 'Must be numeric',
        (v) => v >= 0 || 'Must be greater or equal to zero',
      ],
      commaSeparatedFormat:[

      ],
      name: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length > 1) || 'Must contain at least 2 characters.',
        (v) => (!!v && v.length < 51) || 'Must contain at less than 50 characters.'
      ],
      subName: [
        (v) => (!v || v.length > 1) || 'Must contain at least 2 characters.',
        (v) => (!v || v.length < 64) || 'Must contain at less than 63 characters.'
      ],
      price: [
        (v) => !!v || 'Required',
        (v) => (!!v && v.length >= 0) || 'Price must be zero or greater.'
      ],
      // email: [
      //   (v) => !!v || 'Required',
      //   (v) => /^.+@.+\..+$/.test(v) || 'Invalid email',
      // ],
      // phone1: [
      //   (v) => !!v || 'Required',
      //   (v) => (/^(?:0[2,3,5-9]\d{8})?$/.test(v) || /^(?:04\d{8})?$/.test(v)) || 'Invalid Phone/Mobile number.'
      // ],
    }

  }),
  methods: {
    ruleCommaSeparatedFormat(value){
      let numbers = [];
      if(value == null || value == ''){
        //empty value
        return true;
      }

      if(!isNaN(value)){
        //number passed
        numbers.push(value);
      }else{
        //assume comma separated string.
        numbers = value.split(",");
      }

      for(let i=0;i<numbers.length;i++){
        if(isNaN(numbers[i])){
          return 'Value of ('+numbers[i]+') is not numeric.';
        }else if (numbers[i] < 0){
          return 'Numbers must be greater than zero.';
        }else if(numbers[i] % 1 != 0){
          return "Value ("+numbers[i]+") is no a whole number.";
        }
      }
      return true;
    },
    async update() {
      this.busy = true;
      const params = this.form;
      if(!this.resend_days_tog){
        params.resend_days = null;
      }
      if(!this.suspension_days_tog){
        params.suspension_days = null;
      }
      const response = await this.Api.send('put', 'wholesalers/' + this.customerClassData.i_customer + '/customer-classes/'+this.customerClassData.i_customer_class, params);
      if (response.success) {
        document.dispatchEvent(new CustomEvent('refreshRequested'));
        this.visible = false;
      }
      this.busy = false;
    },
    reset( ){
      this.form = {
        grace_period:null,
        notific_days:null,
        resend_days:null,
        suspension_days:null,
        warning_days:null,
      }
      this.resend_days_tog = false;
      this.suspension_days_tog = false;

      if(this.customerClassData != null){
        this.form = {
          grace_period:this.customerClassData.grace_period,
          notific_days:this.customerClassData.notific_days,
          resend_days:this.customerClassData.resend_days,
          suspension_days:this.customerClassData.suspension_days,
          warning_days:this.customerClassData.warning_days,
        }

        if(this.form.resend_days != null && this.form.resend_days != ''){
          this.resend_days_tog = true;
        }
        if(this.form.suspension_days != null && this.form.suspension_days != ''){
          this.suspension_days_tog = true;
        }
      }
    }
  },

  watch: {
    customerClassData(value) {
      this.reset( );
    },

  },

  props: {
    customerClassData: {
      type: Object,
      default: null
    },
    loading:{
      type: Boolean,
      default: false,
    }
  },
  created(){
    this.reset( );
  },
  computed: {
    isBusy() {
      return this.loading || this.busy;
    },
  }
};
</script>