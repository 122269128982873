<template>
    <ComplexDialog
        :title="title"
        v-model="visible"
        :loading="busy"
    >
    <v-window>
      <v-window-item>
        <p class="subtitle-1 text--primary">
          User Account Details
        </p>

        <ConvertToUserDetailsForm
            v-model="user"
            :prefill="{'id':contact.user_id,'username':contact.username}"
            :show-email-toggle="this.contact.id ? false : true"
            show-bcc
        />
      </v-window-item>
    </v-window>
        <template v-slot:actions>
            <v-spacer />
            <v-btn
                text
                color="primary"
                :loading="busy"
                @click="save"
                :disabled="(user == null)"
            >{{ user == null || user.id == null ? 'Create Login':'Edit Login'}}</v-btn>
        </template>
    </ComplexDialog>
</template>

<script>
    import ConvertToUserDetailsForm from "../pieces/Forms/ConvertToUserDetailsForm";
    import ComplexDialog from './templates/ComplexDialog';
    import dialogMixin from '../../mixins/dialogMixin';
    import apiMixin from '../../mixins/apiMixin';
    export default {
        name: 'ConvertToUserDialog',
        mixins: [apiMixin, dialogMixin],
        components: {
          ComplexDialog,
          ConvertToUserDetailsForm
        },
        data: () => ({
            step: 1,
            note: '',
            flagged: false,
            busy: false,
            user:null,
        }),
        methods: {
            async save() {
                this.busy = true;
                let response;
                if(this.contact.user_id == undefined || this.contact.user_id == null){
                  if(this.partnerId){
                    response = await this.Api.send('POST', 'partners/' + this.partnerId + '/contacts/'+this.contact.id+'/convert-to-user', this.user);
                  }else if(this.customerId){
                    response = await this.Api.send('POST', 'customers/' + this.customerId + '/contacts/'+this.contact.id+'/convert-to-user', this.user);
                  }else{
                    console.error('Missing valid id.');
                  }
                }else{
                  response = await this.Api.send('PUT', 'users/' + this.user.id, this.user);
                }
                if (response.success) {
                  document.dispatchEvent(new CustomEvent('refreshRequested'));
                  this.visible = false;
                }
                this.busy = false;
            }
        },
        props: {
            partnerId: {
                type: Number,
                default: null,
            },
            customerId: {
              type: Number,
              default: null,
            },
            contact: {
              type: Object,
              default: null,
            },
            title: {
                type: String,
                default: 'Create Portal Login'
            },
            data: {
                type: Object,
                default: null
            }
        },
    };
</script>

<style scoped>

</style>