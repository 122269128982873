<template>
    <v-sheet
        rounded
        :color="
            'blue-grey' + ($vuetify.theme.isDark ? ' darken-3' : ' lighten-4')
        "
        class="pa-4"
    >
        <v-text-field
            v-model="searchText"
            hide-details
            placeholder="Search by ABN, ACN or name..."
            :error-messages="searchError"
            prepend-inner-icon="mdi-magnify"
            @input="searchInput()"
            @click:clear="searchClear()"
            @keydown.enter="search()"
            :loading="loading"
            :disabled="loading"
            solo

            clearable
            :solo-inverted="$vuetify.theme.isDark"
        >
            <template v-slot:append-outer>
                <v-btn
                    class="ml-4"
                    @click="search()"
                    :loading="loading"
                    :disabled="!searchText"
                    large
                    height="48px"
                    >Search</v-btn
                >
            </template>
        </v-text-field>
        <v-expand-transition>
            <v-row
                class="pt-4"
                v-if="searchList.Names && !searchItem.EntityName && !loading"
                dense
            >
                <v-col cols="12">
                    Showing {{ searchList.Names.length }} result<span
                        v-if="searchList.Names.length !== 1"
                        >s</span
                    >
                    for '{{ lastSearchText }}'
                </v-col>
                <v-col cols="12">
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>ABN</th>
                                <th>State</th>
                                <th>Postcode</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in searchList.Names"
                                :key="item.index"
                                style="cursor: pointer"
                                :class="parseInt(item.AbnStatus) === 1 ? '' : 'error--text'"
                                @click="searchNumber(item)"
                            >
                                <td>{{ item.Name }}<span v-if="parseInt(item.AbnStatus)!==1"> (Cancelled)</span></td>
                                <td>{{ item.NameType }}</td>
                                <td>{{ item.Abn }}</td>
                                <td>{{ item.State }}</td>
                                <td>{{ item.Postcode }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-expand-transition>
            <v-row class="pt-4" v-if="searchItem.EntityName">
                <v-col cols="12">
                    <v-sheet rounded class="pa-4">
                        <v-row dense>
                            <v-col cols="4" md="2">
                                <span class="font-weight-bold"
                                    >Entity Name</span
                                >
                            </v-col>
                            <v-col cols="8" md="4">
                                {{ searchItem.EntityName }}
                            </v-col>
                            <v-col cols="4" md="2">
                                <span class="font-weight-bold"
                                    >Entity Type</span
                                >
                            </v-col>
                            <v-col cols="8" md="4">
                                {{ searchItem.EntityTypeName }}
                            </v-col>
                            <v-col cols="4" md="2">
                                <span class="font-weight-bold">
                                    <span v-if="searchItem.Abn">ABN</span>
                                    <span v-else>ACN</span>
                                </span>
                            </v-col>
                            <v-col cols="8" md="4">
                                <span v-if="searchItem.Abn">{{
                                    searchItem.Abn
                                }}</span>
                                <span v-else>{{ searchItem.Acn }}</span>
                            </v-col>
                            <v-col cols="4" md="2">
                                <span class="font-weight-bold">Status</span>
                            </v-col>
                            <v-col
                                cols="8"
                                md="4"
                                :class="
                                    searchItem.AbnStatus === 'Active'
                                        ? 'success--text'
                                        : 'error--text'
                                "
                            >
                                {{ searchItem.AbnStatus }} (since {{ searchItem.AbnStatusEffectiveFrom }})
                            </v-col>
                            <v-col cols="4" md="2">
                                <span class="font-weight-bold"
                                    >Main business location</span
                                >
                            </v-col>
                            <v-col cols="8" md="4">
                                {{ searchItem.AddressState }}
                                {{ searchItem.AddressPostcode }}
                            </v-col>
                            <v-col cols="12" class="text-right">
                                <v-btn @click="cancelSearch()">Cancel</v-btn>
                                <v-btn
                                    class="ml-4"
                                    color="primary"
                                    :disabled="
                                        searchItem.AbnStatus !== 'Active'
                                    "
                                    @click="confirmSearch()"
                                    >Select</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-row cols="12" dense class="pt-4">
            <v-col cols="12" sm="6">
                <a v-if="showManualEntry" @click="$emit('manual')">Click here to manually enter business entity details</a>
            </v-col>
            <v-col cols="12" sm="6" class="text-right text--disabled">
                Search powered by
                <a href="https://abr.business.gov.au/" target="_blank"
                    >Australian Business Register</a
                >
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'ABRSearch',
    mixins: [apiMixin],
    data: () => ({
        searchList: [],
        searchItemNameSearch:[], // used to hold the select results from the search result list
        searchItem: [],
        loading: false,
        showFields: false,
        searchText: '',
        lastSearchText: '',
        searchError: '',
    }),
    methods: {
        change: function (value) {
            this.$emit('update', value);
        },
        search() {
            if (this.searchText) {
                this.searchList = [];
                this.searchItem = [];
                if (this.searchText.match(/[^\d\s]/)) {
                    this.searchNames(this.searchText.trim());
                } else {
                    this.searchNumber(this.searchText.replaceAll(' ', ''));
                }
            }
        },
        async searchNames(name) {
            this.loading = true;
            this.searchList = [];
            const response = await this.Api.send('post','tools/abrsearch/name', {
                query: name,
            });
            if (response.success) {
                if (response.data.Message) {
                    this.searchList = [];
                    this.searchError = response.data.Message;
                } else {
                    this.lastSearchText = this.searchText;
                    this.searchList = response.data;
                }
            } else {
                this.searchError = response.data.description
                    ? response.data.description
                    : 'An unknown error occurred';
            }
            this.loading = false;
        },
        async searchNumber(item) {
            let number = item.Abn;
            this.loading = true;
            this.searchItem = [];
            this.searchItemNameSearch = item;
            const response = await this.Api.send('post','tools/abrsearch/number', {
                query: number,
            });
            if (response.success) {
                if (response.data.Message) {
                    this.searchList = [];
                    this.searchError = response.data.Message;
                } else {
                    this.searchItem = response.data;
                }
            } else {
                this.searchError = response.data.description
                    ? response.data.description
                    : 'An unknown error occurred';
            }
            this.loading = false;
        },
        searchInput() {
            if (this.searchText) {
                this.searchText = this.searchText.trimStart();
            }
            this.searchError = '';
        },
        searchClear() {
            this.searchList = [];
            this.searchItem = [];
            this.searchItemNameSearch = [];
            this.searchError = '';
        },
        confirmSearch() {
            let result = {};
            if (this.searchItem.EntityTypeName.includes('Individual') || this.searchItem.EntityTypeName.includes('Partnership')) {
                result.entity_type = 1;
                result.abn_acn = this.searchItem.Abn || this.searchItem.Acn;
                result.lastname = this.properCase(this.searchItem.EntityName.substring(0, this.searchItem.EntityName.search(',')).trim());
                result.firstname = this.properCase(this.searchItem.EntityName.substring(this.searchItem.EntityName.search(',') + 1, this.searchItem.EntityName.length).trim());
            } else if (this.searchItem.EntityTypeName.includes('Trust')) {
                result.entity_type = 3;
                result.trust_abn = this.searchItem.Abn;
                result.trust_name = this.properCase(this.searchItem.EntityName).replace('The Trustee For ', '');
            } else {
                result.entity_type = 2;
                result.abn_acn = this.searchItem.Abn || this.searchItem.Acn;
                result.entity_name = this.properCase(this.searchItem.EntityName);
            }
            if (this.searchItem.BusinessName) {
                let names = [];
                this.searchItem.BusinessName.forEach(value => names.push(this.properCase(value)));
                result.business_names = names;
            }
            result.searchName = this.searchItemNameSearch.Name;
            this.$emit('selected', result);
            this.searchList = [];
            this.searchItem = [];
            this.searchText = '';
        },
        cancelSearch() {
            this.searchItem = [];
        },
        properCase(value) {
            if (typeof value === 'string') {
                return value.replace(
                    /\w\S*/g,
                    function(txt) {
                        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    }
                )
            } else {
                return '';
            }
        }
    },
    props: {
        label: {
            type: String,
            default: null,
        },
        showManualEntry: {
            type: Boolean,
            default: false
        }
    },
};
</script>
<style>
.v-input__append-outer {
    margin: 0 !important;
}
</style>
