<template>
  <v-row dense v-if="resellerData">
    <v-col cols="12">
      <DIDListCard
          :reseller-id="resellerId"
          :loading="resellerLoading"
          :filter="filter"
          @update="updateFilter"
          show-filters
          show-tools
          @addButtonTriggered="showNewDIDs=true"
      />
    </v-col>
    <FloatingButton v-if="resellerData && resellerData.status != 'closed' && ($store.state.user.isAdmin || $store.state.user.isManagedWholesale)" tooltip="Add New DIDs" icon="mdi-phone-plus" @click="showNewDIDs=true" :loading="resellerLoading" />
    <AddDIDDialog v-model="showNewDIDs" :customerData="resellerData" processTypeSwitch="reseller" :validOptions="addDidOptions"/>
  </v-row>
</template>
<script>
import DIDListCard from '../../components/cards/DID/DIDListCard';
import filterMixin from '../../mixins/filterMixin';
import FloatingButton from '@/components/pieces/FloatingButton';
import AddDIDDialog from "../../components/dialogs/DID/AddDIDDialog";
import resellerDataMixin from "../../mixins/resellerDataMixin";
export default {
  name: 'CustomerDIDs',
  mixins: [filterMixin, resellerDataMixin],
  components: {
    AddDIDDialog,
    FloatingButton,
    DIDListCard,
  },
  data: () => ({
    filter: {
      usage: 'A',
      number: null,
      page: 1,
      limit: 10,
    },
    showNewDIDs: false,
    addDidOptions: {"all":[1]}, //only show option 1 regardless of current logged in user type.
  }),
  beforeRouteLeave(to, from, next){
    return (this.showNewDIDs) ? next(false) : next();
  },

};
</script>
